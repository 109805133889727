.banner-top {
	margin: -40px 0 60px;
	height: 590px;
	background-repeat: no-repeat;
	background-position: center center;

	&__inner {
		height: 100%;
	}

	&__desc {
		height: 100%;
		text-align: center;
		max-width: 620px;
		margin: 0 auto;

		&:after {
			display: inline-block;
			content: '';
			height: 100%;
			vertical-align: middle;
			width: 0;
		}
	}

	&__text {
		display: inline-block;
		vertical-align: middle;
		color: rgb(36, 38, 43);
		font-size: 16px;
		font-weight: 300;
		line-height: 25px;
		max-width: 97%;
		padding: 0 20px;
	}

	&__title,
	h1, h2, h3, h4, h5, h6 {
		color: rgb(36, 38, 43);
		font-size: 45px;
		font-weight: 400;
		line-height: 50px;
		margin-bottom: 35px;
	}

	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		margin: -35px -30px 30px;
	}

	@media (max-width: @screen-xs-max) {
		margin: -25px -20px 20px;
		height: 470px;
	}
}