//main: style.less

.compare-buttons {
	position: relative;

	&__item {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		.xs({
			margin-bottom: 10px;
		});
	}

	&__link {
		color: #2c2d32;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;
		display: block;
		.size(100%, 40px);
		line-height: 36px;
		text-align: center;
		border: 2px solid #08b6d3;
		cursor: pointer;
		.transition(background 500ms, color 500ms);

		&.active,
		&:hover {
			background: #08b6d3;
			color: #fff;
		}

		.xs({
			border-width: 1px;
			height: auto;
			padding: 5px;
			font-size: 10px;
			line-height: 1;
		});

		&.active {
			pointer-events: none;
		}
	}
}