// main: style.less

.tooltip-popup {
    position: absolute;
    max-width: 400px;
    background: #e7e7e7; //rgba(0, 0, 0, .7);
    pointer-events: none;
    z-index: 10000;
    line-height: 1;
    margin: 0;
	font-family: Roboto;
    .opacity(0);
    .border-radius(3px);
    .transition(opacity 500ms, margin 500ms, width 500ms, height 500ms, left 500ms, top 500ms);

    &_benefits {
        max-width: 320px;
		
    }

    &_top {
        margin: -40px 0 0;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -10px;
            margin: 0 0 0 -10px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #e7e7e7; //rgba(0, 0, 0, .7);
        }
    }

    &_bottom {
        margin: 40px 0 0;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: -10px;
            margin: 0 0 0 -10px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #e7e7e7; //rgba(0, 0, 0, .7);
        }
    }

    &_left {
        margin: 0 0 0 -40px;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: -10px;
            margin: -10px 0 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #e7e7e7; //rgba(0, 0, 0, .7);
        }
    }

    &_right {
        margin: 0 0 0 40px;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: -10px;
            margin: -10px 0 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid #e7e7e7; //rgba(0, 0, 0, .7);
        }
    }

    &_active {
        .opacity(1);
    }

    &_click {
        pointer-events: auto;
    }


    &__close {
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
        display: none;
    }

    &_click &__close {
        display: block;
    }

    &__change {
        padding: 15px;
        font-family: "Roboto";
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;

        strong {
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            display: block;
            margin-bottom: 10px;
        }
    }

    &_form &__change {
        font-size: 12px;
        line-height: 18px;
        padding: 20px;
    }

    &_form &__close {
        color: #fff;
    }

    &__icon {
        background: #2c2d32;
        border-radius: 50%;
        .size(16px);
        display: inline-block;
        margin-left: 5px;
        color: #fff;
        text-align: center;
        line-height: 16px;
        text-decoration: none;
        vertical-align: top;
        cursor: pointer;
        font-size: 11px;
    }
}