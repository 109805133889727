.add-basket {
	max-width: 680px;
	background-color: #fff;
	padding: 45px 50px 50px;
	line-height: 1;

	@media (max-width: @screen-xs-max) {
		padding-left: 25px;
		padding-right: 25px;
		padding-bottom: 15px;
		padding-top: 20px;
	}

	&__inner {

	}

	&__title {
		color: #333;
		font-size: 20px;
		margin-bottom: 35px;
		text-align: center;

		.xs({
			margin-bottom: 19px;
		});
	}

	&__link {
		display: block;
		margin-bottom: 40px;

		@media (max-width: @screen-xs-max) {
			margin: 0;
		}
	}

	&__pic {
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}

	&__desc {
		padding-top: 15px;
		margin-bottom: 40px;

		@media (max-width: @screen-xs-max) {
			margin: 0;
			text-align: center;
		}
	}

	&__name {
		color: #444;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 15px;
	}

	&__namelink {
		color: #444;
		text-decoration: none;
	}

	&__footer {
		height: 60px;
		.display(flex);
		.justify-content(space-between);
		.align-items(flex-start);

		.xs({
			height: auto;
			margin-bottom: 15px; justify-content: center;
		});
	}

	&__summ {
		text-align: center;
	}

	&__price {
		color: #000;
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 5px;

		&_color {
			color: #ff281b;
		}
	}

	@media (max-width: @screen-xs-max) {

		&__price {
			font-size: 19px;
		}
	}

	&__oldprice {
		color: #666;
		font-size: 15px;
		font-weight: 500;
		text-decoration: line-through;
	}

	&__action {
		text-align: left;
		margin-left: 10px;
		color: rgb(255, 255, 255);
		font-size: 15px;
		font-weight: 500;
		line-height: 35px;
		padding: 0 0 0 18px;
		.size(60px, 35px);
		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAjCAMAAAD/opkOAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAApVBMVEX/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv////0h8pWAAAANXRSTlMAHNTuF9kV0g/ODsgKwgi7BrQDraYBnpWNg3xxav6co6qvtgnBDcbLEtAY3B/hJOMn6CzqER7R2PoAAAABYktHRDZHv4jRAAAACXBIWXMAAAsSAAALEgHS3X78AAAAsUlEQVRIx6XTRwKCQBQE0UYFUVFxFHPOOXP/qxlQYVzxp2v/lgXoWZls+v5szg4F6dbJS6yO3YLIarhYktkk9spCm8CVqtTG2K+J7Q+rutx+sWoY2A8OmiY2wkHLyEa4bWbfuGNoX7hrap+4Z2xD9AcEHprbEGpEYKgxgeFPCAx/SmDM5CvHGN6cwFgsCQx3RWCsNwSGkyEwtjaBYe0IjP2BwDieCIzzhcC43ggM756+B1FEbQL1C8ASAAAAAElFTkSuQmCC') no-repeat;
	}

	&__buttons {

		a {
			.size(100%, 50px);
			text-align: center;
			.border-radius(5px);
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			text-decoration: none;
			display: block;
			.bgButton();
			.vm();

			@media (max-width: @screen-xs-max) {

				&:first-child {
					margin-bottom: 10px;
				}
			}
		}
	}
}