// main: style.less

.catalog-block {
	display: block;
	overflow: hidden;
	position: relative;
	border-radius: 6px;
	margin-bottom: 30px;
	background-color: #fff;
	border: 1px solid #e8e8e8;

	&_non-current {
		position: relative;
		&:after {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			content: '';
			z-index: 1;
			background-color: rgba(#fff,0.9);
		}
		.catalog-block__content {
			position: relative;
			z-index: 4;
		}
		.catalog-block__character {
			opacity: 0.2;
		}
		.compare-add {
			width: 100%!important;
			text-align: center!important;
		}
		.catalog-block__middle {
			position: relative!important;
			height: 285px!important;
		}
	}

	&__name:hover +
	&__middle,
	&__middle:hover {
		//position: absolute;
		//height: 100%;
		//top: 0;
	}
	@media only screen and (max-width: 1023px), only screen	and (hover: none) {
		&:hover &__middle {
			position: static;
			height: 269px;
			top: auto;
		}
	}
	.xs({ height: auto !important; &:hover .catalog-block__middle {
		position: static;
		height: 269px;
		top: auto;
	} });
	&__inner {
		height: 100%;
		position: relative;
	}

	&__photo {
		width: 100%;
		height: 246px;
		position: relative;
		overflow: hidden;
	}

	&__link {

		&_fixed {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: ~'calc(100% - 70px)';
			z-index: 2
		}
	}

	&__inf {
		padding-top: 10px;
	}

	&__pic {
		position: absolute;
		left: 50%;
		top: 50%;
		.transform(translate(-50%, -50%));
	}

	&__content {
		padding-top: 19px;
		text-align: center;
		&:hover {

		}
	}

	&__name {
		margin-bottom: 20px;
		color: #000;
		line-height: 1;
		text-decoration: none;
		text-transform: uppercase;

		.catalog-block__inf & {
			margin-bottom: 10px;
		}
	}

	&__middle:hover &__name {
		color: #00869d;
	}

	&__namelink {
		display: block;
		color: #47474d;
		line-height: 1;
		margin-top: 20px;
		padding-left: 10px;
		padding-right: 10px;
		text-decoration: none;
		text-align: center;
		text-transform: uppercase;
		span {
			display: block;
		}

		&:hover {
			color: #00869d;
		}
	}

	&__character {
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid #e2e2e2;
		border-bottom: 1px solid #e2e2e2;

		&-block {
			margin-bottom: 15px;
		}
	}

	&__more {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		opacity: 0;
		z-index: 1;
	}

	&__middle {
		left: 0;
		right: 0;
		height: 269px;
		bottom: 0;
		overflow: hidden;
		position: relative;
		background-color: rgba(255, 255, 255, 0.93);
		z-index: 1;
	}

	&__span {
		color: #000;
		line-height: 1;
		font-size: 12px;
		font-weight: 400;
		text-transform: lowercase;
	}

	&__strong {
		color: #000;
		line-height: 1;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 5px;
		text-transform: lowercase;
	}

	&__desc {
		border-top: 1px solid #e8e8e8;
	}

	&__footer {
		z-index: 1;
		.display(flex);
		width: 90%;
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		padding-bottom: 20px;
		//padding-right: 15px;
		background-color: #fff;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__summ {
		width: 100%;
		text-align: left;
		margin-bottom: 5px;
		text-align: center;
		//align-self: center;
	}

	&__button {
		width: 50%;
		text-align: center;
		float: left;

		.xs({
			float: none;
			display: flex;
			justify-content: flex-end;
		});
	}

	&__buy {
		color: #fff;
		display: inline-block;
		font-size: 13px;
		font-weight: 500;
		line-height: 40px;
		.size(126px, 40px);
		border-radius: 6px;
		text-decoration: none;
		color: #fff;
		background-color: #00859b;
		text-transform: uppercase;
		.transition(background 500ms);

		&:hover {
			background-color: #08b6d3;
		}
	}
	.compare-add {
		text-align: right;
		width: 50%;
		border: none;
		position: relative;
		line-height: 40px;
		height: 40px;
		font-size: 14px;
		font-weight: 500;

		a {
			&:before {
				border-radius: 3px;
				border-color: #e8e8e8;
				top: -1px;
			}
		}
	}
	&__stock {
		margin-top: 20px;
		text-align: center;

		span {
			position: relative;
			padding-left: 20px;
			color: #000;
			font-size: 13px;
			font-weight: 400;
			line-height: 25px;
			text-transform: none;

			&::after {
				content: "";
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				width: 14px;
				height: 14px;
				background-image: url(../images/basket-info__available.png);
			}
		}
	}

	&__noavailable {
		margin-top: 20px;
		text-align: center;
		position: relative;
		z-index: 4;
		span {
			color: #000;
			font-size: 13px;
			font-weight: 400;
			line-height: 25px;
			text-transform: none;

		}
	}

	&__old-price {
		color: #47474d;
		line-height: 1;
		font-size: 13px;
		font-weight: 500;
		text-decoration: line-through;
	}

	&__span {
		display: block;
		font-weight: 400;
		margin-bottom: 5px;
	}

	&__strong {
		display: block;
		font-weight: 500;
	}

	&__price {
		color: #000;
		font-size: 18px;
		font-weight: 500;
		line-height: 40px;
		&_color {
			color: #ff2521;
			line-height: normal;
		}
	}
}
