.reviews {
	position: relative;
	line-height: 1;
	margin-bottom: 30px;

	&__inner {
		max-width: 1200px;
		margin: 0 auto;
		position: relative;
		padding-left: 10px;
		padding-right: 10px;
		overflow: hidden;

		&-button{
			background-color: #f2f2f2;
			padding: 21px 0;
		}
	}

	&_card{
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 70px;
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		overflow: hidden;
		margin-bottom: 20px;
		font-size: 0;

	}

	&__promo {
		min-height: 301px;
		padding: 20px;
		padding-top: 73px;
		margin-bottom: 30px;
		background-image: url(../images/reviews-promo.jpg);
		color: #fff;

		&-title {
			color: #fff;
			margin-bottom: 30px;
			font-size: 36px;
			font-weight: 400;
			text-align: center;
			text-transform: uppercase;

		}
	}

	&__text {
		max-width: 936px;
		margin-left: auto;
		margin-right: auto;
	}

	&__title {
		float: left;
		color: #000;
		font-size: 26px;
		font-weight: 500;
		margin-bottom: 15px;
		text-transform: uppercase;
	}

	&__review {
		position: relative;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		display: block;
		margin-bottom: 30px;
		background-color: #f2f2f2;
		text-align: center;

		a {
			color: #00859b;
			font-family: Roboto, sans-serif;
			font-size: 21px;
			font-weight: 500;
			border-bottom: 1px dashed #000;
			text-decoration: none;

			&:hover {
				border-bottom: none;
			}
		}
		&-link{
			color:#2d2d30 !important;
			text-transform: uppercase;
		}
	}

	&__button{
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 205px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		background-color: #00859b;
		color: #fff;
		font-family: Roboto;
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;
		border-radius: 4px;

		&:hover{
			background-color: #00a7c3;
		}
	}

	&-response{
		padding: 55px 40px 40px;
		background-color:#fff;

	}

	@media (max-width: 768px) {

		&__wrapper {
			justify-content: center;
			text-align: center;
		}
	}

}