.about-block {
	background-color: @color3;
	margin-bottom: 55px;

	.sm({
		margin-bottom: 35px;
	});

	&__text {
		max-width: 920px;
		margin: 0 auto;
		padding: 55px 30px;
		text-align: center;
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}

	&__title {
		color: #fff;
		font-size: 36px;
		line-height: 40px;
		font-weight: 400;
		margin-bottom: 25px;
		text-transform: uppercase;
	}

	.xs({
		margin: 0 -20px 13px;

		&__text {
			font-size: 14px;
			line-height: 24px;
			padding: 35px 20px;
		}

		&__title {
			font-size: 20px;
			line-height: 25px;
			margin-bottom: 15px;
		}
	});
}
