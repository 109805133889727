.filter-checkbox {
	.clearfix();

	&__label {
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 10px;
		position: relative;
		padding: 0 0 0 30px;
		min-height: 20px;
	}

	&__input {
		position: absolute;
		visibility: hidden;
		top: 0;
		left: 0;
	}

	&__icon {
		.size(20px);
		background-color: #fff;
		border: 1px solid #b8b8b8;
		position: absolute;
		left: 0;
		top: 0;
	}

	&__input:checked ~ &__icon {
		background: @color3;
		border-color: @color3;
		.box-shadow(0 0 0 5px #fff inset);
	}

	&__text {
		color: #000;
		font-size: 14px;
		font-weight: 400;
		display: block;
		padding-top: 5px;
	}
}
