.footer-block {
	position: relative;
	margin-top: 46px;

	.xs({
		position: static;
		border-bottom: 1px solid #666;

	});

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		border-top: 1px solid #666;
		width: 100%;

		.xs({
			display: none;
		});
	}
	&__item {
		margin-bottom: 20px;
		text-align: center;
		&_drop{
			position: relative;
			&:hover{
				.footer-block__dropdown{
					left: 50%;
				}
			}
		}
		.sm({
			margin-bottom: 0;
		});
	}
	.lg({
		.display(flex);
		.justify-content(space-between);
		padding: 33px 0 32px;

		&__copy {
			.opacity(0.6);
			color: #000;
			font-size: 13px;
			font-weight: 300;
			padding: 2px 0 0;
		}

		&__list {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		&__item {
			display: inline-block;
			margin-right: 40px;

			&:last-child {
				margin-right: 0;
			}
		}
		&__dropdown{
			position: absolute;
			bottom: 100%;
			left: -9999999px;
			transform: translateX(-50%);
			z-index: 15;
			margin-bottom: 10px;
			background:#fff;
			text-align: left;
			box-shadow: 9px 9px 21px 0 rgba(0, 0, 0, .14);
			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				height: 30px;
				bottom: -28px;
			}

			&-triagle{

				width: 12px;
				height: 12px;
				top: 100%;
				position: absolute;
				left: 50%;
				margin-left: -6px;
				&:before{
					content: '';
					display: block;
					background-color: @color3;
					transform: rotate(45deg);
					width: 12px;
					height: 12px;
					left: -6px;
					bottom:5px;
					position: absolute;
				}

			}
			&-list{
				display: inline-block;
				padding-top: 8px;
				padding-left: 10px;
				padding-bottom: 8px;
			}
			&-item{
				padding-top: 12px;
				padding-bottom: 7px;
				white-space: nowrap;
				list-style: none;

			}
			&-link{
				display: block;
				white-space: nowrap;
				color: #2d2d30;
				text-decoration: none;
				&:hover{
					color: @color3;
				}

			}
			&-info{
				padding-right: 20px;
				padding-left: 10px;
			}

		}
		&__link {
			.opacity(0.6);
			color: #000;
			font-size: 13px;
			font-weight: 400;
			text-decoration: none;
			&_color{
				color: @color3;
				font-weight: 700;
			}
		}
	});

	&__link:hover,
	&__item.active &__link {
		text-decoration: underline;
	}



	.sm({
		//.display(flex);
		//.justify-content(space-between);
		.align-items(center);
		height: 80px;
		position: absolute;
		left: 0;
		width: 100%;
		padding: 20px 10px 0;
		bottom: 0;
		z-index: 1;

		&__copy {
			.opacity(0.6);
			color: #000;
			font-size: 13px;
			font-weight: 300;
			position: absolute;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
		}

		&__list {
			list-style: none;
			margin: 0;
			padding: 0;
			width: 100%;
			display: flex;
			justify-content: center;
		}

		&__item {
			display: inline-block;
			margin-right: 4%;
			&_drop{
				position: relative;
				&:hover{
					.menu-header__dropdown{
						left: 50%;
					}
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
		&__dropdown{
			position: absolute;
			bottom: 100%;
			left: -9999999px;
			transform: translateX(-50%);
			z-index: 15;
			margin-bottom: 10px;
			background:#fff;
			text-align: left;
			box-shadow: 9px 9px 21px 0 rgba(0, 0, 0, .14);
			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				height: 30px;
				bottom: -28px;
			}

			&-triagle{

				width: 12px;
				height: 12px;
				top: 100%;
				position: absolute;
				left: 50%;
				margin-left: -6px;
				&:before{
					content: '';
					display: block;
					background-color: @color3;
					transform: rotate(45deg);
					width: 12px;
					height: 12px;
					left: -6px;
					bottom:5px;
					position: absolute;
				}

			}
			&-list{
				display: inline-block;
				padding-top: 8px;
				padding-left: 10px;
				padding-bottom: 8px;
			}
			&-item{
				padding-top: 12px;
				padding-bottom: 7px;
				white-space: nowrap;
				list-style: none;

			}
			&-link{
				display: block;
				white-space: nowrap;
				color: #2d2d30;
				text-decoration: none;
				&:hover{
					color: @color3;
				}

			}
			&-info{
				padding-right: 20px;
				padding-left: 10px;
			}

		}
		&__link {
			.opacity(0.6);
			color: #000;
			font-size: 13px;
			font-weight: 400;
			text-decoration: none;
			&_color{
				color: @color3;
				font-weight: 700;
			}
		}
	});

	.xs({
		padding: 0px 0 5px;
		margin-top: 0;

		&__copy {
			.opacity(0.6);
			color: #000;
			font-size: 13px;
			font-weight: 300;
			position: absolute;
			left: 0;
			bottom: 28px;
			right: 0;
			text-align: center;
		}

		&__list {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		&__item {
			&_drop{
				position: relative;
				&:hover{
					.menu-header__dropdown{
						left: 50%;
					}
				}
			}

		}
		&__dropdown{
			position: absolute;
			bottom: 100%;
			left: -9999999px;
			transform: translateX(-50%);
			z-index: 15;
			margin-bottom: 10px;
			background:#fff;
			text-align: left;
			box-shadow: 9px 9px 21px 0 rgba(0, 0, 0, .14);
			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				height: 30px;
				bottom: -28px;
			}

			&-triagle{

				width: 12px;
				height: 12px;
				top: 100%;
				position: absolute;
				left: 50%;
				margin-left: -6px;
				&:before{
					content: '';
					display: block;
					background-color: @color3;
					transform: rotate(45deg);
					width: 12px;
					height: 12px;
					left: -6px;
					bottom:5px;
					position: absolute;
				}

			}
			&-list{
				display: inline-block;
				padding-top: 8px;
				padding-left: 10px;
				padding-bottom: 8px;
			}
			&-item{
				padding-top: 12px;
				padding-bottom: 7px;
				white-space: nowrap;
				list-style: none;

			}
			&-link{
				display: block;
				white-space: nowrap;
				color: #2d2d30;
				text-decoration: none;
				&:hover{
					color: @color3;
				}

			}
			&-info{
				padding-right: 20px;
				padding-left: 10px;
			}

		}

		&__link {
			.opacity(0.6);
			color: #000;
			font-size: 13px;
			font-weight: 400;
			text-decoration: none;
			&_color{
				color: @color3;
				font-weight: 700;
			}
		}
	});
}
