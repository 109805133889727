.compare-features {
	position: absolute;
	left: 0;
	top: 0!important;
	width: 230px;
	background: rgba(255, 255, 255, .8);
	z-index: 2;
	border-right: 2px solid #d8d8d8;

	.xs({
		width: 150px;
		border-width: 1px;
	});

	&__top {
		position: absolute;
		left: 0;
		top: 0!important;
		padding: 20px;
		width: 228px;
		background: #fff;
		transform: translate(0px, 0px)!important;

		.xs({
			padding: 10px;
			width: 148px;
		});
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;

		&_table {

		}
	}

	&__item {
		color: #333;
    	font-size: 14px;
    	font-weight: 500;
    	padding: 10px 0 10px 20px;
		background: #d8d8d8;
		overflow: hidden;
		//height: 40px!important;
		display: flex;
		align-items: center;
		.transition(height 500ms, padding 500ms);

    	&:nth-child(odd) {
    		background: #efefef;
    	}

    	.xs({
    		padding: 5px 10px;
    		font-size: 10px;
    	});
	}
}