html {
	font-family: sans-serif;
	-ms-text-size-adjust: none;
	-webkit-text-size-adjust: none;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	.appearance(none);
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,
acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,
strike,strong,sub,sup,tt,var,b,i,center,dl,dt,dd,ol,ul,li,fieldset,
form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,
aside,audio,canvas,details,figcaption,figure,footer,
header,hgroup,mark,menu,meter,nav,output,
progress,section,summary,time,video{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

html {
    height: 100%;
}

body {
    height: 100%;
    line-height: 1;
}

body:before {
    content: "";
    height: 100%;
    float: left;
    width: 0;
    margin-top: -32767px;
}

input,
select,
textarea,
button {
    vertical-align: middle;
    font-size: 100%;
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: @fontInput;
}

input[type=button],
input[type=submit],
input[type=reset],
button {
    cursor:pointer;
}

a {
    margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
    color: @link_color;
}

a img {
    vertical-align: middle;
}

ul,
ol {
	margin: 0 0 15px 15px;
}

p {
    margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 20px;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section{
    display: block;
}

nav ul {
    list-style: none;
    margin: 0;
}

nav ul li {
    margin: 0;
}

address {
    font-style: normal;
}

blockquote, q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 16px 0;
    padding: 0;
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

.clr {
    font-size: 0px;
    line-height: 0px;
    clear: both;
}