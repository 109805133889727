//main: style.less

.return-form {
	margin: 0 -200% -40px;
	background-color: #e7e7e7;
	padding: 55px 30px 50px;
	line-height: 1;

	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		margin: 0 -30px -40px;
	}

	@media (max-width: @screen-xs-max) {
		margin: 0 -20px -40px;
		padding: 25px 20px 30px;
	}

	&__inner {
		max-width: 1200px;
		margin: 0 auto;
	}

	&__title {
		color: #000;
		font-size: 24px;
		font-weight: 500;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 40px;

		.xs({

			& {
				font-size: 16px;
				margin-bottom: 20px;
			}
		});
	}

	&__form {

	}

	&__name {
		color: #888;
		font-size: 15px;
		font-weight: 400;
		margin-bottom: 8px;

		span {
			color: #ff5252;
			font-size: 15px;
			font-weight: 400;
		}
	}

	&__msg {
		.size(100%, 147px);
		background-color: #fff;
		border: 1px solid #bcbcbc;
		padding: 10px 20px;
		margin-bottom: 28px;

		@media (max-width: @screen-xs-max) {
			margin-bottom: 10px;
		}
	}

	&__input {
		.size(100%, 48px);
		background-color: #fff;
		border: 1px solid #bcbcbc;
		padding: 0 20px;
		display: block;
		margin-bottom: 28px;

		@media (max-width: @screen-xs-max) {
			margin-bottom: 10px;
		}
	}

	&__title2 {
		color: #000;
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 20px;

		@media (max-width: @screen-xs-max) {
			font-size: 15px;
			padding-top: 10px;
		}
	}

	&__fileblock {

	}

	&__section {
		margin-bottom: 20px;
	}

	&__field {
		margin-bottom: 5px;
	}

	&__item {
		display: flex;
		position: relative;
		overflow: hidden;
	}

	&__file {
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 100%;
		font-size: 200%;
	}

	&__button {
		background-color: #2c2d32;
		.border-radius(5px);
		color: #c9c9c8;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		border: none;
		display: inline-block;
		text-align: center;
		padding: 12px 15px;
		.transition(background 500ms);

		&:hover {
			background-color: @color3;
		}
	}

	&__item:hover &__button,
	&__item.active &__button {
		background-color: @color3;
	}

	&__item.active &__button {
		color: #ffffff;
	}

	&__item.active &__button {
		width: 100%;
		white-space: normal;
		max-width: 100%;
		overflow: hidden;
	}

	&__delete {
		display: none;
		vertical-align: top;
		margin-left: 5px;
		color: #000;
		cursor: pointer;
		font-size: 20px;
		padding: 0 4px 4px;
		position: relative;
		z-index: 1;
	}

	&__item.active &__delete {
		display: inline-block;
	}

	&__add {

	}

	&__submit {
		.size(205px, 49px);
		background-color: #2c2d32;
		.border-radius(5px);
		color: #c9c9c8;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		border: none;
		margin: 20px auto 0;
		display: block;
		.transition(background 500ms);

		&:hover {
			background-color: @color3;
		}
	}
}