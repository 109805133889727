.header-search {
	position: relative;

	.lg({
		width: 290px;
		height: 40px;
		background-color: #ebebeb;
		float: right;

		&:after {
			border-bottom: 1px solid #eaeaea;
			position: absolute;
			left: -1000%;
			width: 2000%;
			bottom: 0;
			content: '';
		}

		&__form {
			position: relative;
			padding: 0 50px 0 0;
			height: 40px;
		}

		&__input {
			color: #767779;
			font-size: 13px;
			font-weight: 400;
			padding: 0 35px;
			border: none;
			width: 100%;
			height: 40px;
			background: none;

			.sm({
				padding: 0 20px;
			});
		}

		&__submit {
			height: 21px;
			width: 20px;
			position: absolute;
			top: 50%;
			right: 35px;
			transform: translateY(-50%);
			background: url(../images/search__icon.svg) no-repeat center center;

			border: none;
		}
	});

	.sm({
		width: 188px;
		height: 40px;
		background-color: #ebebeb;
		float: right;
		margin: 20px 30px 20px 0;

		&__form {
			position: relative;
			padding: 0 50px 0 0;
			height: 40px;
		}

		&__input {
			color: #767779;
			font-size: 13px;
			font-weight: 400;
			padding: 0 20px;
			border: none;
			width: 100%;
			height: 40px;
			background: none;
		}

		&__submit {
			height: 40px;
			width: 50px;
			position: absolute;
			top: 0;
			right: 0;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAPCAYAAADUFP50AAABNUlEQVQ4T5WSwXXCQAxEZ3JIrpRAB4EO6ADSQehgfVznYPmCj2wHpAOgA3cQOoASUkEmTzYmxi/hkT3t29WXRtIQvWMWRo/knNBYwuiBPEnaR0unfpzf2T2sLCwAbEj6Wy3gk8AEwLOklFvK+nADOkRyKynLLaV+QGVhBnIHaRstLbs/NvKAI4ByCHVBlYUxyAOkEC29N1JXFgLIkBfr8bCPq8plMAnz3NK0BcusJlTHItlNsK16jMW6ae9u0IOrMtOXNH2zdLgbPPf5U7EaaP9LbmXh1WcRi7WvCOwySXrJLe1+A33yT8CHT/4y1UZ7my1Jeh3CnljA1o3RVbtyzhneSDqQaCpLmJB0R+0BzK72OHCJZ1+gDXY/+tJ30VLduQvS0uVevHprhz0HeUsbh/8F9ufxDaJKqFD6Yb21AAAAAElFTkSuQmCC') no-repeat center center;
			border: none;
		}
	});

	.xs({
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		cursor: pointer;
		padding: 0 0 0 70px;
		border-top: 1px solid #cfcfcf;
		border-bottom: 1px solid #cfcfcf;

		&.active {
			cursor: inherit;
			z-index: 1;
		}

		&__form {
			padding: 0 61px 0 0;
		}

		&__input {
			color: #767779;
			font-size: 13px;
			font-weight: 400;
			padding: 0;
			border: none;
			width: 0%;
			height: 62px;
			background: none;
			float: right;
			background: #eaeaea;
			.transition(width 500ms, padding 500ms);
		}

		&.active &__input {
			width: 100%;
			padding: 0 30px;
		}

		&__submit {
			width: 61px;
			height: 62px;
			position: absolute;
			top: 0;
			right: 0;
			background: #eaeaea url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAWCAYAAAAvg9c4AAAB+klEQVRIS7WVXXISQRSFz5nSymPYgckKxBUEVhB2IFmBPM44WN1TQmifJCsQV2BYQXAFYQm4g/iGpXKsbjKTBhkJVbEf4fLdc3/OhfgPj3XMoU27AM8FtEg0fJykGanrRJxm1i3qfvsXdGjfNoHVF5AnAQR8I1ACztZw3JEa58YVu8Ab0KCO/LQO1FUijGNF1trGcyw7Ii2BF5AmuXUX2+AKGhRSt4C+Q0krt5fzuvI8/BmXM4IvffLcuF4cW0EHRbbw2VdS+511s33z2wCLr2IRAVqWLeBz34y6+4Dl9+9t2krIG0HTvnGd8vMAHRTZhMDrRDr911R3JRsU6dy3ITejquoSGqbbN6Mw8UPesEgtQBO3bV1+kQnA19yMWocAQ5U265H4+KTQWqWhL8Jxbt3poUqHRToG+AbRBmwM6rHrFCder6IauXHByv4FaLUa0qxvXfuxah8cqCI3zm5A74flF/4M0kVu3WQfeGTTk9/gLSkmQjNexWq3gq+5XAA8Bla93Hy4qgN7Swu6Cddrh4itg+L9v5p5sD9zICe/dDS11t75BJc26wg6B/ngOmH+E0ftMqbqaaxo7ekfY++wOqX+HErqkuyGuC1w7ZG+71mHROVpQPOVcB0fnNLiMbgWum9QW2sVbkcJfhJofJTCX84hivbF+lYAav4BNLIUPk8l97cAAAAASUVORK5CYII=') no-repeat center center;
			pointer-events: none;
			border: none;
		}

		&.active &__submit {
			pointer-events: auto;
		}
	});

}