.detail-benefits {

	.lg({
		width: 1140px;
		margin: 0 auto 53px;
	});

	&__inner {
	}

	&__title {
	}

	&__row {
		text-align: center;
	}

	&__col {
		display: inline-block;
		vertical-align: top;
	}

	&__item {
		display: inline-block;
		margin: 0 22px 22px;
	}

	&__pic {
		display: block;
	}
}