.tabs {
	position: relative;
	width: 100%;

	&_detail {
		margin-bottom: 60px;
		background-color: #fff;
		border: 1px solid #d0d0d0;
		border-radius: 0 0 3px 3px;

		.xs({
		    margin-bottom: 50px;
			border: none;
		});
	}

	&__button {
		height: 47px;
		background-color: #e1e1e1;
		color: #010101;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		line-height: 47px;
		padding: 0 20px;
		position: relative;
		margin: 0 -20px;
		cursor: pointer;
		margin-bottom: 1px;

		&:after {
			.size(12px, 7px);
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAMAAAALbFwWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAVFBMVEVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWVlZWX///8KkYpxAAAAGnRSTlMAMroTu7TRFdAMwdUYwgq82BsJt93cB7MGp2H8K9IAAAABYktHRBsCYNSkAAAACXBIWXMAAAsSAAALEgHS3X78AAAARklEQVQI1y2LWxaAEAAFrySJPCvc/S+0Q83XfMxALBITuQoobnq43qlgDloHOMvTAD4wphQZ/Ahy4XWz5G99Klkbflrv019/rgM9geRSpAAAAABJRU5ErkJggg==') no-repeat;
			position: absolute;
			right: 20px;
			top: 50%;
			margin-top: -3.5px;
			content: '';
		}

		&.active {
			background-color: @color3;
			color: #fff;

			&:after {
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAMAAAALbFwWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAATlBMVEX///////////////////////////////////////////////////////////////////////////////////////////////////////+QPFRFAAAAGXRSTlMABqemsQm33dwKu9gbDMDVGLTRFdAyuhMzWn2UxwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAABHSURBVAjXLYtLEoAgFMP6FFD+ICr2/hdVRrJqZhrgQ5ZVMBFFqmnacNtp9NjW0Yfg6SwQE3MBSmaKqDzaOLSTFdfd/7S75wVjnwLiNjP0TwAAAABJRU5ErkJggg==') no-repeat;
			}
		}
	}

	&__inner {
		width: 100%;
		position: absolute;
		left: -1000%;
		z-index: 0;
		opacity: 0;
		top: 0;
		display: block !important;
		.transition(opacity 500ms);

		&_show {
			left: 0;
			opacity: 1;
			z-index: 1;
			position: relative;
		}

	}

}

// <!-- begin tabs -->
// <div class="tabs tabs_detail">
// 	<div class="tabs__inner js-tabs__inner"id="js-tabs-1">

// 	</div>
// 	<div class="tabs__inner js-tabs__inner"id="js-tabs-2">

// 	</div>
// 	<div class="tabs__inner js-tabs__inner"id="js-tabs-3">

// 	</div>
// 	<div class="tabs__inner js-tabs__inner"id="js-tabs-4">

// 	</div>
// 	<div class="tabs__inner js-tabs__inner"id="js-tabs-5">

// 	</div>
// </div>
// <!-- end tabs  -->
