.basket-info {

	&__delete {
		.size(26px, 27px);
		background: url(../images/basket-info__delete.svg) no-repeat;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}

	&__row {
		position: relative;
	}

	.lg({
		margin-bottom: 60px;
		line-height: 1;

		&__inner {
			margin: 0 auto;
			width: 1200px;
		}

		&__row {
			background-color: #fff;
			border: 1px solid #cacaca;
			margin-bottom: -1px;
			display: table;
			width: 100%;
		}

		&__photo {
			width: 215px;
			display: table-cell;
			vertical-align: middle;
			padding: 20px 0;
			text-align: center;
		}

		&__link {
			text-decoration: none;
		}

		&__pic {
			display: inline-block;
			vertical-align: middle;
			max-width: 180px;
			max-height: 150px;
		}

		&__desc {
			display: table-cell;
			vertical-align: middle;
			padding: 20px 0;
		}

		&__table {
			display: table;
			width: 100%;
		}

		&__left {
			width: 490px;
			display: table-cell;
			vertical-align: middle;
		}

		&__info {
			display: inline-block;
			vertical-align: middle;
			margin-right: 30px;
			width: 260px;
		}

		&__name {
			display: inline-block;
			color: @color3;
			font-size: 15px;
			font-weight: 400;
			line-height: 24px;
			text-decoration: underline;
			margin-bottom: 10px;
			text-transform: uppercase;
		}

		&__available {
			color: #000;
			font-size: 13px;
			font-weight: 400;
			position: relative;
			padding: 0 0 0 21px;

			&:before {
				.size(14px, 14px);
				background: url(../images/basket-info__available.png) no-repeat;
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -7px;
			}
		}

		&__articul {
			width: 142px;
			color: #333;
			font-size: 14px;
			font-weight: 400;
			display: inline-block;
			vertical-align: middle;
			line-height: 1.4;
		}

		&__right {
			display: table-cell;
			vertical-align: middle;
		}

		&__count {
			display: inline-block;
			vertical-align: middle;
			margin-right: 80px;
		}

		&__countblock {
		}

		&__minus {
			.size(35, 40);
			display: inline-block;
			vertical-align: middle;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAADBAMAAACzJDhFAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAHlBMVEWQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJD///9XH1USAAAACHRSTlM8RDThwy0zJ1qSqi0AAAABYktHRAnx2aXsAAAACXBIWXMAAAsSAAALEgHS3X78AAAAGklEQVQI12NgFBQUVGCw6OjocGAIS0tLKwAAHPQEXdScoaAAAAAASUVORK5CYII=') no-repeat center center;
		}

		&__input {
			.size(60px, 40px);
			background-color: #fff;
			border: 1px solid #bdbdbd;
			.border-radius(5px);
			display: inline-block;
			vertical-align: middle;
			padding: 0 20px;
			text-align: center;
		}

		&__plus {
			.size(35, 40);
			display: inline-block;
			vertical-align: middle;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAMCAMAAACDd7esAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAANlBMVEWQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJD////ekktjAAAAEXRSTlMAKhLSWgqq8Mh4BmbknEgcDClCFdUAAAABYktHRBHitT26AAAACXBIWXMAAAsSAAALEgHS3X78AAAALUlEQVQI12NgAAFGJgY4YGYhms3KBgTsHECCk4GLGwh4eIEEH8nmgAG/AIgEAEhoASDvljVbAAAAAElFTkSuQmCC') no-repeat center center;
		}

		&__price {
			display: inline-block;
			vertical-align: middle;
		}

		&__priceblock {
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			padding-right: 5px;
		}

		&__pricesumm {
			color: #000;
			font-size: 24px;
			font-weight: 500;

			&_color {
				color: #ff281b;
			}
		}

		&__priceold {
			color: #666;
			font-size: 15px;
			font-weight: 500;
			text-decoration: line-through;
			padding-top: 8px;
			.fa-rub{
				font-size: 14px;
			}
		}

		&__label {
			text-align: left;
			display: inline-block;
			vertical-align: middle;
			color: rgb(255, 255, 255);
			font-size: 15px;
			font-weight: 500;
			line-height: 35px;
			padding: 0 0 0 18px;
			.size(60px, 35px);

		}

		&__footer {
			height: 90px;
			background-color: #e5e5e5;
			.display(flex);
			.justify-content(space-between);
			.align-items(center);
			padding: 0 150px 0 30px;
		}

		&__delivery {
			padding: 0 0 0 50px;
			color: #000;
			font-size: 16px;
			font-weight: 500;
			position: relative;

			&:before {
				position: absolute;
				left: 0;
				top: 50%;
				.size(32px, 26px);
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAaCAMAAADhRa4NAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABd1BMVEUsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTL///8La9naAAAAe3RSTlMAHL/7owqvIhAv0I0pWc4gUNYSVqCAau51Nfgzi80E33NH9ZuRpLdP8lowRkht8ParQeokyOlNNsL87QgDgeb9ykIJkK0m9Igo0ZLdSnr3ODFh2VOwG/pAfNxJK71Sz6Zk2ODVC5UZ+ScWjCHvgiU8rpZFI3Y6LsGTBoPqli68AAAAAWJLR0R80bYgXwAAAAlwSFlzAAALEgAACxIB0t1+/AAAARVJREFUKM9jYGSqRgPMLKwMSICJjZ0DFXBycSMrqOZhQAe8fCgK+DEUCHDRXoEgPz+/ED4FYN8KCuOzQkRUSExcAq8CBgZJKWn8ChhkZOXwK2CVV8CvgEFRiR+XAmUVMEtVDZcCdQ1NLSDQZtbBFZK68nogoG9AIKgNjQgo4K9mqDYmoMDE1IyBwdzC0orbGkmBja2dvYMjWIGTswuDq5u7h6OnAZICL28fV18/f4YAZgaGwKDgEFWgcGiYcDgERERGWUYDRbhjYuPigQGqzOeXANKYaJoEA3biYJPCklVSwGamigCJtPQMuNsyLT2AZBYbjJ+dk5uXL1uA5JHCxKLiEv1SOL+s3F6vAjm7VFZZVfMVgVgAb/5BZXMDArIAAAAASUVORK5CYII=') no-repeat;
				margin-top: -13px;
				content: '';
			}
		}

		&__total {
			color: #373434;
			font-size: 15px;
			font-weight: 400;

			strong {
				color: #000;
				font-size: 24px;
				font-weight: 500;
				margin-left: 25px;
			}
		}

	});

	.sm({
		margin-bottom: 50px;
		line-height: 1;

		&__inner {
			margin: 0 30px;
		}

		&__row {
			background-color: #fff;
			border: 1px solid #cacaca;
			margin-bottom: -1px;
			display: table;
			width: 100%;
		}

		&__photo {
			width: 145px;
			display: table-cell;
			vertical-align: middle;
			padding: 20px 0;
			text-align: center;
		}

		&__link {
			text-decoration: none;
		}

		&__pic {
			display: inline-block;
			vertical-align: middle;
			max-width: 135px;
			max-height: 135px;
		}

		&__desc {
			display: table-cell;
			vertical-align: middle;
			padding: 20px 0;
		}

		&__table {
			display: table;
			width: 100%;
		}

		&__left {
			padding-right: 20px;
			width: 209 / 564 * 100%;
			display: table-cell;
			vertical-align: middle;
		}

		&__info {
			display: inline-block;
			vertical-align: middle;
		}

		&__name {
			display: inline-block;
			color: @color3;
			font-size: 15px;
			font-weight: 400;
			line-height: 24px;
			text-decoration: underline;
			margin-bottom: 15px;
			text-transform: uppercase;
		}

		&__available {
			color: #000;
			font-size: 13px;
			font-weight: 400;
			position: relative;
			padding: 0 0 0 21px;
			margin-bottom: 20px;

			&:before {
				.size(14px, 14px);
				background: url(../images/basket-info__available.png) no-repeat;
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -7px;
			}
		}

		&__articul {
			color: #333;
			font-size: 14px;
			font-weight: 400;
		}

		&__right {
			display: table-cell;
			vertical-align: middle;
			text-align: right;
			width: 100% - (209 / 564 * 100%);
		}

		&__righttable {
			display: table;
			width: 100%;
		}

		&__count {
			display: table-cell;
			vertical-align: middle;
			width: 135 / 356 * 100%;
		}

		&__countblock {
		}

		&__minus {
			.size(24, 40);
			display: inline-block;
			vertical-align: middle;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAADBAMAAACzJDhFAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAHlBMVEWQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJD///9XH1USAAAACHRSTlM8RDThwy0zJ1qSqi0AAAABYktHRAnx2aXsAAAACXBIWXMAAAsSAAALEgHS3X78AAAAGklEQVQI12NgFBQUVGCw6OjocGAIS0tLKwAAHPQEXdScoaAAAAAASUVORK5CYII=') no-repeat center center;
		}

		&__input {
			.size(60px, 40px);
			background-color: #fff;
			border: 1px solid #bdbdbd;
			.border-radius(5px);
			display: inline-block;
			vertical-align: middle;
			padding: 0 20px;
			text-align: center;
		}

		&__plus {
			.size(24, 40);
			display: inline-block;
			vertical-align: middle;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAMCAMAAACDd7esAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAANlBMVEWQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJD////ekktjAAAAEXRSTlMAKhLSWgqq8Mh4BmbknEgcDClCFdUAAAABYktHRBHitT26AAAACXBIWXMAAAsSAAALEgHS3X78AAAALUlEQVQI12NgAAFGJgY4YGYhms3KBgTsHECCk4GLGwh4eIEEH8nmgAG/AIgEAEhoASDvljVbAAAAAElFTkSuQmCC') no-repeat center center;
		}

		&__price {
			display: table-cell;
			vertical-align: middle;
			width: 100% - (135 / 356 * 100%);
			text-align: center;
			white-space: nowrap;
			.catalog-thumb__action{
				margin-left: 3px;
			}
		}

		&__priceblock {
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			padding-right: 5px;
		}

		&__pricesumm {
			color: #000;
			font-size: 22px;
			font-weight: 500;

			&_color {
				color: #ff281b;
			}
		}

		&__priceold {
			color: #666;
			font-size: 14px;
			font-weight: 500;
			text-decoration: line-through;
			padding-top: 8px;
		}

		&__label {
			text-align: left;
			display: inline-block;
			vertical-align: middle;
			color: rgb(255, 255, 255);
			font-size: 15px;
			font-weight: 500;
			line-height: 35px;
			padding: 0 0 0 18px;
			.size(60px, 35px);
		}

		&__footer {
			height: 90px;
			background-color: #e5e5e5;
			.display(flex);
			.justify-content(space-between);
			.align-items(center);
			padding: 0 80px 0 30px;
		}

		&__delivery {
			padding: 0 0 0 50px;
			color: #000;
			font-size: 16px;
			font-weight: 500;
			position: relative;
			max-width: 150px;

			&:before {
				position: absolute;
				left: 0;
				top: 50%;
				.size(32px, 26px);
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAaCAMAAADhRa4NAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABd1BMVEUsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTL///8La9naAAAAe3RSTlMAHL/7owqvIhAv0I0pWc4gUNYSVqCAau51Nfgzi80E33NH9ZuRpLdP8lowRkht8ParQeokyOlNNsL87QgDgeb9ykIJkK0m9Igo0ZLdSnr3ODFh2VOwG/pAfNxJK71Sz6Zk2ODVC5UZ+ScWjCHvgiU8rpZFI3Y6LsGTBoPqli68AAAAAWJLR0R80bYgXwAAAAlwSFlzAAALEgAACxIB0t1+/AAAARVJREFUKM9jYGSqRgPMLKwMSICJjZ0DFXBycSMrqOZhQAe8fCgK+DEUCHDRXoEgPz+/ED4FYN8KCuOzQkRUSExcAq8CBgZJKWn8ChhkZOXwK2CVV8CvgEFRiR+XAmUVMEtVDZcCdQ1NLSDQZtbBFZK68nogoG9AIKgNjQgo4K9mqDYmoMDE1IyBwdzC0orbGkmBja2dvYMjWIGTswuDq5u7h6OnAZICL28fV18/f4YAZgaGwKDgEFWgcGiYcDgERERGWUYDRbhjYuPigQGqzOeXANKYaJoEA3biYJPCklVSwGamigCJtPQMuNsyLT2AZBYbjJ+dk5uXL1uA5JHCxKLiEv1SOL+s3F6vAjm7VFZZVfMVgVgAb/5BZXMDArIAAAAASUVORK5CYII=') no-repeat;
				margin-top: -13px;
				content: '';
			}
		}

		&__total {
			color: #373434;
			font-size: 15px;
			font-weight: 400;

			span {
				width: 110px;
				display: inline-block;
			}

			strong {
				color: #000;
				font-size: 24px;
				font-weight: 500;
				margin-left: 25px;
				display: inline-block;
				vertical-align: bottom;
			}
		}

	});

	.xs({
		margin-bottom: 40px;
		line-height: 1;

		&__inner {

		}

		&__row {
			background-color: #fff;
			border: 1px solid #cacaca;
			margin-bottom: -1px;
			display: table;
			width: 100%;
		}

		&__photo {
			width: 125px;
			display: table-cell;
			vertical-align: top;
			padding: 20px 0;
			text-align: center;
		}

		&__link {
			text-decoration: none;
		}

		&__pic {
			max-width: 100px;
			max-height: 150px;
		}

		&__left {
			padding: 0 50px 0 0;
		}

		&__desc {
			display: table-cell;
			vertical-align: middle;
			padding: 20px 0;
		}

		&__name {
			color: @color3;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-decoration: underline;
			margin-bottom: 15px;
			display: inline-block;
			text-transform: uppercase;
		}

		&__available {
			color: #000;
			font-size: 13px;
			font-weight: 400;
			position: relative;
			padding: 0 0 0 21px;
			margin-bottom: 20px;
			white-space: nowrap;

			&:before {
				.size(14px, 14px);
				background: url(../images/basket-info__available.png) no-repeat;
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -7px;
			}
		}

		&__articul {
			color: #333;
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 25px;
		}

		&__count {
			margin-bottom: 30px;
		}

		&__minus {
			.size(24, 40);
			display: inline-block;
			vertical-align: middle;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAADBAMAAACzJDhFAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAHlBMVEWQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJD///9XH1USAAAACHRSTlM8RDThwy0zJ1qSqi0AAAABYktHRAnx2aXsAAAACXBIWXMAAAsSAAALEgHS3X78AAAAGklEQVQI12NgFBQUVGCw6OjocGAIS0tLKwAAHPQEXdScoaAAAAAASUVORK5CYII=') no-repeat center center;
		}

		&__input {
			.size(60px, 40px);
			background-color: #fff;
			border: 1px solid #bdbdbd;
			.border-radius(5px);
			display: inline-block;
			vertical-align: middle;
			padding: 0 20px;
			text-align: center;
		}

		&__plus {
			.size(24, 40);
			display: inline-block;
			vertical-align: middle;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAMCAMAAACDd7esAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAANlBMVEWQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJD////ekktjAAAAEXRSTlMAKhLSWgqq8Mh4BmbknEgcDClCFdUAAAABYktHRBHitT26AAAACXBIWXMAAAsSAAALEgHS3X78AAAALUlEQVQI12NgAAFGJgY4YGYhms3KBgTsHECCk4GLGwh4eIEEH8nmgAG/AIgEAEhoASDvljVbAAAAAElFTkSuQmCC') no-repeat center center;
		}

		&__priceblock {
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			padding-right: 5px;
		}

		&__pricesumm {
			color: #000;
			font-size: 19px;
			font-weight: 500;

			&_color {
				color: #ff281b;
			}
		}

		&__priceold {
			color: #666;
			font-size: 14px;
			font-weight: 500;
			text-decoration: line-through;
			padding-top: 8px;
		}

		&__label {
			text-align: left;
			display: inline-block;
			vertical-align: middle;
			color: rgb(255, 255, 255);
			font-size: 15px;
			font-weight: 500;
			line-height: 35px;
			padding: 0 0 0 18px;
			.size(60px, 35px);

		}

		&__footer {
			background-color: #e5e5e5;
			.display(flex);
			.justify-content(space-between);
			.align-items(center);
			.flex-direction(column-reverse);
			padding: 25px 0 30px;
		}

		&__delivery {
			padding: 0 0 0 50px;
			color: #000;
			font-size: 16px;
			font-weight: 500;
			position: relative;

			&:before {
				position: absolute;
				left: 0;
				top: 50%;
				.size(32px, 26px);
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAaCAMAAADhRa4NAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABd1BMVEUsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTIsLTL///8La9naAAAAe3RSTlMAHL/7owqvIhAv0I0pWc4gUNYSVqCAau51Nfgzi80E33NH9ZuRpLdP8lowRkht8ParQeokyOlNNsL87QgDgeb9ykIJkK0m9Igo0ZLdSnr3ODFh2VOwG/pAfNxJK71Sz6Zk2ODVC5UZ+ScWjCHvgiU8rpZFI3Y6LsGTBoPqli68AAAAAWJLR0R80bYgXwAAAAlwSFlzAAALEgAACxIB0t1+/AAAARVJREFUKM9jYGSqRgPMLKwMSICJjZ0DFXBycSMrqOZhQAe8fCgK+DEUCHDRXoEgPz+/ED4FYN8KCuOzQkRUSExcAq8CBgZJKWn8ChhkZOXwK2CVV8CvgEFRiR+XAmUVMEtVDZcCdQ1NLSDQZtbBFZK68nogoG9AIKgNjQgo4K9mqDYmoMDE1IyBwdzC0orbGkmBja2dvYMjWIGTswuDq5u7h6OnAZICL28fV18/f4YAZgaGwKDgEFWgcGiYcDgERERGWUYDRbhjYuPigQGqzOeXANKYaJoEA3biYJPCklVSwGamigCJtPQMuNsyLT2AZBYbjJ+dk5uXL1uA5JHCxKLiEv1SOL+s3F6vAjm7VFZZVfMVgVgAb/5BZXMDArIAAAAASUVORK5CYII=') no-repeat;
				margin-top: -13px;
				content: '';
			}
		}

		&__total {
			color: #373434;
			font-size: 15px;
			font-weight: 400;
			// margin-bottom: 25px;

			&-text {
				display: inline-block;
				vertical-align: middle;
				width: 100px;
			}

			strong {
				color: #000;
				font-size: 19px;
				font-weight: 500;
				margin-left: 25px;
				display: inline-block;
				vertical-align: bottom;
			}
		}

		&__delete {
			.size(46px, 46px);
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAPFBMVEXGxsbd3d3KysrPz8/X19fZ2dn////z8/P6+vr9/f3Ozs7Hx8fq6urf39/09PTt7e3h4eHJycnQ0NDe3t6bbkuRAAAAAWJLR0QGYWa4fQAAAAlwSFlzAAALEgAACxIB0t1+/AAAAINJREFUSMft0UsOgCAMBFAURZSPv/vfVSSEUBYwiQs3nV3DW5SpEBzOpwwjneXU0rNaiJd63RrcWOKlVq65DPFdTTygCw/p7EGdPKyj97gOflfqgPW7d3WvjnZh/xPX8b+QT52APjcI+aJvwJPrdH11y56/9E1mY32z/vrRgOVzOP/kAeG/BR3FDna+AAAAAElFTkSuQmCC') no-repeat;
		}

	});
}
