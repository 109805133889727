//main: style.less
.swiper-button-right,
.swiper-button-left{
    background-image: none !important;
    border: solid #888;
    border-width: 0 3px 3px 0;
    padding: 20px;
}

.swiper-button-right{
    transform: translateY(-50%) rotate(-45deg);
    right: 20px;
}

.swiper-button-left{
    transform: translateY(-50%) rotate(135deg);
    left: 20px;
}

