.menu-header {

	.lg({
		position: absolute;
		left: 0;
		top: 11px;

		&__list {
			margin: 0;
			padding: 0;
		}

		&__item {
			margin-left: 30px;
			display: inline-block;
			&_drop{
				position: relative;
				&:hover{
					.menu-header__dropdown{
						left: 50%;
					}
				}
			}
			&:first-child {
				margin-left: 0;
			}
		}

		&__dropdown{
			position: absolute;
			top: 100%;
			left: -9999999px;
			transform: translateX(-50%);
			z-index: 15;
			margin-top: 12px;
			background:#fff;
			text-align: left;
			box-shadow: 9px 9px 21px 0 rgba(0, 0, 0, .14);


			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				height: 30px;
				top: -28px;
			}

			&-triagle{

				width: 12px;
				height: 12px;
				bottom: 100%;
				position: absolute;
				left: 50%;
				margin-left: -6px;
				&:before{
					content: '';
					display: block;
					background-color: @color3;
					transform: rotate(45deg);
					width: 12px;
					height: 12px;
					left: -6px;
					top:5px;
					position: absolute;
				}

			}
			&-list{
				display: inline-block;
				padding-top: 8px;
				padding-left: 10px;
				padding-bottom: 8px;
			}
			&-item{
				padding-top: 12px;
				padding-bottom: 7px;
				white-space: nowrap;

			}
			&-link{
				display: block;
				white-space: nowrap;
				color: #2d2d30;
				text-decoration: none;
				&:hover{
					color: @color3;
				}

			}
			&-info{
				padding-right: 20px;
				padding-left: 10px;
			}
		}
		&__link {
			color: @color;
			font-size: 13px;
			font-weight: 500;
			text-decoration: none;
			transition: .4s;
			&_color{
				color: @color3;
			}
			&:hover {
				color: @color3;
			}
		}

		&__item.active &__link {
			color: @color;
			font-weight: 400;
			text-transform: uppercase;
		}
	});

	.sm({

		&__list {
			.clearfix();
		}

		&__item {
			margin: 0 0 15px;
			padding: 0;

			&:last-child{
				margin: 0;
			}
		}

		&__link {
			color: #fff;
			font-size: 18px;
			font-weight: 400;
			text-decoration: none;

			&_color {
				pointer-events: none;
			}
		}

		&__dropdown {
			padding-top: 15px;

			&-item {
				margin-bottom: 10px;
			}

			&-link {
				color: #fff;
			}
		}
	});

	.xs({

		&__list {
			margin: 0;
			padding: 0;
		}

		&__item {
			margin: 0 0 15px;
			padding: 0;
			text-align: center;
		}

		&__link {
			color: #fff;
			font-size: 18px;
			font-weight: 400;
			text-decoration: none;
			display: block;

			&_color {
				pointer-events: none;
			}
		}

		&__dropdown {
			padding-top: 15px;

			&-item {
				margin-bottom: 10px;
			}

			&-link {
				color: #fff;
			}
		}
	});

	&__col {
		float: left;
		width: 50%;
	}
}
