.reviews-form {
	display: none;
	width: 100%;
	z-index: 3;
	background-color: #f2f2f2;
	padding: 40px 70px 40px;
	line-height: 1;
	animation: fadeEffect 0.2s;

	@keyframes fadeEffect {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	&_hide{
		display: block;
	}

	&__title {
		margin-bottom: 25px;
		color: #2d2d30;
		font-family: Roboto;
		font-size: 26px;
		font-weight: 400;
		text-align: center;
	}

	&__wrapper {
		overflow: hidden;
	}

	&__block {
		display: flex;
		margin-bottom: 15px;
		font-size: 0;

		&_half {
			width: 50%;
			float: left;

			&:last-child {
				padding-left: 20px;

				.reviews-form__col:first-child {
					width: 90px;
				}
			}
		}
	}

	&__col {
		position: relative;
		display: inline-block;
		vertical-align: top;
		box-sizing: border-box;
		padding: 0 10px;
		font-size: 14px;

		&:first-child {
			width: 170px;
			text-align: left;
		}

		&:last-child {
			flex-grow: 1;
		}

		&_rate{
			flex-grow: 0 !important;
		}
	}

	&__text {
		position: relative;
		font-size: 14px;
		line-height: 26px;
		display: inline-block;
		vertical-align: top;
		margin-right: 51px;
		color: #2d2d30;
		font-weight: 700;

		&_phone {
			margin-right: 0;
		}

		&_msg {
			min-width: 95px;
			margin-right: 25px;
		}
	}

	&__input {
		width: 100%;
		height: 28px;
		min-width: 137px;
		background-color: rgb(255, 255, 255);
		border: 1px solid rgb(187, 187, 187);
		padding: 0 10px;
		font-size: 14px;
		color: #2d2d30;
		display: inline-block;
		vertical-align: top;
		.placeholder(rgb(187, 187, 187));
	}

	&__msg {
		width: 100%;
		height: 60px;
		background-color: rgb(255, 255, 255);
		border: 1px solid rgb(187, 187, 187);
		padding: 5px 10px;
		font-size: 14px;
		color: #2d2d30;
		display: inline-block;
		vertical-align: top;
		.placeholder(rgb(187, 187, 187));

		&_comment {
			height: 74px;
		}
	}

	&__footer {
		max-width: 99%;
	}

	&__label {
		font-family: Arial;
		font-size: 12px;
		font-weight: 400;
		color: #2d2d30;
		position: relative;
		display: inline-block;
		padding: 0 0 0 20px;
		padding-top: 14px;
		margin-bottom: 20px;
		line-height: 1.4;

		a {
			color: #00859b;
			font-size: 12px;

			&:hover {
				text-decoration: none;
			}
		}

		span {
			position: relative;
			margin-right: 20px;
			&::after {
				content: "";
				position: absolute;
				display: block;
				z-index: 0;
				top: 0;
				left: 0;
				width: 13px;
				height: 13px;
				background-color: #00859b;
				border-radius: 2px;
			}

			&::before {
				content: "";
				position: absolute;
				z-index: 5;
				display: block;
				top: 0;
				left: 0;
				width: 13px;
				height: 13px;

				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAMAAABFNRROAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAUVBMVEUAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZsAhZv////13fB8AAAAGXRSTlMASubn7ZDs/EOSj+WAGOgRob0D60wGgY7kBxyCQAAAAAFiS0dEGnVn5DIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAABUSURBVAjXVc7JEoAgDAPQFFAQVBbX/v+POgMjS27v0KQgwX8koYFZgPtUqWlu0gZLlrKOecW2Z1n4EFOK5e44gQt3bQkeRrdO96hxoaj/RYJkxUsftsYNYsK3x1AAAAAASUVORK5CYII=') no-repeat;
			}
		}
	}

	&__checkbox {
		position: absolute;
		visibility: hidden;
		left: 0;
		top: 0;

	}

	&__submit {
		float: right;
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 205px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		background-color: #00859b;
		color: #fff;
		font-family: Roboto;
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;
		border-radius: 4px;
		border: none;
		cursor: pointer;

		&:hover {
			background-color: #00a7c3;
		}

	}

	@media (max-width: 520px) {

		&__block {
			flex-wrap: wrap;
		}

		&__col{
			padding: 0;
		}

		padding-left: 25px;
		padding-right: 25px;
		padding-top: 0;
		padding-bottom: 10px;

	}

	@media (max-width: 886px) {

		&__label{
			padding: 0;
		}

		&__submit {
			float: none;
		}
	}

	@media (max-width: 961px) {

		&__block_half {
			width: auto;
			float: none;

			&:last-child {
				padding-left: 0;

				.reviews-form__col:first-child {
					width: 170px;
				}
			}
		}
	}
}

.reviews-form__checkbox:checked + span::before {
	display: block;
}

.reviews-form__checkbox:checked + span::after {
	display: none;
}