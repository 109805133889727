.reviews-rate {
	margin: 0;
	line-height: 1;

	&_big {
		display: inline-block;
		vertical-align: middle;
	}

	&_center {
		width: 100px;
		margin: 0 auto 15px;
		margin-right: 10px;
	}

	&_inline {
		display: inline-block;
		vertical-align: baseline;
	}

	&__section {
		width: 120px;
		height: 20px;
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}

	&_pointer &__section {
		cursor: pointer;
	}

	.reviews-all__col &__section {
		height: 17px;
		width: 100px;
	}

	&_small &__section {
		width: 80px;
		height: 13px;
	}

	&_output &__section,
	&_center &__section {
		cursor: default;
	}

	&__start,
	&__disable {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9ImRfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAyNCAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojYmVjMWM3O308L3N0eWxlPjx0aXRsZT5taWVsZSBzdGFyczwvdGl0bGU+PGcgaWQ9ImRfMiI+PGcgaWQ9IkxheWVyXzEiPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yMS43LDguM0MyMiw4LjEsMjIsNy44LDIyLDcuNUMyMS45LDcuMiwyMS42LDcsMjEuMyw3bC01LjgtMC41Yy0wLjMsMC0wLjUtMC4yLTAuNi0wLjVsLTIuMy01LjVjLTAuMS0wLjQtMC41LTAuNi0wLjktMC40Yy0wLjIsMC4xLTAuNCwwLjItMC40LDAuNEw5LjEsNi4xQzksNi40LDguNyw2LjUsOC41LDYuNkwyLjcsNy4xQzIuNCw3LjIsMi4xLDcuNCwyLDcuN0MxLjksNy45LDIsOC4zLDIuMiw4LjVsNC41LDMuOUM2LjksMTIuNiw3LDEyLjksNywxMy4xbC0xLjMsNS45Yy0wLjEsMC4zLDAsMC42LDAuMywwLjhjMC4yLDAuMiwwLjYsMC4yLDAuOCwwbDUtMy4yYzAuMi0wLjEsMC41LTAuMSwwLjcsMGw1LDMuMWMwLjIsMC4yLDAuNiwwLjIsMC44LDBjMC4yLTAuMiwwLjMtMC41LDAuMy0wLjhsLTEuNC02Yy0wLjEtMC4zLDAtMC42LDAuMi0wLjhMMjEuNyw4LjN6Ii8+PC9nPjwvZz48L3N2Zz4=) repeat-x 0 0;
		z-index: 1;
		.background-size(auto 100%);
	}

	&__click,
	&__hover {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9ImRfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAyNCAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojZmYwMDAwO308L3N0eWxlPjx0aXRsZT5taWVsZSBzdGFyczwvdGl0bGU+PGcgaWQ9ImRfMiI+PGcgaWQ9IkxheWVyXzEiPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yMS43LDguM0MyMiw4LjEsMjIsNy44LDIyLDcuNUMyMS45LDcuMiwyMS42LDcsMjEuMyw3bC01LjgtMC41Yy0wLjMsMC0wLjUtMC4yLTAuNi0wLjVsLTIuMy01LjVjLTAuMS0wLjQtMC41LTAuNi0wLjktMC40Yy0wLjIsMC4xLTAuNCwwLjItMC40LDAuNEw5LjEsNi4xQzksNi40LDguNyw2LjUsOC41LDYuNkwyLjcsNy4xQzIuNCw3LjIsMi4xLDcuNCwyLDcuN0MxLjksNy45LDIsOC4zLDIuMiw4LjVsNC41LDMuOUM2LjksMTIuNiw3LDEyLjksNywxMy4xbC0xLjMsNS45Yy0wLjEsMC4zLDAsMC42LDAuMywwLjhjMC4yLDAuMiwwLjYsMC4yLDAuOCwwbDUtMy4yYzAuMi0wLjEsMC41LTAuMSwwLjcsMGw1LDMuMWMwLjIsMC4yLDAuNiwwLjIsMC44LDBjMC4yLTAuMiwwLjMtMC41LDAuMy0wLjhsLTEuNC02Yy0wLjEtMC4zLDAtMC42LDAuMi0wLjhMMjEuNyw4LjN6Ii8+PC9nPjwvZz48L3N2Zz4=) repeat-x 0 0;
		z-index: 2;
		.background-size(auto 100%);
	}

	&__disable {
		z-index: 3;
		display: none;
	}

	&__hover {
		z-index: 4;
		display: none;
	}

	&__text {
		font-size: 12px;
		font-weight: 700;
		color: #1b1b1b;
		display: inline-block;
		vertical-align: bottom;
		margin-left: 10px;
		position: relative;
		top: 2px;

		span {
			color: #cf121f;
		}
	}
}