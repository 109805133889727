.detail-doc {
	padding: 35px 40px 5px;;
	line-height: 1;

	.xs({
	    padding: 20px 20px 5px;
	});

	&__inner {
		.clearfix(); 
	}

	&__item { 
		float: left;
		padding: 0 60px 30px 0;
		width: 100% / 3;

		.sm({
			width: 50%;
		});

		.xs({
		    margin: 0 0 10px;
			width: 100%;
		}); 
	}

	&__link {
		display: table;
		color: #000;
		text-decoration: underline;
	}

	&__photo {
		width: 55px;
		display: table-cell;
		vertical-align: middle;
	}

	&__pic {
		display: block;
	}

	&__desc {
		display: table-cell;
		vertical-align: middle;
	}

	&__text {
		color: #000;
		font-size: 14px;
		font-weight: 400;
	}
}
