.tooltip {
	opacity: 0;
	position: absolute;
	pointer-events: none;
	line-height: 1;
	right: 0;
	top: 0;
	z-index: 1000;
	max-width: 400px;
	.transform(scale(0));
	.transition(transform 200ms, opacity 200ms);

	&.active {
		pointer-events: auto;
		opacity: 1;
		.transform(scale(1));
	}

	&__arrow {
		position: absolute;

		&_left {
			top: 50%;
			margin-top: -2px;
			left: -4px;
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			border-right: 4px solid rgba(0, 0, 0, .8);
		}

		&_right {
			top: 50%;
			margin-top: -2px;
			right: -4px;
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			border-left: 4px solid rgba(0, 0, 0, .8);
		}

		&_top {
			right: 50%;
			margin-right: -2px;
			top: -4px;
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-bottom: 4px solid rgba(0, 0, 0, .8);
		}

		&_bottom {
			right: 50%;
			margin-right: -2px;
			bottom: -4px;
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-top: 4px solid rgba(0, 0, 0, .8);
		}

	}

	&__section {
		background: rgba(0, 0, 0, .8);
		border-radius: 5px;
		position: relative;
	}


	&__close {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 10px;
		height: 10px;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background: #fff;
			margin-top: -1px;
			top: 50%;
			.transform-origin(50% 50%);
		}

		&:before {
			.transform(rotate(45deg));
		}

		&:after {
			.transform(rotate(-45deg));
		}

	}

	&__text {
		color: #fff;
		line-height: 18px;
		font-size: 12px;
		font-family: Arial;
		padding: 10px;
	}

}

.tooltip-popup {

	&_benefits {
		max-width: 320px !important;

		.tooltip__section{
			background: #e7e7e7;
		}

		.tooltip__text{
			color: #000000;
			font-family: inherit;
			font-size: 16px;
			text-align: center;
		}

		.tooltip__close{
			display: none !important;
		}

		.tooltip__arrow_top {
			margin-right: -5px;
			top: -10px;
			border-bottom: 10px solid #e7e7e7;
			border-right-width:10px;
			border-left-width:10px ;
		}

	}
}