.gallery-touch {
	.user-select(none);
	position: relative;
	box-sizing: border-box;

	&_main {
		margin: -40px 0 60px;

		.sm({
			margin: -36px -30px 40px;
		});

		.xs({
			margin: -26px -20px 30px;
		});
	}

	&__inner {
		overflow: hidden;
		box-sizing: border-box;
	}

	&__list {
		box-sizing: border-box;
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		transform: translate3d(0, 0, 0);
		transition: transform .3s;
	}

	&.move &__list {
		pointer-events: none;
		transition: none;
	}

	&__item {
		box-sizing: border-box;
		margin: 0;
		min-width: 25%;
		width: 25%;
		max-width: 25%;

		.sm({
			min-width: 100% / 2;
			width: 100% / 2;
			max-width: 100% / 2;
		});

		.xs({
			min-width: 100%;
			width: 100%;
			max-width: 100%;
		});
	}

	&_small &__item {
		padding: 0 10px;
	}

	&_video &__item {
		padding: 0 15px;
		min-width: 100% / 3;
		width: 100% / 3;
		max-width: 100% / 3;

		.sm({
			min-width: 100% / 2;
			width: 100% / 2;
			max-width: 100% / 2;
		});

		.xs({
			min-width: 100% / 2;
			width: 100% / 2;
			max-width: 100% / 2;
		});

		@media (max-width: 600px) {
			min-width: 100%;
			width: 100%;
			max-width: 100%;
		}
	}

	&__arrow {
		box-sizing: border-box;
		opacity: 0;
		pointer-events: none;
		transition: opacity 500ms;

		&.active {
			pointer-events: auto;
			opacity: 1;
		}
	}

	&__prev,
	&__next {
		box-sizing: border-box;
		position: absolute;
		top: 0;
		width: 100px;
		height: 100%;
		cursor: pointer;

		.xs({
			width: 40px;
		});

		span {
			.size(60px);
			border: 1px solid transparent;
			.transform(rotate(45deg));
			box-sizing: border-box;
			position: absolute;
			top: 50%;
			margin-top: -30px;
			opacity: .5;
			.transition(opacity 300ms);

			.xs({
				.size(30px);
				margin-top: -15px;
			});

			i {

			}
		}

		&:hover span {
			opacity: 1;
		}
	}

	&__prev {
		left: 0;

		span {
			left: 50px;
			border-left-color: #000;
			border-bottom-color: #000;

			.xs({
				left: 10px;
				border-left-color: #fff;
				border-bottom-color: #fff;
			});

			i {

			}
		}
	}

	&__next {
		right: 0;

		span {
			right: 50px;
			border-top-color: #000;
			border-right-color: #000;

			.xs({
				right: 10px;
				border-top-color: #fff;
				border-right-color: #fff;
			});

			i {

			}
		}
	}

	&_video &__arrow {
		position: absolute;
		right: 15px;
		top: -67px;
		width: 60px;

		.sm({
			top: -46px;
		});

		.xs({
			top: -39px;
		});
	}

	&_video &__prev,
	&_video &__next {
		box-sizing: border-box;
		position: absolute;
		top: 0;
		width: 30px;
		height: 30px;
		cursor: pointer;

		span {
			.size(16px);
			border: 2px solid transparent;
			.transform(rotate(45deg));
			box-sizing: border-box;
			position: absolute;
			top: 50%;
			margin-top: -8px;
			opacity: .5;
			.transition(opacity 300ms);

			i {

			}
		}

		&:hover span {
			opacity: 1;
		}
	}

	&_video &__prev {
		left: 0;

		span {
			left: 10px;
			border-left-color: #000;
			border-bottom-color: #000;

			i {

			}
		}
	}

	&_video &__next {
		right: 0;

		span {
			right: 10px;
			border-top-color: #000;
			border-right-color: #000;

			i {

			}
		}
	}

	&_small &__arrow {
		box-sizing: border-box;
		opacity: 0;
		pointer-events: none;
		transition: opacity 500ms;

		&.active {
			pointer-events: auto;
			opacity: 1;
		}
	}

	&_small &__prev,
	&_small &__next {
		box-sizing: border-box;
		position: absolute;
		top: 0;
		width: 40px;
		height: 100%;
		cursor: pointer;

		.xs({
			width: 30px;
		});

		span {
			.size(70px, 60px);
			background: rgba(255, 255, 255, .7);
			border: none;
			.transform(rotate(0deg));

			i {
				.size(30px);
				border: 2px solid transparent;
				.transform(rotate(45deg));
				box-sizing: border-box;
				position: absolute;
				top: 50%;
				margin-top: -15px;
				.transition(opacity 300ms);

				.xs({
					.size(20px);
					margin-top: -10px;
				});
			}
		}

		&:hover span {
			background: rgba(255, 255, 255, .9);
		}
	}

	&_small &__prev {
		left: 0;

		span {
			left: 0;
			border-radius: 0 10px 10px 0;

			i {
				left: 33px;
				border-left-color: #00869d;
				border-bottom-color: #00869d;

				.xs({
					left: 10px;
				});
			}
		}
	}

	&_small &__next {
		right: 0;

		span {
			right: 0;
			border-radius: 10px 0 0 10px;

			i {
				right: 33px;
				border-top-color: #00869d;
				border-right-color: #00869d;

				.xs({
					right: 10px;
				});
			}
		}
	}

	&__nav {
		box-sizing: border-box;
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 5px;
		text-align: center;
		pointer-events: none;
		transition: opacity 500ms;

		&.active {
			pointer-events: auto;
			opacity: 1;
		}
	}

	&__thumb {
		box-sizing: border-box;
		width: 30px;
		height: 30px;
		padding: 10px;
		display: inline-block;
		cursor: pointer;

		&:after {
			box-sizing: border-box;
			height: 100%;
			width: 100%;
			background: #fff;
			content: '';
			display: block;
			border-radius: 50%;
			transition: background 500ms;
		}

		&.active,
		&:hover {

			&:after {
				background: #c70;
			}
		}
	}

	&.move &__arrow,
	&.move &__nav {
		pointer-events: none !important;
	}


	// user rules
	&__block {
		height: 300px;
		background: red;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		color: #fff;
	}

	&__item:nth-child(2n) &__block {
		background: green;
	}
}
