// main: style.less

.page {
    font-family: @font;
    font-size: @font-size;
    color: @color;
    background: @background;

    &__title {
		color: #000;
		font-size: 36px;
		font-weight: 500;
		text-transform: uppercase;
		line-height: 36px;
		text-align: center;
		margin-bottom: 37px;

		.sm({

			& {
				margin-bottom: 30px;
			}
		});

		.xs({

			& {
				font-size: 28px;
				margin-bottom: 25px;
			}
		});
    }
}

// <h1 class="page__title"></h1>
