.response-popup {
	max-width: 520px;
	background-color: #fff;
	padding: 30px 80px 40px;

	&__inner {

	}

	&__title {
		color: #333;
		font-size: 20px;
		margin-bottom: 35px;
		line-height: 25px;
		text-align: center;
		text-transform: uppercase;
	}

	&__text {
		font-size: 14px;
		font-weight: 400;
		text-align: center;
	}

	@media (max-width: @screen-xs-max) {
		padding: 30px;

		&__title {
			padding:0 30px;
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 20px;
		}
	}
}