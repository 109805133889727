.detail-items{
	border: 1px solid #e2e2e2;
	text-align: center;

	&__stock {
		margin-top: 20px;
		margin-bottom: 20px;
		text-align: center;


		span {
			position: relative;
			padding-left: 20px;
			color: #000;
			font-size: 13px;
			font-weight: 400;
			line-height: 25px;

			&::after {
				content: "";
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				width: 14px;
				height: 14px;
				background-image: url(../images/basket-info__available.png);
			}
		}
	}

	&__photo{
		text-align: center;
		margin-top: 20px;
		.display(flex);
		.justify-content(center);
		.align-items(center);
	}

	&__name{
		color:#000;
		text-decoration: none;
		&:hover{
			color: #00869d;
			text-decoration: underline;
		}
	}

	&__footer {
		z-index: 1;
		margin-top: 20px;
		padding-left: 10px;
		padding-right: 10px;

		overflow: hidden;
		position: relative;
		padding-bottom: 20px;
		background-color: #fff;
		justify-content: space-between;
	}

	&__summ {
		width: 50%;
		float: left;
		text-align: left;
		padding-left: 10px;
	}

	&__old-price {
		color: #47474d;
		line-height: 1;
		font-size: 13px;
		font-weight: 500;
		text-decoration: line-through;
	}

	&__price {
		color: #000;
		font-size: 18px;
		font-weight: 500;
		line-height: 40px;

		&_color {
			line-height: normal;
			color: #ff2521;
		}
	}

	&__button {
		width: 50%;
		float: right;
		text-align: center;
	}

	&__buy {
		color: #fff;
		display: inline-block;
		font-size: 13px;
		font-weight: 500;
		line-height: 40px;
		height: 40px;
		padding-left: 20px;
		padding-right: 20px;
		border-radius: 6px;
		text-decoration: none;
		color: #fff;
		background-color: #00859b;
		text-transform: uppercase;
		.transition(background 500ms);

		&:hover {
			background-color: #08b6d3;
		}
	}

	@media (max-width: 400px) {

		&__buy{
			font-size: 12px;
		}
	}
}
