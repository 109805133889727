.logo {
	float: left;
	width: 140px;
	.lg({
		width: 300px;

		// на время новогоднего лого
		margin: 16px 0 0;

		// margin: 22px 0 0;
	});

	.sm({
		width: 220px;
		margin: 25px 0 0 30px;
	});

	.xs({

		margin: 14px 0 0 20px;
	});

	&__link {
		display: inline-block;
		text-decoration: none;
	}

	&__pic {
		display: block;
		max-width: 100%;

		@media (min-width: @screen-lg-min) {
			// на время новогоднего лого
			height: 63px;

			// height: 42px;
		}

		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			//на время новогоднего лого закоментить
			height: 30px;
		}

		.xs({
			//на время новогоднего лого закоментить
			height: 30px;
		});
	}

}