.block-tag {
	display: table;
	padding-left: 15px;
	margin-bottom: 49px;
	padding-bottom: 10px;
	border-left: 6px solid #00869d;

	p {
		font-size: 15px;
		font-weight: 500;
	}

	&__list {
		margin: 0;
		list-style-type: none;
		.clearfix();
	}

	&__item {
		float: left;
		padding-right: 10px;
	}

	&__link {
		text-decoration: none;
		text-transform: uppercase;
		border-bottom: 1px dashed #118ea4;
	}
}