.news-main {
	line-height: 1;
	margin-bottom: 50px;

	&__title {
		color: rgb(36, 38, 43);
		font-size: 36px;
		font-weight: 400;
		line-height: 36px;
		margin-bottom: 37px;


		.sm({

			& {
				margin-bottom: 30px;
			}
		});

		.xs({

			& {
				font-size: 28px;
				margin-bottom: 25px;
			}
		});
	}

	&__item {

	}

	&__photo {

	}

	&__link {
		text-decoration: none;
	}

	&__pic {
		display: block;
		width: 100%;
	}

	&__content {
		background: #f5f5f5;
		padding: 15px 20px 25px;
	}

	&__date {
		opacity: 0.54;
		color: rgb(1, 82, 148);
		font-size: 12px;
		font-weight: 400;
		margin-bottom: 5px;
	}

	&__name {
		color: rgb(1, 82, 148);
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 15px;
	}

	&__namelink {
		color: rgb(1, 82, 148);
		text-decoration: none;
	}

}