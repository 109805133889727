.about-main {

	.lg({
		width: 1140px;
		margin: 0 auto 40px;

		&__row {
			.display(flex);
			.justify-content(space-between);
		}

		&__desc {
			padding: 20px 0 0;
		}

		&__title {
			color: #000;
			font-size: 36px;
			font-weight: 400;
			margin-bottom: 30px;
			padding: 0 0 0 40px;
		}

		&__text {
			color: #000;
			background-color: #e5e5e5;
			.border-radius(5px);
			padding: 40px 70px 40px 40px;
			margin: 0 -70px 0 0;
			position: relative;
			z-index: 1;
			min-height: 250px;
		}

		&__photo {
			position: relative;
		}

		&__pic {
			display: block;
		}
	});

	.sm({
		margin: 0 0 40px;

		&__row {

		}

		&__desc {
			margin: 0 30px -40px 50px;
		}

		&__title {
			color: #000;
			font-size: 36px;
			font-weight: 400;
			margin-bottom: 30px;
			padding: 0 0 0 40px;
		}

		&__text {
			color: #000;
			background-color: #e5e5e5;
			.border-radius(5px);
			padding: 40px;
			position: relative;
			z-index: 1;
		}

		&__photo {
			position: relative;
		}

		&__pic {
			display: block;
			max-width: 800px;
			margin-left: auto;
			margin-right: auto;
		}
	});

	.xs({
		margin: 0 -20px;

		&__title {
			color: #000;
			font-size: 26px;
			margin-bottom: 15px;
			padding: 0 0 0 20px;
		}

		&__text {
			color: #000;
			background-color: #e5e5e5;
			padding: 20px;
			position: relative;
			z-index: 1;
			font-size: 14px;
			line-height: 22px;
		}

		&__photo {
			position: relative;
		}

		&__pic {
			display: block;
			width: 100%;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
		}
	});

	&__link {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000;
		.opacity(0);
	}
}
