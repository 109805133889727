.catalog-seo {
	margin-top: -10px;
	margin-bottom: 35px;

	.sm({ margin-left: -30px; margin-right: -30px; });

	.xs({ margin-left: -20px; margin-right: -20px; });

	&__inner {
		height: 300px;
		overflow: hidden;
	}
	&__pic {
		display: block;
		margin: 0 auto;
		text-align: center;
		position: absolute;
		width: 2500px;
		left: 50%;
		animation: move 10s ease-in-out;
		animation-fill-mode: forwards;

		img {
			position: static;
			transform: translateX(0%);
		}

		@media (min-width: 2100px) {
			width: 2600px;
			left: 50%;
			img {
				width: 2600px;
			}

		}

		@keyframes move {
			0% {
				transform: translateX(-55%) scale(0.9);

			}
			100% {
				transform: translateX(-50%) scale(1);
			}
		}

		@media (max-width: 991px) {
			width: 100%;
			left: 0;
			animation: none;
			img {
				left: 0;
				transform: translateX(0);
			}
		}
	}

	&__inner {
		position: relative;
	}

	&__desc {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		width: 720px;
		margin-left: -360px;
		.display(flex);
		.flex-direction(column);
		.justify-content(center);
		text-align: center;

		.xs({ width: 100%; margin: 0; left: 0; padding: 0 20px; });
	}

	&__title {
		color: #fff;
		font-size: 36px;
		font-weight: 400;
		line-height: 48px;
		text-transform: uppercase;
		margin-bottom: 30px;

		.sm({ margin-bottom: 10px; });

		.xs({ font-size: 20px; line-height: 20px; margin-bottom: 10px; });
	}

	&__text {
		color: #fff;
		font-size: 16px;
		font-weight: 300;
		line-height: 25px;

		.xs({ font-size: 14px; line-height: 20px; });
	}

}
