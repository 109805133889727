.compare-products {
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar {
		background: #2d2d32;
		height: 10px;
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb  {
		background: #08b6d3;
		height: 10px;
		border-radius: 5px;
	}

	&__list {
		list-style: none;
		margin: 0 0 0 230px;
		padding: 0;
		.clearfix();

		.xs({
			margin: 0 0 0 150px;
		});
	}

	&__col {
		float: left;
		width: 213px;
		border-left: 2px solid #d8d8d8;
		position: relative;

		&:first-child {
			border-color: transparent;
		}

		.sm({
			width: 234px;
		});

		.xs({
			width: 170px;
			border-width: 1px;
		});
	}
}