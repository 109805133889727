.reviews-view {
	float: right;
	margin-bottom: 30px;
	padding-top: 10px;
	line-height: 1;

	&_center{
		float: none;
		text-align: center;
	}

	&__more {
		display: inline-block;
		vertical-align: bottom;
		margin-right: 20px;
		color: #000;
		font-family: Roboto;
		font-size: 16px;
		font-weight: 400;
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		}
	}
	@media (max-width: 393px) {
		&__more {
			margin-bottom: 20px;
		}
	}
}