.header-callback {
	height: 33px;

	.lg({
		float: left;
		margin: 30px 0 24px 55px;
	});

	.sm({
		float: right;
		margin: 23px 30px 29px 0;
	});

	&__link {
		display: table-cell;
		text-decoration: none;
		padding: 0px;
		height: 33px;
		vertical-align: middle;
		color: #494a50;
		font-size: 14px;
		transition: .4s;
		position: relative;

		&:before {
			content: '';
			height: 33px;
			width: 33px;
			display: inline-block;
			vertical-align: middle;
			background: url(../images/call__icon.svg) no-repeat;
			margin-right: 8px;
		}


		&:hover {
			&:before {
				background: url(../images/call__icon-hover.svg) no-repeat left center;
			}
		}
	}

	&__text {
		font-weight: 600;
		color: @color;
		border-bottom: 1px dashed;
	}
}
