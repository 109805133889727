.reviews-all {
	line-height: 1;
	margin-bottom: 25px;
	padding: 12px 10px 20px;

	&__inner {
		padding-top: 20px;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;

		&_border{
			border-top: 1px solid #e2e2e2;
		}
	}

	&__header {
		margin-bottom: 23px;
		color: #1e1e1e;
		font-size: 13px;
		font-weight: 400;
		padding-bottom: 10px;
		display: table;
		width: 100%;
		.clearfix();

		.reviews-rate {
			float: left;
			margin-right: 30px;
		}
	}

	&__text,
	&__txt {
		color: rgb(56, 62, 75);
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 15px;
		display: block;
	}
	&__text{
		display: inline-block;
		vertical-align: top;
		color: #777;
		font-size: 14px;
		font-weight: 700;
		margin-right: 55px;
	}
	&__txt {
		float: left;
		text-align: center;
		margin-bottom: 10px;
		margin-right: 30px;
		color: #000;
		font-size: 20px;
		font-weight: 500;
		line-height: 25px;
		text-transform: uppercase;
	}

	&__link {
		display: inline-block;
		vertical-align: middle;
		color: rgb(77, 82, 93) !important;
		font-size: 14px;
		font-weight: 400;
		margin-right: 55px;
		margin-bottom: 20px;
		text-decoration: none;

		span {
			border-bottom: 1px dashed;
		}

		&:hover span,
		&.active span {
			border-color: transparent;
		}

		&:hover,
		&.active {
			color: #00859b !important;
		}
	}

	&__row {
		font-size: 0;
		margin-bottom: 20px;
		border-bottom: 1px solid #e2e2e2;
	}

	&__col {
		display: inline-block;
		vertical-align: top;
		margin-right: 30px;
		text-align: center;
		overflow: hidden;
		font-size: 15px;
		margin-bottom: 20px;
		.reviews-rate{
			float: left;
		}
		&:last-child{
			margin-right: 0;
		}
	}

	&__info {
		float: left;
		color: #000;
		font-family: Roboto;
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
		text-transform: uppercase;

		&_small{
			font-size: 15px;
			text-transform: lowercase;
			position: relative;
			top: -2px;
		}

		a {
			text-decoration: underline;
			color: #000 !important;

			&:hover{
				text-decoration: none;
			}
		}
	}
}