.contact-map {
	height: 440px;

	.sm({
		margin-right: -30px;
	});

	.xs({
		margin: 0 -20px;
	});
}