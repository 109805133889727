.filter-price {
	margin-bottom: 35px;

	&__top {
		.display(flex);
		.justify-content(space-between);
		.align-items(center);
		margin-bottom: 20px;
	}

	&__input {
		.size(80px, 30px);
		background-color: #fff;
		border: 1px solid #b8b8b8;
		padding: 0 10px;
		color: #000;
		font-size: 14px;
		font-weight: 400;
	}

	&__sep {
		display: block;
		.size(11px, 1px);
		background-color: #949494;
	}

	&__pricesmall {
		.display(flex);
		.justify-content(space-between);
		.align-items(center);
		margin-bottom: 12px;

		span {
			color: #000;
			font-size: 11px;
			font-weight: 500;
		}
	}

	&__slider{
		.size(100%, 7px);
		background-color: #b9b9b9 !important;
		border: none !important;

		.ui-slider-range {
			background: #2b2b2b;
		}

		.ui-slider-handle {
			border-radius: 50%;
			.size(13px) !important;
			background-color: @color3 !important;
			border: 3px solid #fff !important;
			.box-shadow(0 2px 1px rgba(119, 115, 107, 0.75)) !important;

			.sm({
				.size(21px) !important;
				top: -8px;
			});

			.xs({
				.size(21px) !important;
				top: -8px;
			});
		}
	}
}
