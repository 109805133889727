.banner {
	overflow: hidden;
	position: relative;
	width: 100%;

	&__video {
		opacity: 1;
		width: 100%;
		left: 0;
		top: 0;
		position: absolute;

		&_second {
			opacity: 0;
		}

		.xs({
			visibility: hidden;
		});
	}

	&__canvas {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&__inner {
		position: relative;
		z-index: 12;
		max-width: 1200px;
		margin: 0 auto;
	}

	&__pic {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(0, 0);
		opacity: 0;
		transition: opacity 500ms, transform 2500ms;

		.xs({
			max-width: 215px;
		});
	}

	&__desc {
		top: 70px;
		position: absolute;
		right: 0;
		transform: translate(0, 0);
		color: #fff;
		max-width: 280px;
		opacity: 0;
		transition: opacity 500ms, transform 2500ms;

		.xs({
			padding: 0 20px;
			margin: 0 auto;
			max-width: 100%;
			top: 85px;
			transform: translate(110%, 0);
		});
	}

	&.start &__pic {
		opacity: 1;
		transform: translate(40%, 0);
		transition-delay: 5000ms;
	}

	&.start &__desc {
		opacity: 1;
		transform: translate(-50%, 0);
		transition-delay: 5500ms;

		.xs({
			transform: translate(0, 0);
		});
	}

	&__title {
		font-size: 40px;
		text-transform: uppercase;
		font-weight: 100;
	}

	&__code {
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 10px;
		font-weight: bold;
	}

	&__text {
		font-size: 16px;
		line-height: 20px;
		font-weight: 300;
	}
}