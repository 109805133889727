.complain-management{
  max-width: 650px;
  background: #ffffff;
  padding: 45px 45px 25px;
  box-sizing: border-box;
  &__span{
    color: #e38971;
  }
  form{
    .clearfix();
  }
  &__title{
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
  }
  p{
    font-size: 13px;
    text-align: center;
  }
  &__form-desc{
    margin-bottom: 50px;
  }
  &__form-label{
    padding-bottom: 15px;
  }
  &__form-input{
    width: 100%;
    height: 50px;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #e0e0e0;
    margin-bottom: 20px;
    &_textarea{
      height: 100px;
    }
  }
  &__form-block{

  }
  &__form-block_left{
    width: 60%;
  }
  &__form-block_right{
    width: 40%;
  }
  &__form-btn{
    width: 100%;
    display: block;
    padding: 17px 60px;
    background: #2d2c31;
    color: #ffffff;
    margin-top: 31px;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: none;
    outline: none;
    text-align: center;
  }
  &__form-block_right,
  &__form-block_left{
    float: left;
  }
  &__form-block_left{
    padding-right: 15px;
  }
  &__firm-block_right{
    padding-left: 15px;
  }
}
.xs({
  .complain-management{
    padding: 0;

    &__wrap{
      padding: 20px;
    }
    &__form-desc{
      margin-bottom: 20px;
    }
    &__form-label{
      font-size: 14px;
    }
    &__form-input{
      height: 30px;
    }
    &__form-input_textarea{
      height: 70px;
    }
    &__form-btn{
      margin-top: 20px;
    }
    &__form-block_right,
    &__form-block_left{
      float: none;
      width: 100%!important;
      padding: 0;
    }
    &__form-input{
      margin-bottom: 15px;
    }
  }
});