.header-phone {

	&__button {
		display: none;
	}

	&__time {
		display: inline-block;
		cursor: pointer;
		border-bottom: 1px dotted;
	}

	.lg({
		float: left;
		margin: 30px 0 24px 85px;

		&__item {
			float: left;
			margin-left: 40px;
			display: block !important;

			&:first-child {
				margin-left: 0;
			}
		}

		&__number {
			color: #000;
			font-size: 20px;
			font-weight: 700;
			text-decoration: none;
		}

		&__code {
			font-weight: 400;
			color: #000000;
		}

		&__desc {
			padding-top: 4px;
			color: #888;
			font-size: 13px;
			font-weight: 500;
		}
	});

	.sm({
		float: left;
		margin: 21px 0 20px 148px;

		&__item {
			float: left;
			margin-left: 30px;
			display: block !important;

			&:first-child {
				margin-left: 0;
			}
		}

		&__number {
			color: #333;
			font-size: 20px;
			font-weight: 700;
			text-decoration: none;
			border-bottom: 1px dashed;
		}

		&__desc {
			padding-top: 8px;
			color: #888;
			font-size: 13px;
			font-weight: 500;
		}
	});

	.xs({
		position: absolute;
		left: 80px;
		right: 80px;
		bottom: 10px;
		text-align: center;

		&__item {

			&:nth-child(2) {
				display: none;
			}
		}

		&__number {
			color: #333;
			font-size: 17px;
			font-weight: 500;
			text-decoration: none;
			border-bottom: 1px dashed;
			display: inline-block;
			margin-bottom: 6px;
		}

		&__button {
			color: #6a6a6a;
			font-size: 10px;
			font-weight: 700;
			display: inline-block;
			position: relative;
			padding: 0 12px 0 0;
			cursor: pointer;

			&:after {
				content: '';
				.size(9, 5);
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAYAAACXU8ZrAAAAUUlEQVQYV2NkIAIwgtRMnjx5AQMDQzwW9Qtzc3MTwIpwKAQrAMnBFfX39wuwsLAcYGBg0GdgYLj4588fh8LCwg8oikAcqMIJf/78KYApAIkDAJUqGwZ+u4hzAAAAAElFTkSuQmCC') no-repeat;
				position: absolute;
				top: 50%;
				right: 0;
				margin-top: -3px;
			}
		}
	});
}


						// <!-- begin header-phone -->
						// <div class="header-phone">
						// 	<a href="tel:=+74993224716" class="header-phone__number">
						// 		<span class="header-phone__code">+ 8 499</span> 322-47-16;
						// 	</a>
						// 	<div class="header-phone__desc">��� ������ � ��</div>
						// </div>
						// <!-- end header-phone -->
