.services {
	display: block;
	width: 100%;
	clear: both;
	position: relative;

	&__link {
	}
	&__bg {
		width: 100%;
	}
	&__content {
		position: absolute;
		top: 20%;
		bottom: 20%;
		left: 5%;
		right: 50%;
		color: #FFF;
		text-align: center;
		vertical-align: middle;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		cursor: default;
	}
	&__title {
		display: inline-flex;
		width: 100%;
		color: #efefef;
		font-size: 4vw;
		font-weight: 700;
		line-height: 1.05;
		justify-content: center;
		transition: all .15s linear;
	}
	&__text {
		display: none;
		color: white;
		font-size: 2vw;
		line-height: 1.05;
		font-weight: 400;
		justify-content: center;
	}

	&:hover {
		.services__text {
			display: block;
		}
		.services__title {
			font-size: 3vw;
		}
	}

	.lg({ &__title {
		font-size: 60px;
	} &__text {
		  font-size: 24px;
	  } &:hover {
		    .services__title {
			    font-size: 44px;
		    }
	    } });

	&_delivery {

	}
	&_install {
	}
	&_guarantee {
		strong {
			color: #15ddff;
			font-weight: bold;
		}

		margin-bottom: 30px;
	}
}
