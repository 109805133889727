.catalog-mode {
	float: right;
	margin-top: 10px;
	margin-bottom: 35px;

	.sm({
	    display: none;
	});

	.xs({
	    display: none !important;
	});

	&__link {
		.size(24px, 23px);
		display: inline-block;
		margin-left: 20px;
		.transition(background 500ms);

		&_thumb{
			background: url(../images/catalog-mode__link_thumb.png) no-repeat;

			&:hover,
			&.active {
				background: url(../images/catalog-mode__link_thumb-hover.png) no-repeat;
			}
		}

		&_list {
			background: url(../images/catalog-mode__link_list.png) no-repeat;
			.background-size(auto 100%);

			&:hover,
			&.active {
				background: url(../images/catalog-mode__link_list-hover.png) no-repeat;
			}
		}
	}
}
