.catalog-list {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	margin-bottom: 26px;

	&_non-current {
		position: relative;
		&:after {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			content: '';
			background-color: rgba(#fff,0.9);
			z-index: 3;
		}
		.catalog-list__desc {
			position: relative;
			z-index: 4;
		}
		.catalog-list__list {
			opacity: 0.3;
		}
		.catalog-list__top {
			z-index: 4;
			position: relative;
		}
		.compare-add {
			z-index: 4;
			border-color: rgba(#cdc7ba,0.4);
		}
	}

	&__row {
		.display(flex);
		.justify-content(space-between);
	}

	.lg({

		@w1: 255 / 910 * 100%;
		@w2: 400 / 910 * 100%;
		@w3: 100 - @w1 - @w2;

		&__photo {
			width: @w1;
			padding: 25px;
			position: relative;
		}

		&__desc {
			width: @w2;
			padding: 25px 35px 25px 0;
		}

		&__info {
			width: @w3;
			background-color: #e8e8e8;
			padding: 25px 20px 67px;
			position: relative;
		}

	});

	.sm({

		&__row {
			display: block;
			.clearfix();
		}

		@w1: 255 / 710 * 100%;
		@w2: 100 - @w1;

		&__photo {
			width: @w1;
			padding: 25px;
			position: relative;
			float: left;
		}

		&__desc {
			width: @w2;
			padding: 25px 35px 25px 0;
			float: right;
		}

		&__info {
			width: @w2;
			background-color: #e8e8e8;
			padding: 25px 20px 67px;
			position: relative;
			float: right;
		}

	});

	.xs({
		margin-bottom: 17px;

		&__row {
			display: block;
		}

		&__photo {
			padding: 25px;
			position: relative;
		}

		&__desc {
			padding: 25px 20px 15px;
		}

		&__info {
			background-color: #e8e8e8;
			padding: 25px 20px 67px;
			position: relative;
		}

	});

	&__link {
		display: block;
		text-decoration: none;
	}

	&__pic {
		max-width: 100%;
		max-height: 100%;
		display: block;
		margin: 0 auto;
	}

	&__name {
		color: @color3;
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
		text-decoration: underline;
		margin-bottom: 35px;

		.xs({
		    margin-bottom: 15px;
		});
	}

	&__namelink {
		color: @color3;
		text-transform: uppercase;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		line-height: 1;

		li {
			color: #000;
			font-size: 13px;
			margin-bottom: 10px;
			border-bottom: 1px solid #d9d9d9;
			.clearfix();

			strong {
				font-weight: 500;
				float: left;
				background: #fff;
				padding-bottom: 2px;
				top: 3px;
				position: relative;
			}

			span {
				float: right;
				background: #fff;
				padding-bottom: 2px;
				top: 3px;
				position: relative;
			}
		}
	}

	&__top {
		height: 60px;
		.display(flex);
		.justify-content(space-between);
		.align-items(center);
		margin-bottom: 10px;
	}

	&__summ {
		text-align: center;
	}

	&__price {
		color: #000;
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 5px;

		&_color {
			color: #ff281b;
		}
	}

	&__oldprice {
		color: #666;
		font-size: 15px;
		font-weight: 500;
		text-decoration: line-through;
	}

	&__action {
		text-align: left;
		margin-left: 10px;
		color: rgb(255, 255, 255);
		font-size: 15px;
		font-weight: 500;
		line-height: 35px;
		padding: 0 0 0 18px;
		.size(60px, 35px);
		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAjCAMAAAD/opkOAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAApVBMVEX/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv/KBv////0h8pWAAAANXRSTlMAHNTuF9kV0g/ODsgKwgi7BrQDraYBnpWNg3xxav6co6qvtgnBDcbLEtAY3B/hJOMn6CzqER7R2PoAAAABYktHRDZHv4jRAAAACXBIWXMAAAsSAAALEgHS3X78AAAAsUlEQVRIx6XTRwKCQBQE0UYFUVFxFHPOOXP/qxlQYVzxp2v/lgXoWZls+v5szg4F6dbJS6yO3YLIarhYktkk9spCm8CVqtTG2K+J7Q+rutx+sWoY2A8OmiY2wkHLyEa4bWbfuGNoX7hrap+4Z2xD9AcEHprbEGpEYKgxgeFPCAx/SmDM5CvHGN6cwFgsCQx3RWCsNwSGkyEwtjaBYe0IjP2BwDieCIzzhcC43ggM756+B1FEbQL1C8ASAAAAAElFTkSuQmCC') no-repeat;
	}

	&__buy {
		.size(100%, 50px);
		text-align: center;
		.border-radius(5px);
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;
		margin-bottom: 25px;
		display: block;
		.bgButton();
		.vm();
	}

	&__connect {
		color: #000;
		font-size: 13px;
		font-weight: 500;
		padding: 0 0 0 30px;
		background: url(../images/icn-set.svg) no-repeat;
	}

	&__delivery {
		color: #000;
		font-size: 13px;
		font-weight: 500;
		margin-bottom: 13px;
		background: url(../images/delivery.svg) no-repeat;
		min-height: 20px;
		padding: 0 0 0 30px;
	}

	&__install{
		background: url(../images/install.svg) no-repeat;
	}

}
