.label {
	position: absolute;
	top: 10px;
	left: -1px;
	z-index: 1;

	&_gallery {
		left: 125px;
		z-index: 2;

		.sm( {
			left: 0;
		}

		);

		.xs( {
			left: 0;
		}

		);
	}

	&__item {
		margin-bottom: 3px;
	}

	&__pic {
		display: block;
	}

}
