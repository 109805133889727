.tabs-menu {
	line-height: 1;

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		.clearfix();
	}

	&__item {
		display: inline-block;
		vertical-align: middle;
	}

	&__link {
		.size(100%, 40px);
		line-height: 42px;
		background-color: #e1e1e1;
		display: block;
		text-decoration: none;
		text-transform: uppercase;
		padding: 0 25px;
		color: #212121;
		font-size: 14px;
		.border-radius(3px 3px 0 0);
		.transition(color 300ms, background 300ms);

		.sm({
			font-size: 12px;
		});

		&:hover,
		&.active {
			color: #fff;
			background-color: @color3;
		}

	}

}

// <!-- begin tabs-menu -->
// <div class="tabs-menu">

// 	<ul class="tabs-menu__list">
// 		<li class="tabs-menu__item">
// 			<a class="tabs-menu__link js-tabs-menu__link"href="#js-tabs-1">
// 				основные характеристики
// 			</a>
// 		</li>
// 		<li class="tabs-menu__item">
// 			<a class="tabs-menu__link js-tabs-menu__link"href="#js-tabs-2">
// 				подробные характеристики
// 			</a>
// 		</li>
// 		<li class="tabs-menu__item">
// 			<a class="tabs-menu__link js-tabs-menu__link"href="#js-tabs-3">
// 				Преимущества и особенности
// 			</a>
// 		</li>
// 		<li class="tabs-menu__item">
// 			<a class="tabs-menu__link js-tabs-menu__link"href="#js-tabs-4">
// 				сочетание с другими приборами
// 			</a>
// 		</li>
// 		<li class="tabs-menu__item">
// 			<a class="tabs-menu__link js-tabs-menu__link"href="#js-tabs-5">
// 				документация
// 			</a>
// 		</li>
// 	</ul>

// </div>
// <!-- end tabs-menu -->
