.news-article {
	margin-bottom: 50px;

	&__date {
		font-size: 14px;
		text-align: center;
		margin-bottom: 20px;
		text-transform: uppercase;
	}

	&__desc {
		border-radius: 10px;
		margin: 0 0 50px 30px;
		background-color: #f5f5f5;
		padding: 25px 40px 25px 40px;
		float: right;

		.xs({
			margin: 0 0 30px 0;
			padding:20px 20px 20px 25px;
			float: none;
		});

		&-title {
			color: #010101;
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 25px;
			text-transform: uppercase;
		}

		&-list {
			list-style-type: disc;
		}

		&-item {
			color: #00869d;
		}

		&-link {
			color: #000;
			font-size: 15px;
			font-weight: 400;
			line-height: 20px;
			text-decoration: none;
			border-bottom: 1px dashed;
		}
	}

	&__block {
		margin-bottom: 50px;

		.xs({
			margin-bottom: 30px;
		});
	}

	&__title {
		color: #000;
		font-size: 18px;
		font-weight: 500;
		font-style: italic;
		margin-bottom: 20px;

		span {
			margin-right: 10px;
		}
	}

	&__links {
		font-size: 14px;
		font-style: italic;
		position: relative;
		text-decoration: none;
		text-transform: initial;
		border-bottom: 1px dashed #00869d;
		white-space: nowrap;

		&:after {
			top: 5px;
			width: 7px;
			height: 9px;
			content: "";
			right: -10px;
			position: absolute;
			background-image: url(../images/arrow-link.png);
		}
	}


	&__ekspert {
		z-index: 1;
		padding: 45px 20px 45px 90px;
		border-radius: 10px;
		margin-bottom: 40px;
		position: relative;
		background-color: #e8e8e8;

		.xs({
			padding: 30px 25px;
			margin-bottom: 30px;
		});

		&:before {
			top: 40px;
			left: 20px;
			content: "";
			width: 52px;
			z-index: -1;
			height: 37px;
			position: absolute;
			background-repeat: no-repeat;
			background-image: url(../images/news-article_quote.png);

			.xs({
				display: none;
			});
		}

		&-title {
			color: #000;
			font-size: 18px;
			font-weight: 500;
			font-style: italic;
			text-transform: uppercase;
		}

		&-ins {
			color: #3c3c3c;
			font-size: 15px;
			font-weight: 500;
			margin-bottom: 35px;
			font-style: italic;
		}

		&-text {
			color: #000;
			font-size: 18px;
			font-weight: 300;
			font-style: italic;

		}
	}

	&__boxing {
		margin-bottom: 40px;
	}

	&__pic {
		max-width: 100%;
		width: 100%;
	}

	&__full {
		margin-bottom: 40px;
	}

	&__box {
		cursor: pointer;
	}

	img{
		max-width: 100%;
		height: auto;
	}
}