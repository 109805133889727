.video {
	max-width: 1200px;
	margin:  0 auto 15px;
	&-tabs {
		padding-bottom: 30px;
	}
	&__inner {
	}

	&__list {
		list-style: none;
		margin: 0 -17px;
		padding: 0;
		.clearfix();
	}

	&__item {
		float: left;
		padding: 0 14px;

		&_search {
			width: 100% / 3;

			.sm({
				width: 50%;
			});

			.xs({
				width: 100%
			});
		}

		&_thumb {
			width: 100% / 3;

			.sm({
				width: 50%;
			});

			.xs({
				width: 100%
			});
		}

		&_list {
			float: none;
			width: 100%;
		}
	}
}

.video-thumb {
	margin-bottom: 30px;
	line-height: 1;
	.sm({
		margin-bottom: 30px;
	});
	&__link {
		text-decoration: none;
	}
	&__inner {
		position: relative;
	}
	&__pic {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
	}
	&__name {
		color: #333;
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 15px;
		border: 1px solid #ebebeb;
		padding: 15px 60px 15px 10px;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			background: #ebebeb url(../images/small-play.png)  center center no-repeat;
			cursor: pointer;
			padding: 0px 25px;
			top: 0;
			right: 0;
			bottom: 0;
		}
	}
	&__namelink {
		color: #333;
		text-decoration: none;
		cursor: default;
	}

	&__action {
		text-align: left;
		margin-left: 10px;
		color: rgb(255, 255, 255);
		font-size: 15px;
		font-weight: 500;
		line-height: 35px;
		padding: 0 0 0 18px;
		.size(60px, 35px);
	}

	&__buy {
		.size(100%, 50px);
		text-align: center;
		.border-radius(5px);
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;
		position: absolute;
		left: 0;
		bottom: 0;
		.bgButton();
		.vm();
	}
}
