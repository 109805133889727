.menu-footer {
	line-height: 1;

	.lg({
		margin: 0 45px 10px 0;
	});

	.sm({
		margin: 0 0 0 0;
	});

	.xs({
		display: none;
		//& {
		//	border-bottom: 1px solid #666;
		//}
	});

	&__row {

		.lg({
			.display(flex);
			.justify-content(space-between);
		});

		.sm({
			.display(flex);
			.justify-content(space-around);
		});
	}

	&__col {
	}

	&__title {
		color: #000;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 20px;

		.sm({

		});

		.xs({
			text-align: center;
			margin-bottom: 25px;
		});
	}

	&__titlelink {
		color: @color3;
		text-decoration: none;

		&:hover,
		&.active {
			text-decoration: underline;
		}
	}

	&__table {

		.lg({
			display: table;
		});

		.xs({
			display: none;
		});
	}

	&__cell {

		.lg({
			display: table-cell;
			vertical-align: top;

			&:first-child {
				padding-right: 40px;
			}
		});
	}

	&__cell:first-child &__list {
		//margin-bottom: 0;
	}

	&__list {
		list-style: none;
		margin: 0 0 30px;
		padding: 0;

		.xs({
			display: none;
		});
	}

	&__item {
		margin-bottom: 10px;
	}

	&__link {
		color: #000;
		font-size: 11px;
		font-weight: 400;
		text-decoration: none;

		&:hover,
		&.active {
			text-decoration: underline;
		}
	}
}
