.about-help {

	.lg({
		width: 1140px;
		margin: 0 auto 90px;

		&__row {
			.clearfix();
			display: flex;
			flex-direction: column-reverse;
			
			.about-help_about & {
				display: block;
			}
		}

		&__desc {
			
			width: 100%;
			padding: 0;
			
			.about-help_about & {
				width: 100% - (611 / 1140 * 100%);
				float: right;
				padding: 30px 0 0 0;
			}
		}

		&__title {
			color: #000;
			font-size: 36px;
			font-weight: 400;
			margin-bottom: 30px;
			padding: 0 0 0 40px;
			line-height: 36px;
		}

		&__text {
			color: #000;
			background-color: #e5e5e5;
			.border-radius(5px);
			position: relative;
			z-index: 1;
			margin: 0;
			padding: 40px;
			
			.about-help_about & {
				margin: 0 0 0 -60px;
				padding: 40px 40px 40px 100px;
			}
			
			&_culture{
				padding: 40px;
				margin: 0;
				
				.about-help_about & {
					margin: 0 0 0 -30px;
				}
			}
			p {
				margin-bottom: 15px;
			}
		}

		&__photo {
			float: left;
			width: 90%;
			margin: 0 auto;
			
			.about-help_about & {
				width: 611 / 1140 * 100%;
			}
		}

		&__pic {
			display: block;
			width: 100%;
		}
	});

	.sm({
		margin-bottom: 40px;

		&__row {
			.clearfix();
			display: flex;
			flex-direction: column-reverse;
		}

		&__desc {
			padding: 60px 30px 0 0;
		}

		&__title {
			color: #000;
			font-size: 36px;
			font-weight: 400;
			margin-bottom: 30px;
			padding: 0 0 0 20px;
			line-height: 36px;
		}

		&__text {
			color: #000;
			background-color: #e5e5e5;
			.border-radius(5px);
			padding: 40px;
			position: relative;
			z-index: 1;
		}

		&__photo {
			max-width: 550px;
			margin-left: auto;
			margin-right: auto;
		}

		&__pic {
			display: block;
			width: 100%;
		}
	});

	.xs({
		margin: 0 -20px 35px;

		&__row {
			display: flex;
			flex-direction: column-reverse;
		}

		&__title {
			color: #000;
			font-size: 26px;
			margin-bottom: 15px;
			padding: 0 0 0 20px;
		}

		&__text {
			color: #000;
			background-color: #e5e5e5;
			padding: 20px;
			position: relative;
			z-index: 1;
			font-size: 14px;
			line-height: 22px;
		}

		&__photo {
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
		}

		&__pic {
			display: block;
			width: 100%;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
		}
	});
}
