.page-notfound {
	text-align: center;
	padding: 105px 0 0;
	margin: 0 0 170px;

	.sm({

		& {
			padding: 35px 0 0;
		}
	});

	.xs({

		& {
			padding: 25px 0 0;
		}
	});

	&__pic {
		display: block;
		margin: 0 auto 40px;

		.xs({

			& {
				margin: 0 auto 30px;
				width: 250px;
			}
		});
	}

	&__text {
		color: #000;
		font-size: 22px;
		font-weight: 300;

		.xs({

			& {
				font-size: 18px;
			}
		});
	}
}
