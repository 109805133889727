.contact-form {
	background-color: #e7e7e7;
	padding: 55px 30px 50px;
	line-height: 1;

	.sm({
		margin-left: -30px;
		margin-right: -30px;
	});

	.xs({

		& {
			padding: 35px 20px 30px;
			margin-left: -20px;
			margin-right: -20px;
		}
	});

	&__inner {
		max-width: 880px;
		margin: 0 auto;
	}

	&__title {
		color: #000;
		font-size: 24px;
		font-weight: 500;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 40px;

		.xs({

			& {
				font-size: 16px;
				margin-bottom: 30px;
			}
		});
	}

	&__form {
	}

	&__name {
		color: #000;
		font-size: 15px;
		font-weight: 400;
		margin-bottom: 8px;

		span {
			color: #ff5252;
			font-size: 15px;
			font-weight: 400;
		}
	}

	&__input {
		.size(100%, 48px);
		background-color: #fff;
		border: 1px solid #dfdee3;
		padding: 0 20px;
		display: block;
		margin-bottom: 28px;
	}

	&__textarea {
		.size(100%, 246px);
		background-color: #fff;
		border: 1px solid #dfdee3;
		padding: 10px 20px;
		margin-bottom: 28px;
	}


	&__submit {
		.size(205px, 49px);
		.border-radius(5px);
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		border: none;
		margin: 20px auto 0;
		display: block;
		.bgButton();

		.xs({

			& {
				margin-top: 0;
			}
		});
	}

	.lg({

		.row {
			margin-left: -30px;
			margin-right: -30px;
		}

		[class*=col-] {
			padding-left: 30px;
			padding-right: 30px;
		}


	});
}