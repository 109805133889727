.video-menu {
	line-height: 1;
	text-align: center;

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		.clearfix();
	}

	&__item {
		display: inline-block;
		vertical-align: middle;
	}

	&__link {
		display: block;
		text-decoration:  none;
		margin: 15px 8px 30px;
		padding-bottom:  1px;
		color: #1d1e22;
		font-size: 14px;
		border-bottom: 1px dashed #1d1e22;
		.transition(color 300ms, background 300ms);

		.sm({
			font-size: 14px;
		});

		&:hover,
		&.active {
			color: #015294;
			border: none;
			font-weight: 500;
		}

	}

}