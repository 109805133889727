.prod-date {
	&_item {
		display: inline-block;
		float: right;
		color: #00869d;
		font-size: 14px;
		font-weight: 400;

		.xs({ float: none; margin-bottom: 10px; display: block; text-align: right;});
	}

	&_catalog {
			// position: absolute;
			position: relative;
			// bottom: 30px;
			padding-top: 10px;
		color: #fff;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		font-size: 16px;
		font-weight: 700;
		padding-left: 15px;
		padding-right: 15px;
	}

}
