.compare-popup {
	position: fixed;
	bottom: 1px;
	right: 1px;
	z-index: 10;
	display: none;

	&__inner {
		background: #2c2d32;
		padding: 10px 30px 10px 65px;
		border-radius: 5px;
		position: relative;
		border: 1px solid #1a61d7;

		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			padding: 20px 25px 20px 30px;
		}

		@media (max-width: @screen-xs-max) {
			padding: 20px 25px 20px 30px;
		}

		&:before {
			position: absolute;
			left: 20px;
			top: 50%;
			content: '';
			.size(26px, 19px);
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAATCAMAAAC5m+00AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAhFBMVEUaYdf///8aYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdcaYdf///+KX5SQAAAAKnRSTlMAABux/LchMxK0vQb2gfM5ydIP5KvqVGY8MCTYKgktDNucA8Yn1ef5uhVX03hTAAAAAWJLR0QB/wIt3gAAAAlwSFlzAAALEgAACxIB0t1+/AAAAJlJREFUKM99kdcOgkAQRc8CgtIRC02wl/n/DxRiYmAp52Hn4WYzJ3cwTGtlA7YzZK3YiIiL54qGHxC2wyKSET5x+yZs01G0w0llH4F3OA7JVCuQ00f9QacXFYmUFZw0d6dWdNYxzXmkccHv5K8T8iG3+V/3h5Q1NPqufNEQqrmobeM508ZCh7/msyn5uXu9AgxT3t2VC03+o76nEylMiCCx3QAAAABJRU5ErkJggg==') no-repeat;
			margin: -10px 0 0;

			@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
				left: 15px;
			}

			@media (max-width: @screen-xs-max) {
				left: 15px;
			}
		}
	}

	&__text {
		font-size: 15px;
		line-height: 22px;
		color: #fff;

		span {
			display: block;
			text-decoration: none;
			color: #1a61d7;
		}
	}

	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {

		&__count {
			position: absolute;
			border: 1px solid #1a61d7;
			background: #fff;
			text-align: center;
			border-radius: 50%;
			left: 50%;
			top: 50%;
			width: 24px;
			height: 24px;
			font-size: 13px;
			line-height: 22px;
			margin: -27px 0 0 -12px;
		}
	}

	@media (max-width: @screen-xs-max) {

		&__count {
			position: absolute;
			border: 1px solid #1a61d7;
			background: #fff;
			text-align: center;
			border-radius: 50%;
			left: 50%;
			top: 50%;
			width: 24px;
			height: 24px;
			font-size: 13px;
			line-height: 22px;
			margin: -27px 0 0 -12px;
		}
	}

	&__link {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: 0;
	}
}