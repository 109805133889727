.about-info {

	.lg({
		width: 1200px;
		margin: 0 auto 55px;

		&__row {
			display: table;
			width: 100%;
		}

		&__desc {
			display: table-cell;
			vertical-align: top;
			padding: 9px 0 0;
		}

		&__title {
			color: #000;
			font-size: 20px;
			font-weight: 500;
			text-transform: uppercase;
			margin: 0 0 20px 40px;
		}

		&_two &__title {
			padding-top: 50px;
		}

		&_three &__title {
			padding-top: 50px;
		}

		&__section {
			padding: 25px 40px;
			background-color: #e7e7e7;
		}

		&__text {
			color: #333;
			font-size: 15px;
			font-weight: 400;
			line-height: 24px;
		}

		&__photo {
			display: table-cell;
			vertical-align: top;
		}

		&__pic {
			display: block;
		}
	});

	.sm({
		margin: 0 0 30px;

		&__row {
			display: table;
			width: 100%;
		}

		&__desc {
			display: table-cell;
			vertical-align: top;
			padding: 9px 0 0;
		}

		&__title {
			color: #000;
			font-size: 20px;
			font-weight: 500;
			text-transform: uppercase;
			margin: 0 20px 20px 30px;
		}

		&__section {
			padding: 20px 30px;
			background-color: #e7e7e7;
			width: 570px;
			margin: 0 -225px 0 0;
			position: relative;
		}

		&_two &__section,
		&_three &__section {
			width: 100%;
			margin: 0;
		}

		&_two &__photo,
		&_three &__photo {
			width: 50%;
		}

		&_two &__pic,
		&_three &__pic {
			min-width: auto;
			min-height: auto;
			max-width: 100%;
		}

		&__text {
			color: #333;
			font-size: 15px;
			font-weight: 400;
			line-height: 24px;
		}

		&__photo {
			display: table-cell;
			vertical-align: top;
		}

		&__pic {
			display: block;
			min-width: 100%;
			min-height: 100%;
		}
	});

	.xs({
		margin: 0 -20px 25px;

		&__title {
			color: #000;
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;
			margin: 0 20px 20px 20px;
		}

		&__section {
			padding: 20px;
			background-color: #e7e7e7;
		}

		&__text {
			color: #333;
			font-size: 15px;
			font-weight: 400;
			line-height: 24px;
		}

		&__photo {
			margin-bottom: 30px;
		}

		&_one,
		&_one &__photo {
			margin-bottom: 0;
		}

		&__pic {
			display: block;
			width: 100%;
		}
	});
}
