//main: style.less

.swiper-container {

    &_margin {
        margin: -40px 0 50px;

        .sm({
            margin: -37px -30px 50px;
        });

        .xs({
            margin: -25px -20px 30px;
        });
    }
}