.menu-horiz {

	.lg({
		margin: 0 -500%;
		background-color: @color3;

		&__list {
			max-width: 1280px;
			margin: 0 auto;
			height: 61px;
			.display(flex);
			.align-items(center);
			.justify-content(space-between);

		}

		&__item {
			position: relative;
			width: 20%;
			white-space: nowrap;
		}
		&__icon{
			margin-right: 12px;

		}

		&__link {
			.display(flex);
			.align-items(center);
			.justify-content(center);
			height: 61px;
			color: #fff;
			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;
			text-decoration: none;
			padding: 0;
			white-space: nowrap;

		}

		&__item:hover &-sub {
			.opacity(1);
			pointer-events: auto;
		}

		&-sub {
			height: 200px;
			width: 250px;
			position: absolute;
			background: #fff;
			left: 0;
			right: 0;
			.opacity(0);
			pointer-events: none;
			z-index: 2;
			.transition(opacity 500ms);
			margin-left: -12px;
			margin-right: -20px;
			box-shadow: 9px 9px 21px 0 rgba(0, 0, 0, 0.14);

			img{
				width: 100%;
				display: block;
				height: 100%;
				object-fit: cover;
			}
		}
	});

	.sm({
		margin-bottom: 40px;

		&__list {
			margin: 0;
			padding: 0 0 15px;
			.clearfix();
			border-bottom: 1px solid rgba(255, 255, 255, .24);
		}

		&__item {
			float: left;
			width: 50%;
			margin-bottom: 15px;
		}

		&__link {
			color: #fff;
			font-size: 20px;
			font-weight: 500;
			text-transform: uppercase;
			display: block;
			text-decoration: none;
		}
		&__icon{
			display: inline-block;
			margin-right: 12px;
		}
		&-sub {
			display: none;
		}
	});

	.xs({
		margin-bottom: 30px;

		&__list {
			margin: 0;
			padding: 0 0 10px;
			.clearfix();
			border-bottom: 1px solid rgba(255, 255, 255, .24);
		}

		&__item {
			margin-bottom: 15px;
			text-align: center;
		}

		&__link {
			color: #fff;
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;
			display: block;
			text-decoration: none;
		}
		&__icon{
			margin-bottom: 12px;
		}
		&-sub {
			display: none;
		}
	});
}