.basket-form {
	max-width: 708px;
	margin: 0 auto 60px;

	.sm({
		width: auto;
		margin-left: 30px;
		margin-right: 30px;
	});

	.xs({
		margin: 0 20px 30px;
	});

	&__form {
	}

	&__title {
		color: #000;
		font-size: 24px;
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 25px;
		text-align: center;
	}

	&__field {

		.lg({
			display: table;
			width: 100%;
			//padding: 0 40px 0 0;
			margin-bottom: 30px;

			&_city {
				padding: 0;
			}

			&_address {
				margin-bottom: 70px;
			}
		});

		.sm({
			display: table;
			width: 100%;
			//padding: 0 20px 0 0;
			margin-bottom: 30px;

			&_city {
				padding: 0;
			}

			&_address {
				margin-bottom: 70px;
			}
		});

		.xs({
			margin-bottom: 20px;

			&_city {
				padding: 0 0 10px;
			}

			&_address {
				margin-bottom: 50px;
			}

			&_lift {
				margin-bottom: 0;
			}
		});
	}

	&__name {
		display: table-cell;
		vertical-align: middle;
		width: 230px;

		span {
			color: #ff5252;
		}

		.xs({
			display: block;
			width: 100%;
			margin-bottom: 3px;
		});
	}

	&__field_lift &__name {

		.xs({
			margin-bottom: 10px;
		});
	}

	&__field_city &__name {
		vertical-align: top;
		padding: 10px 0 0;
	}

	&__block {
		display: table-cell;
		vertical-align: middle;
		position: relative;

		.xs({
			display: block;
		});
	}

	&__metro {
		.size(100%, 50px);
		background-color: #fff;
		border: 1px solid #dfdee3;
		padding: 0 20px;
	}

	&__line {
		height: 1px;
		background-color: #e1e1e1;
		margin-bottom: 35px;

		.xs({
			margin: 0 -20px 20px;
		});
	}

	&__input {
		.size(100%, 50px);
		background-color: #fff;
		border: 1px solid #dfdee3;
		padding: 0 20px;

		&_small {
			.size(92px, 50px);
		}
	}

	&__hint {
		color: #888;
		font-size: 13px;
		font-weight: 400;
		position: absolute;
		bottom: -30px;
		left: 0;

		.xs({
			line-height: 14px;
			bottom: -40px;
		});
	}

	&__submit {
		.size(265px, 55px);
		.border-radius(5px);
		border: none;
		font-size: 15px;
		font-weight: 500;
		text-transform: uppercase;
		display: block;
		margin: 0 0 0 230px;
		.bgButton();

		.xs({
			margin: 0 auto;
		});
	}
}
