.why{
  h3{
    margin: 0;
  }
}

.why-quote{
  position: relative;
  z-index:2;
  margin-bottom: 65px;
  .clearfix();
  background: @color3;
  color: #ffffff;
  font-style: italic;
  padding: 50px 56px;
  border-radius: 10px;
  &:before{
    content: '';
    position: absolute;
    width: 53px;
    height: 39px;
    display: block;
    background: url(../images/why-quote__icon.png) no-repeat;
    left: 15px;
    top: 50%;
    z-index: -1;
    transform: translateY(-50%);
  }
  &__title{
    font-weight: 500;
    margin-bottom: 35px;
    h3{
      text-transform: uppercase;
      font-size: 18px;
    }
  }
  &__desc{
    font-size: 18px;
    font-weight: 300;
  }
}
.why-text{
  margin-bottom: 35px;
  &__title{
    h3{
      padding-bottom: 15px;
    }
  }
}
.why-info{
  height: 290px;
  background: url(../images/why-info.jpg) no-repeat center center;
  .background-size(cover);
  .display(flex);
  .justify-content(center);
  .align-items(center);

  .sm({
    margin: 0 -30px;
  });

  .xs({
    height: 200px;
    margin: 0 -20px;
  });

  &__text {
    color: #fff;
    font-size:  18px;
    font-weight: 300;
    text-align: center;
    max-width: 890px;

    .sm({
      width: 710px;
      font-size: 14px;
    });

    .xs({
      font-size: 12px;
      line-height: 24px;
      padding: 0 20px;
    });
  }
}