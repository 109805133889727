.basket-city {
	width: 100%;
	background-color: #fff;
	border: 1px solid #dfdee3;
	display: table;
	margin-bottom: 25px;
	&__col {
		display: table-cell;
		width: 50%;

		.xs({
			display: block;
			width: 100%;
		});
	}

	&__label {
		height: 51px;
		.display(flex);
		.justify-content(flex-start);
		.align-items(center);
		position: relative;
		padding: 0 0 0 30px;
	}

	&__input {
		visibility: hidden;
		position: absolute;
		left: 0;
		top: 0;
	}

	&__icon {
		.size(20px);
		background-color: #fff;
		border: 1px solid #cacaca;
		border-radius: 50%;
		display: block;
		position: relative;
	}

	&__input:checked ~ &__icon {
		border: 1px solid @color3;
		background: @color3;
		.box-shadow(0 0 0 4px #fff inset);
	}

	&__bg {
		background-color: #e0e0e0;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		visibility: hidden;
	}

	&__input:checked ~ &__bg {
		visibility: visible;
	}

	&__text {
		padding-left: 15px;
		position: relative;
	}
}
