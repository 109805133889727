.catalog {
	max-width: 1200px;
	margin:  0 auto 15px;
	
	&__list {
		list-style: none;
		margin: 0 -17px;
		padding: 0;
		.clearfix();
	}

	&__item {
		float: left;
		padding: 0 17px;
		width: 100% / 3;

		&_search {
			width: 100% / 4;

			.sm({
				width: 50%;
			});

			.xs({
				width: 100%
			});
		}

		&_thumb {
			width: 100% / 3;

			.sm({
				width: 50%;
			});

			.xs({
				width: 100%
			});
		}

		&_list {
			float: none;
			width: 100%;
		}
	}
}
