.search-form {
	max-width: 1200px;
	margin: 0 auto;

	.lg({
		margin-bottom: 45px;

	});

	.sm({
		margin: 0 30px 30px;
	});

	.xs({
		margin: 0 20px 20px;
	});

	&__form {
		position: relative;


		.xs({
			padding: 0 0 20px 0;
		});
	}

	&__input {
		.size(81%, 40px);
		background-color: #fff;
		border: 1px solid #e8e8e8;
		color: #000;
		font-size: 14px;
		font-weight: 400;
		padding: 0 20px;

		.xs({
			display: block;
			margin-bottom: 10px;
			width: 100%;
		});
	}

	&__submit {
		border: none;
		.size(206px, 39px);
		.border-radius(5px);
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		border: none;
		position: absolute;
		top: 0;
		right: 0;
		.bgButton();

		.xs({
			position: static;
			width: 100%;
		});
	}

	&__result {
		color: #000;
		font-size: 15px;
		font-weight: 400;
		padding: 30px 21px 0;
		span{
			color: @color3;
			strong {
				font-weight: 500;
			}
		}


		.xs({
			padding: 0;
		});
	}
}
