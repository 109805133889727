.filter-sort{
	&__row{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@media (max-width: 640px) {
			justify-content: center;
				}
	}
}