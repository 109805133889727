.out-benefits {
	position: relative;
	margin: 0 auto 45px;
	border-top: 1px solid #e0e0e0;

	.content__benefits & {
		margin-bottom: 0;
	}

	.detail__advantages & {
		margin-bottom: 0;
	}

	&_detail {

		.lg({
		    margin-bottom: 60px;
		});
	}

	&_compare {
		margin-bottom: 0;
	}

	&__inner {
		margin: 0 auto;
		max-width: 1260px;

		.xs({
			margin: 0;
		});
	}
	.certificate {
		height: 163px;
		display: block;
		margin-top: 0;

	}
	.lg({
		border-bottom: 1px solid #e0e0e0;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
			top: 269px;
			border-bottom: 1px solid #e0e0e0;
		}

		&__inner {
			margin-bottom: -1px;
		}
	});

	.sm({
		width: auto;
		margin-right: -30px;
		margin-left: -30px;

		&_compare {
			margin-bottom: 0;
		}
	});

	.xs({
		width: inherit;
		margin: 0 -20px 40px;

		&_compare {
			margin-bottom: 0;
		}
	});

	&__row {
		.clearfix();
	}

	&__col {
		border-right: 1px solid #e0e0e0;
		border-bottom: 1px solid #e0e0e0;
		width: 100% / 3;
		height: 270px;
		float: left;

		.lg({

			&:nth-child(3n) {
				border-right: 1px solid transparent;
			}
		});

		.sm({
			width: 50%;
			height: 240px;
		});

		.xs({
			width: 100%;
			border-right: none;
			height: auto;
			padding: 0 20px;
		});
	}

	&__item {
		padding: 70px 14px;

		.xs({
			padding: 20px 0;
		});
	}

	&__title {
		color: #000;
		font-size: 36px;
		font-weight: 400;
		line-height: 36px;
		margin-bottom: 20px;
	}

	&__text {

		.xs({
			font-size: 14px;
			line-height: 20px;
		});
	}

	&__title ~ &__text {

		.xs({
			margin-bottom: 5px;
		});
	}

	&__photo {
		position: relative;
		display: table-cell;
		vertical-align: top;
		width: 110px;

		.xs({
			width: 80px;
		});
	}

	&__round {
		overflow: hidden;
		position: relative;
		border: 1px solid @color3;
		border-radius: 50%;
		.size(110px);
		.display(flex);
		.align-items(center);
		.justify-content(center);
		.transition(border 500ms);

		svg {
			width: 50px;
			position: relative;
			.transition(all 500ms);
		}

		.xs({
			.size(80px);

			svg {
				.size(30px);
			}
		});

		&:before {
			border-radius: 50%;
			background: @color3;
			position: absolute;
			left: 0;
			top: 0;
			content: '';
			.size(100%);
			.transform(scale(0));
			.transition(transform 400ms);
		}
	}

	&__pic {
		display: block;
		max-width: 110px;

		.xs({
			max-width: 80px;
		});
	}

	&__desc {
		display: table-cell;
		vertical-align: top;
		padding-left: 20px;
	}

	&__name {
		color: #000;
		font-size: 22px;
		font-weight: 500;
		margin-bottom: 10px;

		.xs({
			font-size: 18px;
		});
	}

	.st0{fill: @color3;}
	.st1{fill: @color3;}
	.st2{fill: @color3;}
	.st3{fill:none;stroke: @color3;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;}
	.st4{fill: @color3;}

	&__col:hover &__round {
		border-color: #fff;

		&:before {
			.transform(scale(1));
		}

		.st0{fill:#fff;}
		.st1{fill:#fff;}
		.st2{fill:#fff;}
		.st3{stroke:#fff;}
		.st4{fill:#fff;}

	}
}
