@font-face {
	font-family: 'Roboto Slab';
	src: url('fonts/RobotoSlabRegular.woff') format('woff');
	font-weight:400;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i&subset=cyrillic,cyrillic-ext,latin-ext');

@import url(../css/fonts/font-awesome-4.7.0/css/font-awesome.min.css);

.fa-rub{
  font-size: 22px;
}

