.history__title{
  span{
    text-transform: capitalize;
  }
}
.history__pic{
  .xs({
    margin-left: -20px;
    margin-right: -20px;
  });
}
.history__img{
  display: block;
  width: 100%;
  margin-bottom: 50px;

}

.history__inner_title{
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding-bottom: 10px;
}

.history__inner_text{
  margin-bottom: 70px;
}

.history__menu{
    background: @color3;
    padding: 45px 0;

    .sm({
       margin: 0 -30px;
       padding: 45px 30px;     
    });  

  .xs({
    margin: 0 -20px;
    padding: 45px 20px;
  });

}
.history__menu_list{
  margin: 0;
  font-size: 0;
}
.history__menu_item{
  &:first-child,
  &:nth-child(11){
    border-left: #80c3ce 1px solid;

  }
  &:nth-child(11),
  &:nth-child(12),
  &:nth-child(13),
  &:nth-child(14),
  &:nth-child(15),
  &:nth-child(16),
  &:nth-child(17),
  &:nth-child(18),
  &:nth-child(19),
  &:nth-child(20){
    border-top:hidden;
  }
  display: inline-block;
  font-size: 24px;
  width: 10%;

  box-sizing: border-box;
  border: #80c3ce 1px solid;
  border-left: hidden;
  text-align: center;
  .xs({
    font-size: 18px;
    width: 20%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5){
      border-bottom: hidden;
    }

    &:nth-child(6),
    &:nth-child(16){
      border-left: #80c3ce 1px solid;
    }
  });

}

.history__menu_link{
  text-decoration: none;
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;

  &:hover{
    background: rgba(255,255,255,.2);
    color: rgba(255,255,255,.5);
  }

  &.active{
    background: rgba(255,255,255,.5);
    color: #fff;
  }
}



.history__tabs_info{
    background: @color3;

    .content-fluid{
        .clearfix();
    }
    padding-bottom: 45px;

  .sm({
        margin: 0 -30px;
        padding: 0 30px 45px;
    });

    .xs({
        margin: 0 -20px;
        padding: 0 20px 45px;
    });
}

.history__tabs_info-pic{
  height: 110px;
  min-width: 110px;

  .xs({
    float: none;
    display: none;
  });

}

.history__tabs_info-img{
  display: block;
  width: 100%;
  max-height: 100%;
}

.history__tabs_info{

  .xs({
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  });

}

.history__tabs_info-desc_title{
    font-size: 36px;
    color: #ffffff;
    min-width: 150px;
    text-align: center;

    .xs({
        text-align: left;
        margin: 0 0 20px;
    });
}

.history__tabs_text,
.history__tabs_pic{
  float: left;
  width: 50%;

  .xs({
    float: none;
    width: 100%;
  });

}

.history__tabs_info-desc_text{
    color: #ffffff;

    p {
        margin-bottom: 0;
    }

}

.history__tabs_img{
  display: block;
  width: 100%;
}

.history__tabs_text-title{
  padding-top: 55px;
  text-transform: uppercase;
  font-weight: 500;
  font-style: italic;
  font-size: 18px;
  padding-bottom: 20px;
}

.history__tabs_inner{
  display: none;
}

.tabs__inner_show{
  display: block;
}

.history__tabs_info-desc {
    display: flex;
    align-items: center;

    .xs({
        display: block;        
    });

}