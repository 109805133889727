.compare-item {
	top: 0!important;
	position: absolute;
	padding: 20px;
	text-align: center;
	width: 211px;
	background: #fff;
	z-index: 1;
	transform: translate(0px, 0px)!important;
	left: 0!important;


	.sm({
		width: 232px;
	});

	.xs({
		width: 168px;
	});

	&__close {
		position: absolute;
		right: 0;
		top: 0;
		.size(30px);
		background: #08b6d3;
		cursor: pointer;

		&:before,
		&:after {
			position: absolute;
			left: 50%;
			top: 50%;
			height: 1px;
			width: 20px;
			margin-left: -10px;
			content: '';
			background: #fff;
			.transform-origin(50% 50%);
		}

		&:before {
			.transform(rotate(45deg))
		}

		&:after {
			.transform(rotate(-45deg))
		}
	}

	&__photo {

	}

	&__link {
	}

	&__pic {

	}

	&__name {
		color: #444;
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 10px;
		display: inline-block;
		text-decoration: none;
	}

	&__price {
		color: #000;
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 15px;
	}

	&__buy {
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;
		padding: 10px 20px;
    	background: #08b6d3;
    	color: #ffffff;
    	display: inline-block;
		cursor: pointer;
	}

	.xs({
		padding: 10px;

		&__photo {
			padding-bottom: 10px;
			height: 110px;
			.display(flex);
			.align-items(center);
			.justify-content(center);

			//.fixed & {
			//	height: 0;
			//	padding: 0;
			//}
		}

		&__pic {
			display: block;
			max-width: 100px;
			max-height: 100px;
		}

		&__name {
			font-size: 10px;
			margin-bottom: 5px;
		}

		&__price {
			font-size: 12px;
			margin-bottom: 10px;
		}

		&__buy {
			font-size: 11px;
			padding: 5px 10px;
		}

	});
}