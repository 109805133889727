.header-menu {

	.sm({

		&__button {
			width: 115px;
			height: 83px;
			background: @color3 url(../images/header-menu__button.svg) no-repeat center center;
			cursor: pointer;

			&.active {
				background: @color3 url(../images/header-menu__buttonactive.svg) no-repeat center center;
			}
		}

		&__section {
			.opacity(0);
			.transition(opacity 500ms);
			pointer-events: none;
			background: @color3;
			padding: 50px 30px 45px;
			z-index: 10;
			position: absolute;
			width: 100%;
		}

		&__button.active + &__section {
			.opacity(1);
			pointer-events: auto;
		}

		&__scroll {
		}
	});

	.xs({

		&__button {
			.size(70px, 62px);
			background: @color3 url(../images/header-menu__button.svg) no-repeat center center;
			.background-size(30px auto);
			cursor: pointer;
			position: absolute;
			left: 0;
			top: 61px;
			z-index: 2;

			&.active {
				background: @color3 url(../images/header-menu__buttonactive.svg) no-repeat center center;
				.background-size(25px auto);
			}
		}

		&__scrolldisable {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			overflow: hidden;
		}

		&__section {
			.opacity(0);
			.transition(opacity 500ms);
			pointer-events: none;
			position: fixed;
			left: 0;
			top: 124px;
			right: 0;
			bottom: 0;
			z-index: 12;
		}

		&__button.active + &__section {
			.opacity(1);
			pointer-events: auto; 
		}

		&__scroll {
			background: @color3;
			padding: 40px 20px 20px;
			overflow-y: auto;
			max-height: 100%;
			-webkit-overflow-scrolling: touch;
		}
	});

}