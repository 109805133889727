.callback {
	max-width: 436px;
	padding: 50px;
	background-color: #fff;

	&__title {
		color: #333;
		font-size: 20px;
		font-weight: 500;
		line-height: 25px;
		text-transform: uppercase;
		margin-bottom: 35px;
		text-align: center;
	}

	&__text {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 38px;
	}

	&__form {
	}

	&__name {
		color: #888;
		font-size: 15px;
		font-weight: 400;
		margin-bottom: 10px;

		span {
			color: #ff5252;
		}
	}

	&__input {
		.size(100%, 52px);
		border: 1px solid #b5b5b5;
		background-color: #fff;
		margin-bottom: 25px;
		padding: 0 20px;
	}

	&__submit {
		.size(205px, 49px);
		background-color: #2c2d32;
		.border-radius(5px);
		color: #c9c9c8;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		border: none;
		margin: 0 auto;
		display: block;
		.transition(background 500ms);

		&:hover {
			background-color: @color3;
			color: #fff;
		}
	}


	.xs({

		& {
			padding: 25px;
		}

		&__title {
			margin-bottom: 20px;
		}
	});
}
