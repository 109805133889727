.catalog-thumb {
	background-color: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 6px;
	margin-bottom: 35px;
	line-height: 1;

	.gallery-touch__item & {
		max-width: 285px;
		margin: 0 auto;
	}

	.sm({
		height: auto !important;
	});

	.xs({
		height: auto !important;
	});

	.product-gallery_tabs & {
		border: none;
	}

	.sm({
		margin-bottom: 30px;
	});

	&__inner {
		position: relative;
		padding-bottom: 50px;
	}

	.product-gallery_tabs &__inner {
		padding-bottom: 0;
	}

	&__photo {
		width: 100%;
		height: 246px;
		position: relative;
		overflow: hidden;
		margin-bottom: 19px;
	}

	&__stock {
		text-align: center;
		margin-bottom: 30px;

		span {
			position: relative;
			padding-left: 20px;
			color: #000;
			font-size: 13px;
			font-weight: 400;
			line-height: 25px;
			text-transform: none;

			&::after {
				content: "";
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				width: 14px;
				height: 14px;
				background-image: url(../images/basket-info__available.png);
			}
		}
	}

	&__link {
	}

	&__inf {
		padding-top: 10px;
	}

	&__pic {
		position: absolute;
		left: 50%;
		top: 50%;
		.transform(translate(-50%, -50%));
	}

	&__content {
		padding: 0 15px;

		.xs({
		    .product-gallery_tabs & {
				padding: 0;
			}
		});
	}

	&__name {
		color: #444;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 15px;
	}

	&__namelink {
		color: #444;
		text-decoration: none;
		text-transform: uppercase;
	}

	&__footer {
		height: 60px;
		.display(flex);
		.justify-content(space-between);
		.align-items(flex-start);
	}

	&__summ {
		text-align: center;
	}

	&__price {
		color: #000;
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 5px;


		&_color {
			color: #ff281b;
		}
	}

	&__oldprice {
		color: #666;
		font-size: 15px;
		font-weight: 500;
		text-decoration: line-through;
		.fa-rub{
			font-size: 14px;
		}
	}

	&__action {
		text-align: left;
		margin-left: 10px;
		line-height: 35px;
		padding: 0 0 0 18px;
		.size(60px, 35px);
		position: relative;

		&:before{
			content: '';
			position: absolute;
			display: block;
			width: 34px;
			height: 34px;
			right: 45px;
			border-top: 16px solid transparent;
			border-right: 16px solid #ffd600;
			border-bottom: 16px solid transparent;
			border-left: 0px solid transparent;
		}

		&:after{
			content: '';
			position: absolute;
			display: block;
			width: 43px;
			height: 34px;
			background-color: #ffd600;
			right: 2px;
			bottom: 1px;
		}

		span {
			position: relative;
			z-index: 1;
		}
	}

	&__sale{
		color: #000000;
		font-size: 15px;
		font-weight: 500;
		z-index: 2;
		position: absolute;
	}

	&__buy {
		.size(100%, 50px);
		text-align: center;
		.border-radius(5px);
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;
		position: absolute;
		left: 0;
		bottom: 0;
		.bgButton();
		.vm();
	}

	&__bottom{
		display: flex;
		align-items: center;

		.compare-add{
			position: static;
			display: block;
			border: none;
			padding: 0;
			height: auto;

			a{
				display: flex;
				align-items: center;
				height: 20px;
			}
		}
	}

	&__to-basket{
		min-width: 126px;
		margin-right: 20px;
		padding: 10px 20px;
		text-transform: uppercase;
		background-color: #00859b;
		color: #ffffff;
		text-align: center;
		text-decoration: none;
		border-radius: 5px;

		&:hover{
			background-color: #08b6d3;
		}
	}
}
