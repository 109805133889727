.select-product {
	margin-bottom: 60px;
	position: relative;

	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		margin-bottom: 40px;
	}

	@media (max-width: @screen-xs-max) {
		margin-bottom: 30px;
	}

	&__title {
		color: rgb(36, 38, 43);
		font-size: 36px;
		font-weight: 400;
		line-height: 36px;
		margin-bottom: 37px;


		.sm({

			& {
				margin-bottom: 30px;
			}
		});

		.xs({

			& {
				font-size: 28px;
				margin-bottom: 25px;
			}
		});
	}

	&__clipper {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		overflow: hidden;
		.box-sizing(border-box);
	}

	&__scroller {
		margin: 0 0 -20px !important;
		overflow-x: scroll;
		overflow-y: hidden;
		height: 100%;
		.box-sizing(border-box);
	}

	&__scroller::-webkit-scrollbar {
		width: 0;
		height: 0;
		.box-sizing(border-box);
	}

	&__track {
		.size(100%, 7px);
		background-color: rgb(214, 214, 214);
		.border-radius(4px);
		position: absolute;
		left: 0;
		bottom: 0;
	}

	&__bar {
		position: absolute;
		top: -1px;
		width: 10px;
		height: 7px;
		background-color: @color3;
		.border-radius(4px);
		.box-sizing(border-box);
		z-index: 1;
	}

	&__list {
		list-style: none;
		margin: 0 -15px 30px;
		white-space: nowrap;
		.clearfix();
	}

	&__item {
		margin: 0;
		padding: 0 15px;
		width: 289px;
		display: inline-block;
		white-space: normal;

		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			width: 372px;
		}
	}
}