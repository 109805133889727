.reviews-list {
	margin-bottom: 30px;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	border-bottom: 1px solid #e2e2e2;

	&__item {
		margin-bottom: 50px;
	}

	&__col {
		display: table-cell;
		vertical-align: top;
		font-size: 13px;
		line-height: 20px;
		color: rgb(77, 82, 93);

		strong {
			color: rgb(56, 62, 75);
			font-size: 16px;
			font-weight: 700;
			line-height: 1;
		}

		&:first-child {
			width: 263px;
		}

	}

	&__header {

	}

	&__name {
		display: inline-block;
		vertical-align: top;
		padding-left: 25px;
		margin-right: 120px;
		margin-bottom: 20px;
		color: #000;
		font-size: 18px;
		font-weight: 500;
		line-height: 30px;
		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAMAAABlGZcgAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAllBMVEXn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+f///9d8DnLAAAAMHRSTlMAEpjl+8HCATEyY2V4bDo70tUCM/A0I5e+ZqalLAhf8fM5/p+g0+34+UPkB1XH6PLp2y7IAAAAAWJLR0Qx2dsdcgAAAAlwSFlzAAALEgAACxIB0t1+/AAAAIdJREFUGNNdztsCgUAUheE1E0JFSDGSCulsv//TSfZQ/rvvai2gS0hjYkgB3ZTezUzmnD4t2Eu2xbbZNtthO+wVe8023Z6brd7beR29/XcffnAIfA1xVKfwHCpl9Q+jC+niCEhS+pUmuNKwG9yR78hGzvAYOUf+56IcsCyAqpZN+6S4bWRd4QXjcyJt8YraogAAAABJRU5ErkJggg==') no-repeat;
	}

	&__subject {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 20px;
		padding-left: 35px;
		color: #00859b;
		font-family: Roboto;
		font-size: 18px;
		font-weight: 500;
		text-decoration: underline;

		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAUCAMAAACknt2MAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAxlBMVEXs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Oz///+Qp9ThAAAAQHRSTlMAifWhAXAib0r84QniSS3wNywV2/5XHSPBnZzAl/nzZPfv5wX7YKUD4AbXSw+W0q6FW00QTFoxsYfYafaICv2/Ytp6agAAAAFiS0dEQYnebE4AAAAJcEhZcwAACxIAAAsSAdLdfvwAAADdSURBVCjPfZBXksJADESFAS05GmxyWJNMzhn6/qdiNAvUgg39MdWtV1MKRA8FjGAoFDQC5FEYALN6ft5JJArE4vEEEE2+klQaMDJEGQNIZ/+TXB4wC+IKJpDP3ctFZstWPUqsVVa2YjEXiar4oCrVPqH6t1/U8CcNNUZWu2YLZhtom2g1dUE2+HXEdRjdXr/f64I7kp2BTO+KHY4wpsmExhgNJbt6sakfmmo080MzjeZiF0+0XElea7QRu91hfzgeDy5OW8kbjc4X71aX89+BbS+63m9v2c4rcGxLlW8ZOU5z1y6F5AAAAABJRU5ErkJggg==') no-repeat;

		&:hover {
			text-decoration: none;
		}
	}

	&__text {
		margin-bottom: 10px;
		color: #1b1b1b;
		font-size: 14px;
		max-width: 762px;
	}

	&__field {
		display: block;
		margin-bottom: 5px;
		font-weight: 700;
	}

	&__date {
		margin-top: 20px;
		color: #000;
		font-size: 14px;
		font-weight: 400;
	}

	@media (max-width: 367px) {
		&__col:first-child {
			text-align: center;
		}
	}

	@media (max-width: 393px) {
		&__col:first-child {
			width: 140px;
			padding-right: 20px;
		}
	}

	@media (max-width: 500px) {

		&__col:first-child {
			display: block;
			float: none;
			width: auto;
			padding-right: 20px;
			margin-bottom: 20px;

			.reviews-rate {
				margin-bottom: 20px;
			}
		}

		&__date {
			display: inline-block;
			vertical-align: top;
			margin-top: 0;
			margin-left: 20px;
		}
	}
}
