.gallery-main {
	//background: url(../images/gallery-main.jpg) no-repeat center center;
	height: 540px;
	position: relative;
	overflow: hidden;
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	&__round {

	}

	&:hover {
		.gallery-main__btn {
			&:before {
				transition: .5s;
				transform: translate(4px);

			}
		}
	}
	.lg({ &__descinner {
		background: #ffffff;
		padding: 40px 45px;
		border-radius: 10px;
	} });
	.sm({ height: 490px; &__descinner {
		background: rgba(0, 0, 0, 0.7);
		padding: 30px 35px;
		border-radius: 10px;
	} });

	.xs({ height: 344px; });

	&__row {

		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		.lg({ .display(flex); .justify-content(space-between); .align-items(center); });

		.sm({ .display(flex); .justify-content(space-between); .align-items(center); height: 490px; width: 100%; padding: 0 60px; padding-right: 80px; });

		.xs({ height: 344px; });
	}

	&__desc {
		position: absolute;
		right: 10%;
		width: 535px;

		.xs({ width: 100%; padding: 20px; background: rgba(0, 0, 0, .69); position: absolute; left: 0; top: 0; width: 100%; height: 100%; });

		.sm({ right: 15%; });
	}

	.xs({ &__descinner {
		display: table;
		height: 100%;
		width: 100%;
	} &__desccell {
		  display: table-cell;
		  vertical-align: middle;
	  } });

	&__title {
		color: #24262b;
		font-size: 34px;
		font-weight: 400;
		line-height: 50px;
		margin-bottom: 20px;
		font-family: 'Roboto Slab', serif;
		text-transform: uppercase;
		.sm({ font-size: 24px; line-height: 30px; color: #ffffff;});

		.xs({ color: #fff; font-size: 24px; font-weight: 500; line-height: 30px; text-align: center; });
	}

	&__text {
		color: #24262b;
		font-size: 16px;
		font-weight: 300;
		line-height: 25px;
		margin-bottom: 20px;
		.sm({ font-size: 14px; color: #ffffff;}); 

		.xs({ color: #fff; font-size: 14px; font-weight: 300; line-height: 20px; text-align: center; });
	}
	&__link {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: 0;

	}
	&__btn {
		font-size: 20px;
		font-weight: 400;
		font-style: italic;
		text-transform: uppercase;
		color: @color3;
		padding-left: 24px;
		position: relative;
		text-decoration: none;
		&:before {
			content: '';
			position: absolute;
			width: 13px;
			height: 15px;
			background: url(../images/arrow.png) no-repeat;
			right: 100%;
			left: 0;
			top: 5px;
		}

	}

	&__image {
		position: relative;
		left: 50%;
		transform: translateX(-50%);

		.sm({
			width: 100%;
			height: 100%;
			object-fit: cover;});

	}

}

.gallery-horiz_new{
	margin: 0 0 60px !important;
}