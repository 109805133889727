.payment-method {
	display: block;
	cursor: pointer;

	&__input {
		display: none;
	}

	&__input&:checked ~ &__item {
		background: #e8e8e8;
	}

	&__input:checked ~ &__item &__icon img {
		display: none;

		& + img {
			display: block;
		}
	}

	&__input:checked ~ &__item 	&__circle {
		border-color: #00869d;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: #00869d;
			transform: translate(-50%, -50%);
		}
	}

	&__item {
		display: flex;
		align-items: center;
		padding: 12px 20px;
		border: 1px solid #e8e8e8;
	}

	&__circle {
		position: relative;
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		border-radius: 50%;
		background-color: #fff;
		border: 1px solid #e8e8e8;
	}

	&__icon {
		width: 32px;
		height: 32px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 12px;

		img {
			max-width: 100%;
		}

		img + img {
			display: none;
		}
	}

	&__text {
		color: #2d2d30;
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		margin-left: 10px;
	}

	.xs({
		&__item {
			padding: 12px 13px;
		}
	});
}