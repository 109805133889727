.payment-methods {
	margin: 40px 0 80px;
	
	&__flex {
		display: flex;
	}

	&__left {
		width: 230px;
		flex-shrink: 0;
		padding-top: 10px;
	}
	
	&__right {
		flex-grow: 1;
	}
	
	.xs({
		margin-bottom: 45px;

		&__flex {
			flex-wrap: wrap;
		}

		&__left,
		&__right {
			width: 100%;
		}

		&__left {
			padding: 0;
			margin-bottom: 20px;
		}
	});
}