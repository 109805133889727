.articul-code {
	flex-shrink: 0;
	color: #666;
	font-size: 14px;
	font-weight: 500;

	@media (min-width: 1001px) {
		&:not(:first-child) {
			padding-right: 10px;
			margin-left: 3.5%;
			margin-right: auto;
		}
	}

	@media (min-width: 521px) and (max-width: 767px) {
		&:not(:first-child) {
			padding-right: 10px;
			margin-left: 3.5%;
			margin-right: auto;
		}
	}
}