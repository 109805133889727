.breadcrumbs {
	text-align: center;
	color: #000;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	margin: 0 0 50px;

	.sm({

		& {
			margin-bottom: 30px;
		}
	});

	.xs({

		& {
			margin-bottom: 25px;
		}
	});

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		display: inline-block;
	}

	&__link {
		color: #000;
	}

	&__sep {
		margin: 0 10px;
		display: inline-block;
	}

	&__text {
		display: inline-block;
	}
}
