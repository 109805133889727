//main: style.less

.news {

	&__item {
		min-height: 100%;
		height: auto !important;
	}

	&__date{
		font-weight: 400;
		font-size: 14px;
		text-transform: uppercase;
		padding-bottom: 9px;
	}
	&__pic{
		display: block;
		width: 100%;
	}
	&__titlelink{
		font-size: 18px;
		font-weight: 400;
		color: #010101;
		text-decoration: none;
	}
	&__desc {
		display: block;
		padding-bottom: 30px;

	}
	//.lg({
    //
	//	&__inner {
	//		margin-bottom: 40px;
	//		.clearfix();
	//	}
    //
	//	&__item {
	//		background-color: #fff;
	//		display: table;
	//		margin: 0;
	//	}
    //
	//	&__photo {
     //     display: block;
	//	}
    //
	//	&__link {
	//	}
    //
	//	&__pic {
	//		display: block;
	//		width: 100%;
	//	}
    //
	//	&__desc {
     //     	display: block;
	//		padding: 20px 0px 30px 40px;
    //
	//	}
    //
	//	&__title {
	//		color: #1a61d7;
	//		font-size: 18px;
	//		font-weight: 400;
	//	}
    //
	//	&__titlelink {
	//		color: @color;
	//		font-weight: 600;
	//		text-decoration: none;
	//		&:hover{
	//			text-decoration: underline;
	//		}
	//	}
    //
	//	&__text {
	//	}
	//});

	//.sm({
    //
	//	&__inner {
	//		margin-bottom: 30px;
	//		padding: 0 30px;
	//	}
    //
	//	&__item {
	//		border-bottom: 1px solid #e4e4e4;
	//		padding-bottom: 20px;
	//		display: table;
	//		width: 100%;
	//		margin: 0 auto 30px;
	//	}
    //
	//	&__photo {
	//		display: table-cell;
	//		vertical-align: top;
	//		width: 235px;
	//	}
    //
	//	&__link {
	//	}
    //
	//	&__pic {
	//		display: block;
	//		width: 100%;
	//	}
    //
	//	&__desc {
	//		display: table-cell;
	//		vertical-align: top;
	//		padding: 0 30px;
	//	}
    //
	//	&__title {
	//		color: #1a61d7;
	//		font-size: 24px;
	//		font-weight: 400;
	//		margin-bottom: 30px;
	//	}
    //
	//	&__titlelink {
	//		color: #1a61d7;
	//	}
    //
	//	&__text {
	//	}
	//});

	//.xs({
    //
	//	&__inner {
	//		margin-bottom: 30px;
	//		padding: 0 20px;
	//	}
    //
	//	&__item {
	//		border-bottom: 1px solid #e4e4e4;
	//		padding-bottom: 10px;
	//		display: table;
	//		width: 100%;
	//		margin: 0 auto 20px;
	//	}
    //
	//	&__photo {
	//		margin-bottom: 15px;
	//	}
    //
	//	&__link {
	//	}
    //
	//	&__pic {
	//		display: block;
	//		width: 100%;
	//	}
    //
	//	&__desc {
    //
	//	}
    //
	//	&__title {
	//		color: #1a61d7;
	//		font-size: 18px;
	//		font-weight: 400;
	//		margin-bottom: 10px;
	//	}
    //
	//	&__titlelink {
	//		color: #1a61d7;
	//	}
    //
	//	&__text {
	//	}
    //
	//});

	.row {
		margin: 0;
	}
	[class*=col-] {
		padding: 0;
	}
}
