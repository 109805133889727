.compare-add {
	border-top: 1px solid #cdc7ba;
	position: absolute;
	left: 0;
	right: 0;
	padding: 0 20px;
	bottom: 0;
	height: 65px;
	.display(flex);
	.align-items(center);

	.detail & {
		position: static;
		display: block;
		border: none;
		padding: 0;
		height: auto;

		a {
			padding-top: 2px;
		}
	}

	a {
		padding: 0 0 0 30px;
		position: relative;
		text-decoration: none;
		color: #000;
		font-size: 14px;
		font-weight: 400;

		&:before {
			.size(20px);
			background-color: #fff;
			border: 1px solid #b8b8b8;
			position: absolute;
			left: 0;
			top: 0;
			content: '';
			border-radius: 5px;
		}

		&.active:before {
			background: @color3;
			border-color: @color3;
			.box-shadow(0 0 0 5px #fff inset);
		}
	}
}
