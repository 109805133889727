.catalog-banner {
	border: 2px solid #00869d;
	padding: 15px 25px;
	margin-bottom: 20px;

	&__link {
		color: black;
		font-size: 14px;
		font-weight: 400;
		margin-right: 40px;
		text-decoration: none;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			color: #00869d;
			text-decoration: underline;
		}
	}

	@media (max-width: 991px) {
		margin-top: 64px;
	}

	@media (max-width: 767px) {
		margin-top: 117px;

		&__link {
			display: block;
		}
	}
}