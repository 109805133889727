.reviews-tags {

	margin-bottom: 50px;

	&__title{
		margin-bottom: 30px;
	}
	&__link {
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		color: #000;
		padding-right: 10px;
		padding-left: 10px;
		margin-bottom: 5px;
		text-decoration: none;

		span {
			position: relative;
			&::after {
				content: "";
				position: absolute;
				display: block;
				width: 100%;
				height: 1px;
				bottom: 0;
				left: 0;
				border-bottom: 1px dashed #000;
			}
		}

		&_active {
			position: relative;
			background-color: #ececec;
			color: #00869d;
			border-bottom: none;
			font-weight: 700;
			text-decoration: none;

			span::after{
				display: none;
			}

			&::after {
				content: "";
				position: absolute;
				display: block;
				width: 7px;
				height: 7px;
				background-image: url(../images/tags.png);
				right: 1px;
				top: 50%;
				transform: translateY(-50%);

			}
		}
	}

	&_small {
		background-color: #f2f2f2;
		padding: 30px;

		.reviews-tags__link {
			font-size: 14px;
		}
		.reviews-tags__link_active {
			background-color: #fff;
		}
	}

	&__about {
		display: block;

		width: 137px;
		height: 43px;
		margin-top: 40px;
		line-height: 43px;
		background-color: #00859b;
		border-radius: 5px;
		text-decoration: none;
		text-align: center;
		color: #fff;
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		transition: background-color 500ms;
		&:hover {
			background-color: #08b6d3;
		}
	}

}