.detail-desc {

	&__text {
		text-align: center;
	}

	.lg({
		width: 1140px;
		margin: 0 auto 90px;

		&__title {
			color: #000;
			font-size: 24px;
			font-weight: 500;
			margin-bottom: 40px;
			text-align: center;
			text-transform: uppercase;
		}
	});

	.sm({
		margin: 0 30px 40px;

		&__title {
			color: #000;
			font-size: 26px;
			font-weight: 500;
			margin-bottom: 25px;
			text-align: center;
			text-transform: uppercase;
		}
	});

	.xs({
		margin-bottom: 35px;

		&__title {
			color: #000;
			font-size: 26px;
			margin-bottom: 15px;
			text-align: center;
			text-transform: uppercase;
			font-weight: 500;
		}
	});
}
