.filter {
	margin-bottom: 13px;
	padding: 30px;
	background-color: #ececec;
	line-height: 1;

	&-slider.noUi-target {
		width: calc(100% - 14px);
		height: 7px;
		border: none;
		background: #fff;
		margin-left: 7px;
		margin-bottom: 35px;
	}

	&-slider .noUi-connect {
		background: #015294;
	}

	&-slider.noUi-horizontal .noUi-handle {
		cursor: move; /* fallback if grab cursor is unsupported */
		cursor: -webkit-grab;
		cursor: grab;
		position: absolute;
		top: -5px;
		right: -7px !important;
		width: 14px;
		height: 14px;
		background: #015294;
		border-radius: 14px;
		border: 3px solid #fff;
		box-shadow: 0 3px 3px 0 rgba(50, 50, 50, 0.75);

		&.noUi-active {
			cursor: grabbing;
		}

		&::before,
		&::after {
			content: none;
		}
	}

	.sm({ display: none; padding: 40px; margin-bottom: 35px; });

	.xs({ display: none; });

	&__row {

		.sm({ .clearfix(); margin: 0 -30px; });
	}

	&__col {

		.sm({ width: 50%; float: left; padding: 0 30px; });
	}

	&__block {
		margin-bottom: 20px;
	}

	&__name {
		color: #000;
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 15px;
	}

	&__footer {
		.display(flex);
		.justify-content(space-between);
		.align-items(center);

		.sm({ padding-top: 20px; .justify-content(center); });

		.xs({ .justify-content(center); });
	}

	&__reset {
		color: @color3;
		font-size: 14px;
		font-weight: 400;
		border: none;
		border-bottom: 1px dashed #1a61d7;
		background: none;

		.sm({ margin-right: 10px; });

		.xs({ margin-right: 10px; });
	}

	&__submit {
		.size(103px, 43px);
		background-color: @color3;
		.border-radius(3px);
		border: none;
		display: block;
		color: #fff;
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		.transition(background 500ms);

		.sm({ margin-left: 10px; });

		.xs({ margin-left: 10px; });

		&:hover {
			background-color: #343745;
			color: #c9c9c8;
		}
	}
}

.bx_filter .bx_filter_popup_result.right {
	left: auto;
	right: 0;

	@media (max-width: 768px) {
		left: auto;
		right: 0;
	}
}