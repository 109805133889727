.detail-settings {
	padding: 30px 30px 20px;

	.sm({
	    padding: 20px 20px 5px;
	});

	.xs({
	    margin: 0 -20px;
		padding: 0;
		line-height: 16px;
	});

	&__inner {

	}

	&__title {
		font-size: 16px;
		font-weight: 700;
		margin: 0 0 12px;

		.xs({
			margin: 20px 20px 0;
		});
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		margin: 0;
		padding: 2px 0;
		border-bottom: 1px dashed #cccccc;
		display: table;
		width: 100%;

		&:last-child {
			border-bottom: none;
		}

		.xs({
		    display: block;
			padding: 10px 20px;
		});
	}

	&__term {
		color: #000;
		font-size: 14px;
		font-weight: 500;
		display: table-cell;
		vertical-align: top;
		width: 400px;

		.sm({
		    width: 245px;
		});

		.xs({
		    width: 100%;
			display: block;
		});
	}

	&__values {
		color: #000;
		font-size: 14px;
		font-weight: 400;
		display: table-cell;
		vertical-align: top;

		.xs({
		    display: block;
			width: 100%;
		});

		ul {
			margin: 0;
			list-style: none;

			li {
				margin-bottom: 5px;
				padding: 0 0 0 20px;
				position: relative;

				&:before {
					.size(6px);
					background-color: @color3;
					border-radius: 50%;
					left: 0;
					position: absolute;
					left: 0;
					top: 8px;
					content: '';
				}
			}
		}
	}

}
