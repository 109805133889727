.scroll-view {
	position: relative;
	margin-top: 20px;
	margin-bottom: 30px;
	user-select: none;

	&__section {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	&__list {
		.display(flex);
	}

	&__item {
		min-width: 100%/4;
		max-width: 100%/4;
		min-height: 100%;
		padding: 0 10px;
		padding-bottom: 10px;
	}

	&__scrollbar {
		height: 25px;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		pointer-events: none;
		z-index: 1;
		background: #fff;
	}

	&__track {
		height: 10px;
		background-color: #cbcfd2;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
	}

	&__thumb {
		background-color: #00859b;
		position: absolute;
		left: 0;
		bottom: 0;
		height: 100%;
	}

	&__prev,
	&__next {
		position: absolute;
		z-index: 3;
		bottom: -9px;
		text-decoration: none;
		display: inline-block;
		vertical-align: middle;
		margin-top: 3px;
		width: 14px;
		height: 26px;
		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAaCAMAAACw0Z1uAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA21BMVEUrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKyv///+MAOuQAAAASHRSTlMAiWWLzBEBj8kQkccPlcSXww2awQwCnb8KA6C8ormltwmotgfUzQZG4GMiZiHfaB/dbB7cbh3achvZdBnXeBjWehd+FdKBzxNkAkKHAAAAAWJLR0RI8ALU6gAAAAlwSFlzAAALEgAACxIB0t1+/AAAAIBJREFUGNNVztkCgVAARdErRGQuM4WQIfOsQob+/4883nP223rbQkAJBZVMpSXUTFYD5fI6qFBElcoVULVmSJn1RlOq1e6guj1U37Kl7MFwBGfOeIKjU3eGFHN3QV56K/J6syXv9gfy8XQmX6438t0PyOHjSX45Efn9+bJ//BPrf38YC2zW3zyiAAAAAElFTkSuQmCC') no-repeat;
		&:hover {
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAaCAMAAACw0Z1uAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAk1BMVEUAhp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp0Ahp3///+L03HkAAAAMHRSTlMAXxWG1BeA1xp52R113h9v3yJp4SZl5CjlLFrnMFXoM1DpN0zqatq7f3RuZF47q0g3IkH+AAAAAWJLR0Qwrtwt5AAAAAlwSFlzAAALEgAACxIB0t1+/AAAAHVJREFUGNNVztsCQkAARdFRxIwI434fokL5/7/r9Zz9tt62sC4Cu9oO+eZ6ZKl88j0IyY8oJic6JWd5QbbKilw3LbnrB/JoJvL8XJDO+gJ57hvkKwkKgw8ojjZQqhNQke+gqjxAbVODhr4DTWYEfc2Mo78T9Qcl7gbBZeQ/SwAAAABJRU5ErkJggg==') no-repeat;
		}

	}

	&__prev {
		float: right;
		left: -20px;
		&:hover {
			transform: rotate(180deg);

		}
	}

	&__next {
		float: right;
		right: -20px;
		transform: rotate(180deg);

		&:hover {
			transform: rotate(0deg);
		}
	}

	@media (max-width: 1137px) {

		&__item {
			min-width: 100%/3;
			max-width: 100%/3;
		}
	}

	@media (max-width: 888px) {

		&__item {
			min-width: 100%/2;
			max-width: 100%/2;
		}
	}

	@media (max-width: 768px) {

		&__prev{
			left: -15px;
		}

		&__next{
			right: -15px;
		}
	}


	@media (max-width: 587px) {

		&__item {
			min-width: 320px;
		}
	}

}