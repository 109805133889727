.compare {
	border-top: 2px solid #d8d8d8;
	border-bottom: 2px solid #d8d8d8;
	position: relative;
	line-height: 1;
	margin: 0 0 30px;

	.xs({
		margin-left: -20px;
		margin-right: -20px;
	});

	&__back {
	    position: absolute;
	    top: -28px;
	    left: 0;
	    width: 100%;
	    font-size: 12px;
	    text-align: center;
	}

	.xs({
		border-width: 1px;
		border-left: none;
		border-right: none;
	});

	&__top {
		padding-top: 428px;
		.transition(padding 300ms);

		.fixed & {
			//padding-top: 250px;
		}

		.xs({
			padding-top: 200px;

			.fixed & {
				//padding-top: 150px;
			}
		});
	}

	&__fixed {
		//height: 428px;
		//.box-shadow(0 3px 5px 0px #555);
		//.transition(height 300ms);

		.fixed & {
			//height: 220px;
			//position: fixed;
		}

		.xs({
			height: 200px;

			//.fixed & {
			//	height: 110px;
			//}
		});
	}

	&__photo {
		height: 250px;
		line-height: 250px;
		padding-bottom: 20px;
		overflow: hidden;
		.transition(height 300ms, padding 300ms);

		//.fixed & {
		//	height: 0;
		//	padding: 0;
		//}
	}

	&__pic {
		display: inline-block;
		vertical-align: middle;
		max-height: 230px;
		max-width: 230px;
	}
}