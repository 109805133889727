.count-show {
	.display(flex);
	.justify-content(flex-end);
	.align-items(center);
	margin-bottom: 35px;

	.sm({
	    display: none;
		.justify-content(flex-start);

		&_bottom {
			.display(flex);
			.justify-content(flex-end);
		}
	});

	.xs({
	    display: none;
		.justify-content(space-between);
		margin-bottom: 15px;
	});

	&__text {
		color: #777;
		font-size: 14px;
		font-weight: 700;
		margin-right: 25px;
	}

	&__select {
		.size(89px, 42px);
		border: 1px solid #d9d9d9;
		background-color: #fff;
		position: relative;

		&:after {
			width: 42px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			background: #fff url(../images/option__icon.png) no-repeat center center;
			pointer-events: none;
			content: '';
		}

		select {
			background: none;
			border: none;
			border-radius: 0;
			.appearance(none);
			height: 40px;
			width: 100%;
			color: #000;
			font-size: 14px;
			font-weight: 400;
			padding: 0 0 0 20px;
		}

		option {
		}
	}
}
