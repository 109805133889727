.certificate {
	margin: 20px 0;
	padding-bottom: 15px;
	border-bottom: 1px solid #c8c8c8;
	text-align: center;

	&__link {
		text-decoration: none;
		border: 3px solid #2d2d30;
		display: inline-block;
	}

	&__pic {
		max-width: 100%;
	}

	.sm({ display: none; });

	.xs({ display: none; });
}

.certificate-popup {
	width: 476px;
	height: 390px;
	padding-top: 20px;
	background-color: #fff;
	text-align: center;

	&__title {
		margin-bottom: 20px;
		color: #2d2d30;
		font-family: Roboto;
		font-size: 20px;
		font-weight: 500;
		line-height: 25px;
		text-align: center;
		text-transform: uppercase;
	}

	&__garant {
		width: 320px;
		margin-left: auto;
		margin-right: auto;
		padding: 10px;
		background-color: #c61308;
		color: #fff;
	}

	&__link {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 20px;
		width: 161px;
		height: 227px;
		border: none;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
		img {
			max-width: 161px;
		}
	}

	@media (max-width: 520px) {
		width: 320px;
		padding-left: 10px;
		padding-right: 10px;

		&__garant {
			width: auto;
		}
	}

	@media (max-width: 340px) {
		width: 280px;

		&__title{
			font-size: 16px;
		}
	}
}

