.available {
	color: #000;
	font-size: 13px;
	font-weight: 400;
	position: relative;
	padding: 0 0 0 21px;
	margin-bottom: 13px;

	.detail & {
		margin-bottom: 0;
	}

	.catalog-block__content & {
		margin-bottom: 20px;
		display: inline-block;
	}

	.catalog-block__inf & {
		margin-bottom: 10px;
	}

	&:before {
		.size(14px, 14px);
		background: url(../images/basket-info__available.png) no-repeat;
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		margin-top: -7px;
	}
}
