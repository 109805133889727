.faq-accordion {
  	width: 80%;
  	margin: 0 auto;
  	padding-bottom: 30px;

  	.xs({
  		width: 100%;
  	});

  		&__inner {
			&_header {
		  	position: relative;
		  	padding: 10px 40px 10px 0;
		  	margin: 5px;
		  	cursor: pointer;
		  	h3 {
				font-size: 15px;
				font-weight: 500;
				color: #333;
				font-style: italic;
				margin: 0;
		  	}
		  	.faq-burger {
				position: absolute;
				right: 6px;
				top: 45%;
				width: 26px;
				height: 26px;
				padding: 1px;
				text-align: center;
				&:after, &:before {
			  	height: 4px;
			  	width: 24px;
			  	content: "";
			  	background-color: @color3;
			  	display: block;
			  	position: absolute;
			  	.transition(500ms all);
				}
				&:after {
			  		transform: rotate(90deg);
				}
		  	}
		}
		&_body {
		  	background-color: #f9f9f9;
		  	border-radius: 6px;
		  	padding: 20px 35px;
		  	display: none;
		  	// display: table;
		  	width: 100%;
		  	.transition(0.4s ease-out-in);

		}
		&.in {
		  	.faq-accordion__inner_body {
				display: table;
		  	}
		  	.faq-burger {
				&:after {
			  		transform: rotate(0);
				}
		  	}
		}
  	}

  	&__title {
		font-size: 15px;
		font-weight: 500;
		color: #333;
		font-style: italic;
		margin: 0;
  	}

	&__body_content{
		.xs({
			margin-left: -20px;
			margin-right: -20px;
		});
	}
  	&__body_img {
		.clearfix();

  		ul {
  			margin: 0;
  			padding: 0;
  			list-style-type: none;

  			li {
  				float: left;
  				margin: 10px 0;
  				text-align: center;
  				width: 100% / 4;
  				position: relative;

  				.sm({
					width: 50%;
				});

				.xs({
					width: 100%
				});

  				img {
  					margin: 0 auto;
  				}
  			}
  		}
  	}

  	&__play {
		.size(52px, 52px);
		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAMAAADypuvZAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABO1BMVEX///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+g1MVRAAAAaHRSTlMAGUNjfJGdpX1EHl2UqJZeATCCkGdFLRgMBCsxIYOKJCNihCIFp4hQT4dgZCacIKFvbqNpJZuebQamZYaFMoFOXAdhC70buWYJjhqqSZkqfrx7FpIXtkICjy+uaBUfNbFVcg8/oihGn0faEAMAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAACGElEQVRIx52WaVubQBDHJ4fExvxNJCe5o4Aa22Abe2irNnVrtfVM7H2ffv9vUEge2wC7sGFesDDDj2fZOYn8EosnknNKKqXMzSduxUhC0gsZuGQxmw4mckuq/Vq+UCyVK5pWKVeLhbytUGs5MVNvAM1Ey61sd5rA8ooA0bMGzFWeZW0dRlfnWcomsCHawwZglv3q23egtsU776lQql7lkoVNnQJEv4t7NbeqZaFPIVKAteX6n/vhDFEfD6ZcrZvYDGeIHsJ89O+hi4YuA2kqujf39W3UZRj7DGHceFnFjhzj+OtxbnxTgynLED3B7njdw6o8tI+Gs6TRlGeITPTs61N0vIbBrhh6hqx9zWDLazhge89FUBuLdjAgT36IHb44ElDHeElxTgDZEGOvTnJcqIAi7SDOhxg7PeNBRSToHGciiF1cDv1QFfM0wpUQYuz1wsBrHGJECg4CIMaMN55fq0AhA28DIcbeucuThpQEdPrebQVCt/dhzbO9AayQgzj8WPEaPyETeOTM/Mw/8gDnWl+II45zhWH09ZvGY8ZhJAjYi+8/iC9OwPJT47glQCapwU3CnySUSRJGSvdZC4s6XiOVsEjFknrbWJFjpsoyZbE8cwMgfV261Ux9PEpTk2uffU/7lGvUv2pepcRI8NuvjjJ8RBtzKNJARf9Htz+lod00joYlidHNkdmHxInE4p3k6Bq4HiX54+hfSEtfh8eypL4AAAAASUVORK5CYII=') no-repeat;
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -26px 0 0 -26px;
  	}
}

