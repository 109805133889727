.lts(@value:50) {
	letter-spacing: @value / 1000em;
}

.vm (@value:100%) {
	&:after {
		content: "";
		display: inline-block;
		vertical-align: middle;
		height: @value;
	}

}

.xs (@rules) {
	@media (max-width:@screen-xs-max) {
		@rules();
	}

}

.sm (@rules) {
	@media (min-width:@screen-sm-min) and (max-width:@screen-sm-max) {
		@rules();
	}

}

.md (@rules) {
	@media (min-width:@screen-md-min) and (max-width:@screen-md-max) {
		@rules();
	}

}

.lg (@rules) {
	@media (min-width:@screen-lg-min) {
		@rules();
	}

}

.bgButton(@bg: #00859b, @color: #fff, @bgH: #08b6d3, @colorH: #fff) {
	background-color: @bg;
	color: @color;
	.transition(background 500ms, color 500ms);

	&:hover {
		background-color: @bgH;
		color: @colorH;
	}
}