.footer-contact {
	line-height: 1;

	.xs({
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	});

	&__title {
		color: #000;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 20px;
		&:hover,
		&.active {
			text-decoration: underline;
		}
		.xs({
			display: none;
		});
	}

	&__titlelink {
		color: #000;
		text-decoration: none;
	}

	&__list {
		list-style: none;
		margin: 0 0 34px;
		padding: 0;

		.xs({
			margin-bottom: 0;

		});
	}

	&__item {
		padding: 0 0 0 45px;
		margin-bottom: 22px;

		.xs({
			margin-bottom: 14px;

		});
	}

	&__text {
		display: table-cell;
		height: 30px;
		vertical-align: middle;
	}

	&__phone {
		color: #000;
		font-size: 13px;
		font-weight: 700;
		margin-bottom: 7px;
		a {
			span {
				font-weight: 400;
			}
		}
		.xs({
			margin: 0 25px 0 0;
			display: inline-block;
			vertical-align: middle;


		});

		&:last-child {
			margin: 0;
		}

		a {
			color: #000;
			text-decoration: none;
		}
	}

	&__item_phone {
		background: url(../images/footer-contact__item_phone.svg) no-repeat left center;
		.background-size(28px);

	}

	&__item_email {
		background: url(../images/footer-contact__item_email.svg) no-repeat left center;
		.background-size(28px);
		height: 30px;

	}

	&__item_address {
		background: url(../images/footer-contact__item_address.svg) no-repeat left center;
		.background-size(28px);

		.xs({
			margin-bottom: 14px;
		});
	}

	&__address {
		color: #000;
		font-size: 13px;
		font-weight: 500;
	}

	&__email {
		color: #000;
		font-size: 13px;
		font-weight: 700;
		margin-bottom: 7px;
		a {
			span {
				font-weight: 400;
			}
		}
		.xs({
			margin: 0 25px 0 0;
			display: block;
			vertical-align: middle;


		});

		&:last-child {
			margin: 0;
		}

		a {
			color: #000;
			text-decoration: none;
		}
		.footer-block__link_post{
			color: @color3;
			font-weight: 600;
			border-bottom: 1px dashed @color3;
			&:hover{
				text-decoration: none;

			}
		}
	}

	&__logo {
		margin-bottom: 0;

		img {
			display: block;
			height: 34px;
			height: auto; // стиль для новогоднего логотиа
			// margin-top: 40px;  // стиль для новогоднего логотиа
			margin-bottom: 10px;
		}
		.sm({
			img {
				display: block;
				margin-top: 30px;
				margin-bottom: 20px;
			}
		});
		.xs({
		});
	}
}