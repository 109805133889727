.header-basket {
	height: 43px;
	padding: 0 0 0 56px;
	float: right;
	position: relative;
	margin: 23px 0 24px 0;

	&:before {
		content: '';
		height: 43px;
		width: 43px;
		background: url(../images/header-basket.svg) no-repeat left center;
		position: absolute;
		left: 0;
		display: inline-block;
	}

	.sm({
		float: none;
		position: absolute;
		left: 50%;
		top: 18px;
		margin: 0 0 0 -77px;
	});

	&__text {
		height: 43px;
		display: table-cell;
		vertical-align: middle;
		font-size: 14px;
		font-weight: 500;
		color: #727476;
	}

	&__line {
		color: @color3;
		text-decoration: underline;
	}

	&__link {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000;
		.opacity(0);
	}

	.xs({
		height: 33px;
		background: url(../images/header-basket.svg) no-repeat left center;
		.background-size(auto 100%);
		padding: 0 0 0 41px;
		float: none;
		position: absolute;
		top: 16px;
		right: 20px;
		margin: 0;
		&:before {
			height: 30px;
			width: 30px;
		}

		&__text {
			height: 33px;
		}
	});
}
