.seo-blocktext {
    text-align: justify;
    max-width: 1200px;
    margin: 0 auto 40px;
}

.seo-blocktext h1,
.seo-blocktext h2,
.seo-blocktext h3,
.seo-blocktext h4,
.seo-blocktext h5,
.seo-blocktext h6,
.seo-blocktext__title {
    color: #000;
    font-size: 36px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 30px;
    text-align: left;
}

@media (max-width: 767px) {

    .seo-blocktext h1,
    .seo-blocktext h2,
    .seo-blocktext h3,
    .seo-blocktext h4,
    .seo-blocktext h5,
    .seo-blocktext h6,
    .seo-blocktext__title {
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 25px;
    }
}