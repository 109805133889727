input:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: transparent !important;
}

input:focus::-moz-placeholder { /* Firefox 19+ */
	color: transparent !important;
}

input:focus:-moz-placeholder { /* Firefox 18- */
	color: transparent !important;
}

input:focus:-ms-input-placeholder { /* IE 10+ */
	color: transparent !important;
}