.contact-info {

	&_rekvisit {
		padding-top: 30px;
		float: right;
		padding-right: 30px;
		max-width: 550px;

		@media (max-width: @screen-xs-max) {
			float: none;
		}
	}

	&_rekvisit &__row {
		width: auto;
	}

	&_rekvisit &__photo,
	&_rekvisit &__desc {
		vertical-align: top;
	}

	&__row {

		.lg({
			width: 1130px;
			margin: 0 auto 55px;
			.display(flex);
			.justify-content(space-between);
			.align-items(center);
		});

		.sm({
			padding: 0 30px;
			margin: 0 auto 35px;
			.display(flex);
			.justify-content(space-between);
			.align-items(flex-start);
		});

		.xs({

			& {
				padding: 0 20px;
				margin-bottom: 60px;
			}
		});
	}

	&__item {

		.xs({

			& {
				margin-bottom: 30px;
			}
		});
	}

	&__photo {

		.lg({
			display: table-cell;
			vertical-align: middle;
			width: 90px;
		});

		.sm({

			& {
				margin-bottom: 20px;
			}
		});

		.xs({
			display: table-cell;
			vertical-align: middle;
			width: 80px;
		});
	}

	&__pic {
		display: block;
		.size(62);
	}

	&__desc {

		.lg({
			display: table-cell;
			vertical-align: middle;
		});

		.xs({
			display: table-cell;
			vertical-align: middle;
		});
	}

	&__text {
		color: #000;
		font-size: 14px;
		line-height: 24px;
		font-weight: 400;

		strong {
			font-weight: 500;
			display: block;
		}

		a {
			color: #000;
			text-decoration: none;
		}
	}

	.xs({
		margin-left: -20px;
		margin-right: -20px;
	});
}
