.insikate-tabs {
	padding-top: 40px;
	position: relative;
}

.insikate-tabs__bg{
	width: 100%;
	height: 370px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;

}

.insikate-menu {
	text-align: center;

	&__inner {
		position: relative;
		display: inline-block;
	}

	&__pic {
		display: block;
		margin: 0 auto;
		max-width: 100%;
	}

}

.insikate-menu__list{
	list-style-type: none;
}

.insikate-menu__link{
	top: 0;
	display: block;
	color: #ffffff;
	padding: 7px 16px;
	position: absolute;
	background: @color3;
	text-decoration: none;
	left: ~'calc(45% - 10px)';

	.xs({
		font-size: 9px;
		line-height: 1;
		padding: 2px 6px 3px;
		display: none;
	});

	.sm({
		font-size: 12px;
		line-height: 1;
		padding: 10px;
	});

	&.active{
		color: @color3;
		font-weight: 500;
		background: #ffffff;
	}
}

.insikate-navigation {
	left: 0;
	right: 0;
	top: 30px;
	z-index: 3;
	text-align: center;
	position: absolute;

	.prev,
	.next {
		margin: 5px;
		text-decoration: none;
		.transition(500ms all);

		.fa {
			color: #454545;
			font-size: 55px;
		}

		&:hover {

			.fa {
				color: @color3;
			}

		}

	}

	.xs({
		text-align: right;
	});

}
.insikate__inner {
	display: flex;
	justify-content: space-around;

	&_title{
		font-size: 18px;
		font-weight: 500;
		font-style: italic;
		padding-bottom: 40px;
	}

	&_text {
		width: 55%;
	}

	&_photo {
		width: 45%;
		margin-left: 20px;
		text-align: right;
		align-self: flex-start;
	}


	.xs({
		display: block;

		&_photo {
			width: 100%;
			margin: 0 0 15px 0;

			img {
				width: 100%;
			}
		}

		&_text {
			width: 100%;
		}
	});
}