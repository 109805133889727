.garants-map {
	background: #ececec;

	@w1: 808 / 1280 * 100%;
	@w2: 100% - @w1;

	&__row {
		.clearfix();
	}

	&__map {
		display: none;

		.lg({
			width: @w1;
			float: left;
			height: 577px;
			display: block;
		});
	}

	&__desc {
		padding: 20px 0;

		.lg({
			width: @w2;
			float: left;
			height: 577px;
		});

		.sm({
			padding: 20px 30px;
			margin-bottom: 20px;
		});

		.xs({
			margin: 0 0 20px;
		});
	}

	&__section {

		.sm({
			margin: 0 0 -40px;
			overflow: hidden;
		});

		.xs({
			margin: 0 0 -40px;
			overflow: hidden;
		});
	}

	&__field {
		position: relative;
		cursor: pointer;

		.lg({
			padding: 0 85px 0 30px;			
		});

		.sm({
			padding: 0;
		});

		.xs({
			padding: 0 20px;
		});

		.lg({

			&:hover,
			&.active {
				&:before {
					position: absolute;
					left: 0;
					top: 0;
					bottom: 20px;
					content: '';
					width: 4px;
					background-color: @color3;
				}				
			}
		});
	}

	&__item {
		border-bottom: 1px solid #c4c4c4;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	&__text {

		strong {
			display: block;
		}
	}

	&__link {

		.lg({
			display: none;
		});
	}
}