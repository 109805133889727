.catalog-buttons {
	width: 50%;
	float: right;
	.display(flex);
	.justify-content(space-between);
	position: relative;
	z-index: 1;

	.lg({
	    display: none;
	});

	.sm({ float: none;
		width: 100%;
		margin-bottom: 16px;
	});

	.xs({
	    width: 100%;
		margin-bottom: 16px;
	});

	&__button {
		height: 53px;
		background-color: #e7e7e7;
		color: #010101;
		font-size: 16px;
		font-weight: 400;
		padding: 0 0 0 25px;
		display: block;
		line-height: 53px;
		position: relative;
		width: 49.5%;
		cursor: pointer;

		&:after {
			content: '';
			position: absolute;
			right: 20px;
			.size(12px, 7px);
			background: url(../images/option__icon.png) no-repeat;
			margin-top: -3.5px;
			top: 50%;
			.transition(transform 500ms);
		}

		.xs({
		    padding: 0 0 0 15px;
		    font-size: 15px;
		    line-height: 53px;

		    &:after {
		    	right: 15px;
		    }
		});

		&.active {

			&:after {
				.transform(rotate(180deg));
			}
		}
	}

}
