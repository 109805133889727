.cubik-rubik {
	.clearfix();

	.lg({

		&__inner {
			height: 870px;
		}

		&__top {
			float: left;
			width: ~'calc(50% + 230px)';
			.display(flex);
			.justify-content(flex-end);
			.align-items(center);

			&-desc {
				padding: 0 60px 0 0;
				width: 450px;
				float: right;
			}

			&-text {
				font-size: 16px;
			}

			&-title {
				color: #000;
				font-size: 36px;
				font-weight: 400;
				line-height: 36px;
				margin-bottom: 30px;
			}

			&-photo {
				width: 351px;
				height: 342px;
				background-position: center center;
				background-repeat: no-repeat;
				.background-size(cover);
			}
		}

		&__middle {
			float: right;
			width: ~'calc(50% - 230px)';

			&-photo {
				background-position: center center;
				background-repeat: no-repeat;
				.background-size(cover);
				height: ~'calc(870px - 294px)';
			}

			&-desc {
				height: 294px;
				background-color: @color3;
			}

			&-text {
				color: #fff;
				font-size: 16px;
				font-weight: 300;
				line-height: 24px;
				padding: 37px;
				width: 370px;
			}
		}

		&__bottom {
			float: left;
			width: ~'calc(50% + 230px)';
			position: relative;

			&-photo {
				background-position: center center;
				background-repeat: no-repeat;
				.background-size(cover);
				height: ~'calc(870px - 342px)';
			}

			&-desc {
				position: absolute;
				right: 0;
				top: 0;
				width: 800px;
				height: 100%;
				z-index: 1;
				text-align: center;

				&:after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					height: 100%;
				}
			}

			&-text {
				display: inline-block;
				vertical-align: middle;
				color: #fff;
				font-size: 36px;
				font-weight: 300;
				line-height: 40px;
				text-align: center;
				width: ~'calc(100% - 200px)';
				text-transform: uppercase;
			}
		}
	});

	.sm({
		margin: 0 -30px;

		&__inner {

		}

		&__top {
			.display(flex);
			.justify-content(space-between);
			.align-items(center);

			&-desc {
				padding: 0 30px 0 30px;
				width: ~'calc(50% + 34px)';
			}

			&-text {
				font-size: 16px;
			}

			&-title {
				color: #000;
				font-size: 36px;
				font-weight: 400;
				line-height: 36px;
				margin-bottom: 30px;
			}

			&-photo {
				width: ~'calc(50% - 34px)';
				height: 342px;
				background-position: center center;
				background-repeat: no-repeat;
				.background-size(cover);
			}
		}

		&__middle {
			.display(flex);
			.justify-content(space-between);
			.align-items(stretch);
			.flex-direction(row-reverse);

			&-photo {
				background-position: center center;
				background-repeat: no-repeat;
				.background-size(cover);
				height: 298px;
				width: 50%;
			}

			&-desc {
				width: 50%;
				background-color: @color3;
				.display(flex);
				.justify-content(center);
				.align-items(center);
			}

			&-text {
				color: #fff;
				font-size: 16px;
				font-weight: 300;
				line-height: 24px;
				padding: 30px;
			}
		}

		&__bottom {
			position: relative;

			&-photo {
				background-position: center center;
				background-repeat: no-repeat;
				.background-size(cover);
				height: 290px;
			}

			&-desc {
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				text-align: center;

				&:after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					height: 100%;
				}
			}

			&-text {
				display: inline-block;
				vertical-align: middle;
				color: #fff;
				font-size: 28px;
				font-weight: 300;
				line-height: 35px;
				text-align: center;
				width: ~'calc(100% - 160px)';
			}
		}
	});

	.xs({
		margin: 0 -20px 25px;

		&__inner {

		}

		&__top {

			&-desc {
				padding: 0 20px;
			}

			&-text {
				font-size: 14px;
				line-height: 22px;
				margin-bottom: 20px;
			}

			&-title {
				color: #000;
				font-size: 36px;
				font-weight: 400;
				line-height: 36px;
				margin-bottom: 20px;
			}

			&-photo {
				background-position: center center;
				background-repeat: no-repeat;
				.background-size(cover);
				height: 340px;
			}
		}

		&__middle {
			background-color: @color3;
			overflow: hidden;

			&-photo {
				position: relative;
				height: 300px;
				background-position: center center;
				background-repeat: no-repeat;
				.background-size(cover);
			}

			&-desc {

			}

			&-text {
				color: #fff;
				font-size: 14px;
				font-weight: 300;
				line-height: 24px;
				padding: 20px;
			}
		}

		&__bottom {
			position: relative;

			&-photo {
				background-position: center center;
				background-repeat: no-repeat;
				.background-size(cover);
				height: 300px;
			}

			&-desc {
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				text-align: center;

				&:after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					height: 100%;
				}
			}

			&-text {
				display: inline-block;
				vertical-align: middle;
				color: #fff;
				font-size: 16px;
				font-weight: 300;
				line-height: 24px;
				text-align: center;
				width: ~'calc(100% - 40px)';
			}
		}
	});
}
