.product-gallery {
	max-width: 1200px;
	margin: 0 auto 40px;


	.catalog-thumb {
		margin-bottom: 0;

		&__name{
			text-align: center;
		}

		&__footer{
			justify-content: center;
		}
	}

	.sm({

	});

	.xs({
		margin-bottom: 40px;
	});

	&_tabs {

		.lg({
			padding: 30px 0;

		    margin-bottom: 0;
		});

		.sm({
		    margin-bottom: 45px;
		});

		.xs({
		    margin-bottom: 34;
		});
	}

	&__title {
		color: #000;
		font-size: 30px;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 40px;

		.xs({
			font-size: 26px;
			margin-bottom: 20px;
		});
	}
}
