.text-default {
	font-size: 15px;
	line-height: 24px;
	max-width: 1200px;
	margin: 0 auto 40px;

	.sm({

		& {
			margin: 0 0 30px;
		}
	});

	.xs({

		& {
			margin: 0 0 25px;
		}
	});

	&__title,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #000;
		font-size: 30px;
		font-weight: 400;
		line-height: 30px;
		margin-bottom: 45px;
		.display(flex);
		.justify-content(flex-start);
		.align-items(center);

		> img {
			max-width: 100%;
			margin: 0 15px 0 0;
			display: inline-block;
			vertical-align: middle;
		}

		> span {
			display: inline-block;
			vertical-align: middle;

			img {
				display: inline-block;
				vertical-align: middle;
			}
		}

		.xs({
			text-align: center;
			font-size: 20px;
			line-height: 25px;
			margin-bottom: 25px;
			display: block;

			> img {
				float: none;
				margin: 0 auto 20px;
				display: block;
				max-width: 75px;
				max-height: 75px;
				position: static;
			}
		});
	}

	&__color {
		color: @color2;
	}

	&__image{
		display: inline-block;
		float: right;
		margin-left: 20px;
		margin-bottom: 20px;

		&_left{
			float: left;
			margin-left: 0;
			margin-right:20px;
		}
		.sm({
			float: none; margin-left: 0; margin-right: 0;
		});
		.xs({
			float: none;margin-left: 0; margin-right: 0;
		});
	}

	table {
		border: 1px solid @color3;
		border-collapse: separate;
		border-spacing: 2px;
		width: 100%;

		tr {

			td {
				padding: 5px 15px;
				border: 1px solid @color3;
			}
		}
	}

	ul {
		margin: 0 0 40px;
		list-style: none;

		li {
			margin-bottom: 15px;
			padding: 0 0 0 20px;
			position: relative;

			&:before {
				.size(7px);
				background-color: @color3;
				border-radius: 50%;
				left: 0;
				position: absolute;
				left: 0;
				top: 8px;
				content: '';
			}
		}
	}
}