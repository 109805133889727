.catalog-seo-block {

	.lg({
		width: 1200px;
		margin: 0 auto 90px;

		&__row {
			.clearfix();
		}

		&__desc {
			width: 100% - (611 / 1140 * 100%);
			float: right;
			padding: 15px 0 0 0;
		}

		&__title {
			color: #000;
			font-size: 36px;
			font-weight: 500;
			margin-bottom: 30px;
			padding: 0 0 0 40px;
			line-height: 36px;
		}

		&__text {
			color: #000;
			background-color: #e5e5e5;
			.border-radius(5px);
			padding: 35px 40px 35px 40px;
			position: relative;
			z-index: 1;
			margin: 0 0 0 -60px;

			p {
				margin-bottom: 15px;
			}
		}

		&__photo {
			float: left;
			width: 611 / 1140 * 100%;
		}

		&__pic {
			display: block;
			width: 100%;
		}
	});

	.sm({
		margin: 0 -30px 30px;

		&__row {
			.clearfix();
		}

		&__desc {
			width: 100% - (330 / 770 * 100%);
			float: right;
			padding: 0;
		}

		&__title {
			color: #000;
			font-size: 36px;
			font-weight: 400;
			margin-bottom: 30px;
			padding: 0 0 0 20px;
			line-height: 36px;
		}

		&__text {
			color: #000;
			background-color: #e5e5e5;
			.border-radius(5px);
			padding: 40px;
			position: relative;
			z-index: 1;
			margin: 0 0 0 -30px;
		}

		&__photo {
			float: left;
			width: 330 / 770 * 100%;
		}

		&__pic {
			display: block;
			width: 100%;
		}
	});

	.xs({
		margin-bottom: 35px;

		&__row {
		}

		&__desc {
		}

		&__title {
			color: #000;
			font-size: 26px;
			margin-bottom: 15px;
			padding: 0 0 0 20px;
		}

		&__text {
			color: #000;
			background-color: #e5e5e5;
			padding: 20px;
			position: relative;
			z-index: 1;
			font-size: 14px;
			line-height: 22px;
		}

		&__photo {
		}

		&__pic {
			display: block;
			width: 100%;
		}
	});
}
