.basket-radio {
	.display(flex);
	.justify-content(space-between);

	.xs({
		.flex-wrap(wrap);
	});

	&__label {
		position: relative;
		padding: 0 0 0 34px;

		.xs({
			margin-bottom: 25px;
		});
	}

	&__input {
		visibility: hidden;
		left: 0;
		top: 0;
		position: absolute;
	}

	&__icon {
		.size(20px);
		background-color: #fff;
		border: 1px solid #cacaca;
		border-radius: 50%;
		position: absolute;
		left: 0;
		top: 2px;
	}

	&__input:checked ~ &__icon {
		border: 1px solid @color3;
		background: @color3;
		.box-shadow(0 0 0 4px #fff inset);
	}

	&__text {
		text-align: left;
	}
}
