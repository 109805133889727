.wrapper {
	position: relative;
	overflow: hidden;
	min-height: 680px;
	min-width: 300px;
}

.header {

	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		border-bottom: 1px solid #eaeaea;
	}

	&__inner {
		margin: 0 auto;
		max-width: 1200px;
		position: relative;

		.xs({
			height: 124px;
		});
	}

	&__line {
		border-bottom: 1px solid #eaeaea;
	}

}

.middle {
	.clearfix();

	&__container {
		float: left;
		width: 100%;
	}

}

.content {
	padding: 0;
	line-height: @line-height;
	.clearfix();
	@left: 250 / 1200 * 100%;
	@right: 100 - @left;

	&-fluid {
		max-width: 1200px;
		margin: 0 auto;
		position: relative;
	}

	.lg({

		& {
			padding: 40px 0 0;
		}
	});

	.sm({

		& {
			padding: 35px 30px 0;
		}
	});

	.xs({

		& {
			padding: 25px 20px 0;
		}
	});

	&__left {
		width: @left;
		float: left;

		.sm({
			width: 100%;
			float: none;
		});

		.xs({
			width: 100%;
			float: none;
		});
	}

	&__right {
		width: @right;
		float: right;
		padding: 0 0 0 40px;

		.sm({
			width: 100%;
			float: none;
			padding: 0;
		});

		.xs({
			width: 100%;
			float: none;
			padding: 0;
		});
	}
}

.footer {
	border-top: 3px solid @color3;
	min-width: 320px;
	position: relative;
	overflow: hidden;

	&__inner {
		max-width: 1200px;
		margin: 0 auto;
	}

	&__row {
		.clearfix();
	}

	.lg({

		@w1: 944 / 1200 * 100%;
		@w2: 110 - @w1;

		&__row {
			display: table;
			width: 100%;
		}

		&__left {
			display: table-cell;
			vertical-align: top;
			width: @w1;
			padding: 35px 48px 0 0;
		}

		&__right {
			position: relative;
			display: table-cell;
			vertical-align: top;
			width: @w2;
			padding: 35px 0 0 45px;
		}

	});

	.sm({
		padding: 0 0 70px;

		@w1: 590 / 768 * 100%;
		@w2: 120 - @w1;

		&__row {
			display: table;
			width: 100%;
		}

		&__left {
			display: table-cell;
			vertical-align: top;
			width: @w1;
			padding: 28px 0 0 10px;
		}

		&__right {
			position: relative;
			display: table-cell;
			vertical-align: top;
			width: @w2;
			padding: 28px 10px 0 10px;
		}

	});

	.xs({
		padding: 0 0 50px;

		&__left {
			padding: 28px 20px 0 20px;
		}

		&__right {
			padding: 20px 20px 1px;
			position: relative;
		}

	});

}
