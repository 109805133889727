// main: style.less

.detail {
	max-width: 1200px;
	margin: 0 auto 100px;

	.sm({
	    margin-bottom: 60px;
	});

	.xs({
	    margin-bottom: 35px;
	});

	&__title {
		margin-bottom: 35px;
		color: #000;
		font-size: 28px;
		font-weight: 400;
		text-transform: uppercase;
		text-align: center;

		.xs({
		    font-size: 24px;
			margin-bottom: 30px;
		});
	}

	&__frow {
		display: flex;
		flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 15px;

        @media (min-width: 768px) and (max-width: 1000px) {
            flex-direction: column;
        }

        @media (max-width: 520px) {
            flex-direction: column;
        }
    }	

	&__articul {
		color: #666;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 15px;
	}

	&__desc {
		background-color: #e7e7e7;
		padding: 25px 35px;

		.xs({
		    margin: 0 -20px;
			padding: 25px 20px;
		});
	}

	&__top {
		border-bottom: 1px solid #c8c4bb;
		padding-bottom: 20px;
		margin-bottom: 30px;
		.display(flex);
		.justify-content(space-between);
		flex-wrap: wrap;

		@media (max-width: 1080px) {

		}
	}

	&__middle {
		border-bottom: 1px solid #c8c4bb;
		margin-bottom: 20px;
		position: relative;

		.sm({
		    padding: 0 10px;
		});

		.xs({
		    padding: 0 10px;
		});
	}

	&__table {
		margin-bottom: 30px;
		display: table;
		width: 100%;
		position: relative;

		.sm({
		    display: block;
			margin-bottom: 20px;
		});

		.xs({
		    display: block;
			margin-bottom: 20px;
		});
	}

	&__col {
		display: table-cell;
		width: 50%;

		@media (min-width: @screen-lg-min) {
			vertical-align: top;
		}

		.sm({
		    display: block;
			width: 100%;
		});

		.xs({
		    display: block;
			width: 100%;
		});
	}

	&__allsumm {
		margin-bottom: 35px;

		@media (min-width: @screen-lg-min) {
			min-height: 50px;
		}

		.sm({
		    margin-bottom: 20px;
		});

		.xs({
		    margin-bottom: 20px;
		});
	}

	&__summ {
		text-align: center;
		display: inline-block;
		vertical-align: middle;
	}

	&__price {
		color: #000;
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 5px;

		&_color {
			color: #ff281b;
		}
	}

	&__oldprice {
		color: #666;
		font-size: 15px;
		font-weight: 500;
		text-decoration: line-through;
	}

	&__acrualpriceinfo {
		position: absolute;
		left: 0;
		top: 65px;
		color: #ff0000;
		font-size: 13px;
		line-height: 15px;

		.sm({
			margin: -10px 0 10px;
			position: static;
		});

		.xs({
			margin: 0 0 10px;
			position: static;
		});
	}

	&__action {
		text-align: left;
		margin-left: 10px;
		color: rgb(255, 255, 255);
		font-size: 15px;
		font-weight: 500;
		line-height: 35px;
		padding: 0 0 0 18px;
		.size(60px, 35px);
		display: inline-block;
		vertical-align: middle;
	}

	&__buy {
		.size(100%, 50px);
		text-align: center;
		.border-radius(5px);
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: none;
		display: block;
		margin-bottom: 35px;
		.bgButton();
		.vm();

		.sm({
		    width: 220px;
			margin-bottom: 20px;
		});

		.xs({
		    width: 220px;
			margin-bottom: 20px;
		});
	}

	&__find {
		color: @color2;
		font-size: 14px;
		font-weight: 700;
		text-decoration: none;
		border-bottom: 1px dashed;
		position: relative;


		&:hover {
			border-color: transparent;
		}
	}

	&__oneclick {
		color: #333;
		font-size: 14px;
		font-weight: 400;
		border-bottom: 1px dashed;
		text-decoration: none;

		&:hover {
			color: @color3;
		}
	}

	&__info {
		color: #000;
		font-size: 14px;
		font-weight: 400;

		.sm({
		    margin-bottom: 25px;
		});

		.xs({
		    margin-bottom: 25px;
		});
	}

	&__bottom {
		.display(flex);
		.justify-content(space-between);

		.sm({
		    display: block;
			padding: 0 10px;
		});

		.xs({
		    display: block;
			padding: 0 10px;
		});
	}

	&__delivery {
		color: #000;
		font-size: 15px;
		font-weight: 500;
		padding: 4px 0 0 45px;
		min-height: 27px;
		background: url(../images/delivery.svg) no-repeat left center;
	}

	&__sep {
		.size(1px, 32px);
		.opacity(0.18);
		background-color: #000;
		display: block;

		.sm({
		    margin-bottom: 20px;
			background: none;
			height: 1px;
			width: 100%;
		});

		.xs({
		    margin-bottom: 20px;
			background: none;
			height: 1px;
			width: 100%;
		});
	}

	&__install {
		color: #000;
		font-size: 15px;
		font-weight: 500;
		padding: 4px 0 0 45px;
		min-height: 27px;
		background: url(../images/install.svg) no-repeat left center;
	}
}
