// main: style.less

.popup {
	position: fixed;
	overflow-x: hidden;
	overflow-y: scroll;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1200;
	-webkit-overflow-scrolling: touch;
	-moz-overflow-scrolling: touch;
	-o-overflow-scrolling: touch;
	-ms-overflow-scrolling: touch;
	overflow-scrolling: touch;
	opacity: 0;
	pointer-events: none;
	background: rgba(24, 17, 34, 0.7);
	transition: opacity 500ms;

	&_iphone,
	&_iphone body {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
	}

	&_ie10,
	&_ie9 {
		display: none;
	}

	&_dopclass {
		position: absolute;
		overflow: visible;
	}

	&_active {
		opacity: 1;
		pointer-events: auto;
	}

	&_ie10&_active,
	&_ie9&_active {
		display: block;
	}

	&__overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&__table {
		display: table;
		width: 100%;
		height: 100%;
		padding: 40px 30px;
	}

	&__cell {
		height: 100%;
		height: 100%;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}

	&__block {
		display: inline-block;
		text-align: left;
		position: relative;
		background: #fff;
		box-shadow: 1px 1px 5px 2px #555;
	}

	&_dopclass &__block {
		background: #ccc;
		box-shadow: none;

		&:before {
			position: absolute;
			width: 20px;
			height: 20px;
			background: #ccc;
			transform: rotate(45deg);
			left: -10px;
			top: 21px;
			content: '';
		}
	}

	&__close {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		width: 30px;
		height: 30px;
		cursor: pointer;
		z-index: 2;

		&:before,
		&:after {
			position: absolute;
			left: 50%;
			margin: -1px 0 0 -8px;
			top: 50%;
			height: 2px;
			width: 16px;
			background: #000;
			content: '';
			transform-origin: 50% 50%;
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}
	}

	&_active &__close {
		display: block;
	}

	&__change {

		& > div {
			display: block;
		}
	}

	&__body_hidden {
		overflow: hidden;
		-webkit-overflow-scrolling: auto;
		-moz-overflow-scrolling: auto;
		-o-overflow-scrolling: auto;
		-ms-overflow-scrolling: auto;
		overflow-scrolling: auto;
	}
}

	// <!-- begin popup -->
	// <div class="popup" id="js-popup">

	// 	<div class="popup__inner" id="js-popup__inner">
	// 		<div class="popup__close" id="js-popup__close"></div>
	// 		<div class="popup__section" id="js-popup__section">
	// 		</div>
	// 	</div>
	// </div>

// ;(function($) {

// 	$.fn.popup = function(opts) {

// 		opts = $.extend({
// 			element: null,
// 			center: false,				// С†РµРЅС‚СЂРёСЂРѕРІР°РЅРёРµ
// 			removeAll: false,			// РµСЃР»Рё РЅР°РґРѕ СѓРґР°Р»РёС‚СЊ РІРµСЃСЊ РїРѕРїР°Рї СЃ СЃРѕРґРµСЂР¶РёРјС‹Рј
// 			nameClass: "",				// РјРѕР¶РЅРѕ РґРѕР±Р°РІРёС‚СЊ РєР»Р°СЃСЃ РіР»Р°РІРЅРѕРјСѓ РєРѕРЅС‚РµР№РЅРµСЂСѓ
// 			clickFn: function() {},		// РѕР±СЂР°С‚РЅР°СЏ С„СѓРЅРєС†РёСЏ РґРѕ РІС‹Р·РѕРІР° РїРѕРїР°Рї
// 			closeFn: function() {}		// РѕР±СЂР°С‚РЅР°СЏ С„СѓРЅРєС†РёСЏ РїРѕ Р·Р°РєСЂС‹С‚РёСЋ РїРѕРїР°Рї
// 		}, opts);

// 		if(!opts.element.length) {
// 			return false;
// 		}

// 		var scrollWidthElement = function() {

// 			var div = document.createElement("div");
// 			div.style.overflowY = "scroll";
// 			div.style.width = "50px";
// 			div.style.height = "50px";
// 			div.style.visibility = "hidden";

// 			document.body.appendChild(div);
// 			var scrollWidth = div.offsetWidth - div.clientWidth;
// 			document.body.removeChild(div);

// 			return scrollWidth;

// 		}

// 		opts.scrollWidth = scrollWidthElement();

// 		opts.popup = $("<div class='popup' id='js-popup'></div>");
// 		opts.popup__inner = $("<div class='popup__inner' id='js-popup__inner'></div>").appendTo($(opts.popup));
// 		opts.popup__close = $("<div class='popup__close' id='js-popup__close'></div>").appendTo($(opts.popup__inner));
// 		opts.popup__section = $("<div class='popup__section' id='js-popup__section'></div>").appendTo($(opts.popup__inner));

// 		if(opts.nameClass) {

// 			$(opts.popup).addClass(opts.nameClass);

// 		}

// 		$(opts.element).before($(opts.popup));
// 		$(opts.popup__section).append($(opts.element));

// 		$(document.body).addClass("popup__body_hidden");
// 		document.body.style.paddingRight = opts.scrollWidth + "px";

// 		opts.widthElement = opts.element[0].offsetWidth;
// 		opts.heightElement = opts.element[0].offsetHeight;

// 		$(opts.popup__inner).css({
// 			width: opts.widthElement + "px",
// 			height: opts.heightElement + "px"
// 		});

// 		if(opts.center) {

// 			opts.marginTop = -opts.heightElement / 2;

// 			$(opts.popup__inner).css({
// 				marginLeft: "auto",
// 				marginRight: "auto",
// 				top: "50%",
// 				marginTop: opts.marginTop + "px",
// 				height: opts.heightElement + "px"
// 			});

// 		}

// 		opts.clickFn(opts);

// 		$(opts.popup).addClass("popup_active");

// 		opts.clickClose = function() {

// 			$(opts.popup).removeClass("popup_active");

// 			setTimeout(function() {

// 				$(document.body).removeClass("popup__body_hidden");
// 				document.body.style.paddingRight = "";

// 				$(opts.popup).before($(opts.element));
// 				$(opts.popup).remove();

// 				if(opts.removeAll) {

// 					$(opts.element).remove();

// 				}

// 				opts.closeFn(opts);

// 			}, 1000);

// 		}

// 		$(opts.popup__close).click(function() {

// 			opts.clickClose();
// 			return false;

// 		});

// 		$(opts.popup).click(function() {

// 			opts.clickClose();
// 			return false;

// 		});

// 		$(opts.popup__inner).click(function(e) {

// 			e.stopPropagation();

// 		});

// 	}

// })(jQuery);

// })(jQuery);

	// $.fn.popup({
	// 	element: $("#js-popup-basket")
	// });