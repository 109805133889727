.nav {
	max-width: 1200px;
	margin: 0 auto 40px;

	&_catalog {
		margin-bottom: 80px;
		padding-top: 5px;

		.xs({
		    margin-bottom: 30px;
		});
	}

	&__list {
		.display(flex);
		.justify-content(center);
		.align-items(center);
	}

	&_catalog &__list {
		.justify-content(flex-start);

		.xs({
		    .justify-content(center);
		});
	}

	&__arrow {
		color: @color;
		font-size: 14px;
		font-weight: 700;
		padding: 0 25px;
		text-decoration: none;

		.xs({

			& {
				text-indent: -9999px;
				padding: 0 10px;
			}
		});
	}

	&__arrow_prev {
		background: url(../images/nav__arrow_prev.png) no-repeat left center;
	}

	&__item {
		margin: 0 7px;
		.size(28px);
		background-color: #e8e8e8;
		color: #333;
		font-size: 14px;
		font-weight: 400;
		line-height: 29px;
		text-align: center;
		text-decoration: none;

		.xs({

			& {
				margin: 0 3px;
			}
		});
	}

	&__item_state_current {
		background-color: @color3;
		color: #fff;
	}

	&__more {
		margin: 0 7px;
		.size(28px);
		color: #333;
		font-size: 14px;
		font-weight: 400;
		line-height: 29px;
		text-align: center;
		text-decoration: none;

		.xs({

			& {
				margin: 0 3px;
			}
		});
	}

	&__arrow_next {
		background: url(../images/nav__arrow_next.png) no-repeat right center;
	}
}
