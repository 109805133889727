.glossary {

	&__title{
		text-transform: capitalize;
		font-size: 30px;
	}

	&-content {
		margin: 0 -5px;
	}
}

.glossary-header{
	&__pic img{
		display: block;
		width: 100%;
	}
	&__nav-list{
		flex-wrap: wrap;
		display: flex;
		justify-content: space-between;
		list-style: none;
		padding: 15px 15px;
		background: #00869d;
		margin: 0;
		color: #ffffff;

		.xs({

		});
	}
	&__nav-item{
		padding: 6px 10px;
		&:hover{
			background: #339eb1;
		}
	}

}
.glossary-content__list{
	list-style-type:none;
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 70px;
	margin-bottom: 60px;
	.display(flex);
	.justify-content(space-between);
	.align-items(stretch);
	.flex-wrap(wrap);
}
.glossary-content__item{
	padding-top: 50px;
	width: 50%;
	padding: 50px 5px 0;

	&-top {
		display: table;
		width: 100%;
		height: 50px;
		min-height: 50px;
		position: relative;
		margin-bottom: 10px;
	}

	.xs({
		float: none;
		width: 100%;
	});

}
.item__icon,
.item__title{
	display: table-cell;
	vertical-align: middle;
}
.item__icon{
	height: 50px;
	width: 70px;
	position: relative;

	img {
		display: block;
		margin: 0 auto;
		max-width: 50px;
		max-height: 50px;
	}

	&:after{
		content: '';
		position: absolute;
		display: block;
		width: 4px;
		height: 30px;
		background: @color3;
		left: 50%;
		margin-left: -2px;
		top: 52px;
	}
}
.item__title{
	font-weight: 500;
	font-style: italic;
	font-size: 15px;
	h3{
		margin: 0;
		text-transform: uppercase;
	}

}
.glossary-content__item-bottom{
	background: #f9f9f9;
	display: flex;
	justify-content: flex-start;
	padding: 30px 20px 30px 10px;
	border-radius: 10px;
	min-height: 200px;
	.sm({
		padding-right: 10px;
	});
}


.glossary-content__text{
	padding-left: 20px;
}
@media screen and (max-width: 981px) {
	.glossary-header__nav-list{
		justify-content: flex-start;
	}
	.glossary-content__item-bottom{
		flex-wrap: wrap;
	}
}