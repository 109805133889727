.about-info-four {
	position: relative;
	height: 432px;
	background-image: url(../images/about-info__pic4.jpg);
	background-repeat: no-repeat;
	background-position: center;
	.background-size(cover);
	.display(flex);
	.justify-content(center);
	.align-items(center);

	.sm({
		margin: 0 -30px;
	});

	.xs({
		height: auto;
		padding: 20px 0;
		margin: 0 -20px;
	});

	&__title{
		color: #fff;
		font-size: 36px;
		font-weight: 400;
		margin-bottom: 30px;
		padding: 0 0 0 40px;

		.xs({
		    font-size: 26px;
		    margin-bottom: 15px;
		    padding: 0;
		});

	}
	&__text {
		position: relative;
		color: #fff;
		font-size:  15px;
		font-weight: 300;
		text-align: center;
		max-width: 1080px;
		z-index: 2;

		.sm({
			width: 710px;
			font-size: 14px;
		});

		.xs({
			font-size: 14px;
			line-height: 22px;
			padding: 0 20px;
		});
	}


}
