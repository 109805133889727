.news-side {
	margin-bottom: 50px;

	.sm({
		display: none;
	});

	.xs({
		display: none;
	});

	&__title {
		padding: 0 0 0 30px;
		color: #000;
		font-size: 14px;
		line-height: 25px;
		font-weight: bold;
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	&__section {
	}

	&__item {
		background: rgb(242, 242, 242);
		margin-bottom: 12px;
	}

	&__photo {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 30px;
			bottom: -12px;
			width: 2px;
			height: 100px;
			background: #00859b;
		}
	}

	&__pic {
		display: block;
		width: 100%;
	}

	&__content {
		color: #000;
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
		padding: 25px 30px;
	}

	&__name {
		display: inline-block;
		margin-bottom: 10px;
		color: rgb(0, 133, 155);
		text-transform: uppercase;
		text-decoration: underline;
	}

	&__text {

	}

	&__more {
		margin: 0 30px;
		display: block;
		text-decoration: none;
		height: 43px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		background: rgb(0, 133, 155);
		border-radius: 5px;
		text-transform: uppercase;
		color: #fff;
		font-size: 13px;
		font-weight: 500;
	}
}
