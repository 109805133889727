.menu-vert {

	.lg({
		padding: 10px 0;
		background-color: @color3;
		margin-bottom: 13px;

		&__list {
			margin: 0;
			padding: 0;
		}

		&__item {
			margin-bottom: 1px;
		}

		&__link {
			color: #fff;
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			display: block;
			text-decoration: none;
			line-height: 18px;
			padding: 10px 30px 10px 58px;
			.transition(background 500ms);
			position: relative;
			margin: 0;
			&:before {
				.size(8px);
				background-color: rgb(255, 255, 255);
				left: 26px;
				top: 50%;
				margin-top: -4px;
				content: '';
				position: absolute;
				.transform(rotate(0deg));
				.transition(transform 2500ms, background 2500ms);
				.transform-origin(50% 50%);
			}

		}

		&__item.active &__link,
		&__link:hover {
			background-color: @link_color;

			&:before {
				background: #015294;
				.transform(rotate(765deg));
			}

		}
	});

	.sm({
		position: relative;
		margin-bottom: 35px;

		&__button {
			.size(48%, 53px);
			background-color: @color3;
			padding: 0 0 0 54px;
			color: #fff;
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			line-height: 53px;
			cursor: pointer;
			position: relative;

			&:before {
				.size(12px, 21px);
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAVCAMAAABFcv+GAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA21BMVEX////o6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Oj///9aCzgqAAAASHRSTlMARmd2kpN/R1tigyUgKih7So54c3J5kUUmmcrMpqRcOjEyWqJNYzM/QD5XgTY9OznCw08FbRfHUDBuA4ejHnUcGhMSeqq9xruyGPEZAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAKNJREFUGNN9z8cSgjAUheEr2ClWNBI1AoqIgL0XsOH7v5EhOHHnvziTb3NnAgAZQczm8kIBkopsocS2LMmKIqsVhmqt3mhqrTZ9oo6OWXoXQa9PBohmENMCE3jDPxghwx47zsQ2kAtTz/cIIYHnzzSYY94Clj+4sFrb30R2A0AK6JgpNlu849hjfOA4ns4XjrQEVyWMbvcoUh8Uz1ec/CZ+W/ABxecUhYIzw9IAAAAASUVORK5CYII=') no-repeat;
				content: '';
				position: absolute;
				left: 20px;
				top: 50%;
				margin-top: -10px;
			}

			&:after {
				content: '';
				position: absolute;
				right: 20px;
				.size(12px, 7px);
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAMAAAALbFwWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAUVBMVEX///////////////////////////////////////////////////////////////////////////////////////////////////////////94FlRsAAAAGnRSTlMAMroTu7TQFc8MwdUYwgq82BsaCbfbB7MGp0V2Gv0AAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAARklEQVQI1y2LwRaAEAAEV5JEKIT9/w/tUXOawwzEIjGRq4DipofrnQrmoHWAszwN4APjdUcGP4KUWQpz+tankrXhp/U+/QWAfgNDN5VwGAAAAABJRU5ErkJggg==') no-repeat;
				margin-top: -3.5px;
				top: 50%;
				.transition(transform 500ms);
			}
		}

		&.active &__button {

			&:after {
				.transform(rotate(180deg));
			}
		}

		&__list {
			margin: 0;
			padding: 0;
			position: absolute;
			z-index: 10;
			top: 53px;
			left: 0;
			right: 0;
			background-color: @color3;
			pointer-events: none;
			opacity: 0;
			.transition(opacity 500ms);
		}

		&.active &__list {
			opacity: 1;
			pointer-events: auto;
		}

		&__item {
			margin-bottom: 1px;
		}

		&__link {
			color: #fff;
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			display: block;
			text-decoration: none;
			height: 53px;
			line-height: 53px;
			padding: 0 0 0 57px;
			.transition(background 500ms);
			position: relative;

			&:before {
				.size(12px, 21px);
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAVCAMAAABFcv+GAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA21BMVEX////o6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Oj///9aCzgqAAAASHRSTlMARmd2kpN/R1tigyUgKih7So54c3J5kUUmmcrMpqRcOjEyWqJNYzM/QD5XgTY9OznCw08FbRfHUDBuA4ejHnUcGhMSeqq9xruyGPEZAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAKNJREFUGNN9z8cSgjAUheEr2ClWNBI1AoqIgL0XsOH7v5EhOHHnvziTb3NnAgAZQczm8kIBkopsocS2LMmKIqsVhmqt3mhqrTZ9oo6OWXoXQa9PBohmENMCE3jDPxghwx47zsQ2kAtTz/cIIYHnzzSYY94Clj+4sFrb30R2A0AK6JgpNlu849hjfOA4ns4XjrQEVyWMbvcoUh8Uz1ec/CZ+W/ABxecUhYIzw9IAAAAASUVORK5CYII=') no-repeat;
				position: absolute;
				left: 20px;
				top: 50%;
				margin-top: -10px;
				content: '';
			}
		}

		&__item.active &__link,
		&__link:hover {
			background-color: #0068bd;
		}
	});

	.xs({
		position: relative;
		margin-bottom: 17px;

		&__button {
			.size(100%, 53px);
			background-color: @color3;
			padding: 0 0 0 54px;
			color: #fff;
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			line-height: 53px;
			cursor: pointer;
			position: relative;

			&:before {
				.size(12px, 21px);
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAVCAMAAABFcv+GAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA21BMVEX////o6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Oj///9aCzgqAAAASHRSTlMARmd2kpN/R1tigyUgKih7So54c3J5kUUmmcrMpqRcOjEyWqJNYzM/QD5XgTY9OznCw08FbRfHUDBuA4ejHnUcGhMSeqq9xruyGPEZAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAKNJREFUGNN9z8cSgjAUheEr2ClWNBI1AoqIgL0XsOH7v5EhOHHnvziTb3NnAgAZQczm8kIBkopsocS2LMmKIqsVhmqt3mhqrTZ9oo6OWXoXQa9PBohmENMCE3jDPxghwx47zsQ2kAtTz/cIIYHnzzSYY94Clj+4sFrb30R2A0AK6JgpNlu849hjfOA4ns4XjrQEVyWMbvcoUh8Uz1ec/CZ+W/ABxecUhYIzw9IAAAAASUVORK5CYII=') no-repeat;
				content: '';
				position: absolute;
				left: 20px;
				top: 50%;
				margin-top: -10px;
			}

			&:after {
				content: '';
				position: absolute;
				right: 20px;
				.size(12px, 7px);
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAMAAAALbFwWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAUVBMVEX///////////////////////////////////////////////////////////////////////////////////////////////////////////94FlRsAAAAGnRSTlMAMroTu7TQFc8MwdUYwgq82BsaCbfbB7MGp0V2Gv0AAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAARklEQVQI1y2LwRaAEAAEV5JEKIT9/w/tUXOawwzEIjGRq4DipofrnQrmoHWAszwN4APjdUcGP4KUWQpz+tankrXhp/U+/QWAfgNDN5VwGAAAAABJRU5ErkJggg==') no-repeat;
				margin-top: -3.5px;
				top: 50%;
				.transition(transform 500ms);
			}
		}

		&.active &__button {

			&:after {
				.transform(rotate(180deg));
			}
		}

		&__list {
			margin: 0;
			padding: 0;
			position: absolute;
			z-index: 10;
			top: 53px;
			left: 0;
			right: 0;
			background-color: @color3;
			pointer-events: none;
			opacity: 0;
			.transition(opacity 500ms);
		}

		&.active &__list {
			opacity: 1;
			pointer-events: auto;
		}

		&__item {
			margin-bottom: 1px;
		}

		&__link {
			color: #fff;
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			display: block;
			text-decoration: none;
			height: 53px;
			line-height: 53px;
			padding: 0 0 0 57px;
			.transition(background 500ms);
			position: relative;

			&:before {
				.size(12px, 21px);
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAVCAMAAABFcv+GAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA21BMVEX////o6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Oj///9aCzgqAAAASHRSTlMARmd2kpN/R1tigyUgKih7So54c3J5kUUmmcrMpqRcOjEyWqJNYzM/QD5XgTY9OznCw08FbRfHUDBuA4ejHnUcGhMSeqq9xruyGPEZAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAKNJREFUGNN9z8cSgjAUheEr2ClWNBI1AoqIgL0XsOH7v5EhOHHnvziTb3NnAgAZQczm8kIBkopsocS2LMmKIqsVhmqt3mhqrTZ9oo6OWXoXQa9PBohmENMCE3jDPxghwx47zsQ2kAtTz/cIIYHnzzSYY94Clj+4sFrb30R2A0AK6JgpNlu849hjfOA4ns4XjrQEVyWMbvcoUh8Uz1ec/CZ+W/ABxecUhYIzw9IAAAAASUVORK5CYII=') no-repeat;
				position: absolute;
				left: 20px;
				top: 50%;
				margin-top: -10px;
				content: '';
			}
		}

		&__item.active &__link,
		&__link:hover {
			background-color: #0068bd;
		}
	});
}
