.baron {

	.lg({
		height: 537px;
	});

	&__clipper {
		position: relative;
		overflow: hidden;
	}

	&__scroller {
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		margin: 0;
		border: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		-webkit-overflow-scrolling: touch;
		/* remove line to customize scrollbar in iOs */

		.sm({
			max-width: 100% !important;
			width: 100% !important;
			min-width: 100% !important;
		});

		.xs({
			max-width: 100% !important;
			width: 100% !important;
			min-width: 100% !important;
		});
	}

	&__scroller::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	&__track {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
	}

	&._scrollbar &__track {
		display: block;
	}

	&__free {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 35px;
		background-color: #dfddd9;
		width: 10px;
	}

	&__bar {
		display: none;
		position: absolute;
		right: 0;
		z-index: 1;
		width: 10px;
		background: #beb8ab;;
	}

	&._scrollbar &__bar {
		display: block;
	}

	&__control {
		display: none;
	}
}