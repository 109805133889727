.compare-menu {
    position: relative;
    height: 40px;
    background-color: #fff;
    border: 2px solid #08b6d3;
    margin-bottom: 20px;

	&:after {
		width: 40px;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAAgUlEQVQoU3WO0Q3CQAxDz1kANmCFdgM2oLlbhE5SFsmlTNAVugIbdIIEBYmqIJ3/bD9ZhohsAE6pIXd/QVWv7r60IAA9ohSRO4DpH3T3sZTy+EChWuucUrodwGfOeQi/Q6p6NrMVwCV+EFHHzNsPFEZVOzObiWhg5vW7ui+1jkf+BlmxLiyjE931AAAAAElFTkSuQmCC) no-repeat center center;
		pointer-events: none;
		content: '';
	}

	select {
		.appearance(none);
		border-radius: 0;
		border: none;
		background: none;
		height: 36px;
		width: 100%;
		padding: 0 10px;
	}

	.xs({
		height: auto;
		margin-bottom: 10px;
		border-width: 1px;

		select {
			height: auto;
			padding: 5px 10px;
			font-size: 10px;
		}

		&:after {
			width: 30px;
		}
	});
}