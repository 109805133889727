.compare-nav {
	position: absolute;
	left: 230px;
	pointer-events: none;
	z-index: 4;
	right: 0;

	//.fixed & {
	//	position: fixed;
	//	top: 3px;
	//}

	&__prev,
	&__next {
		cursor: pointer;
		width: 30px;
		top: 50px;
		height: 40px;
		position: absolute;

		small {
			background: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ibmMtaWNvbiBnbHlwaCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTcsMjMuNDE0TDUuNTg2LDIybDEwLTEwbC0xMC0xMEw3LDAuNTg2bDEwLjcwNywxMC43MDdjMC4zOTEsMC4zOTEsMC4zOTEsMS4wMjMsMCwxLjQxNEw3LDIzLjQxNHoiPjwvcGF0aD48L3N2Zz4=) no-repeat #2d2d32 center center;
			position: absolute;
			width: 100%;
			top: 0;
			height: 100%;
			pointer-events: auto;
			opacity: .4;
			border-radius: 2px;
			text-indent: -9999px;
			.transition(opacity 400ms);

			&:hover {
				opacity: 1;
			}
		}
	}

	&__prev {
		left: 5px;

		small {
			.rotate(-180deg);
		}
	}

	&__next {
		right: 5px;
	}

	.xs({
		left: 150px;

		&__prev,
		&__next {
			top: 5px;
		}
	});
}
